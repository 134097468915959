@import '../../../color_variables.scss';
@import '../../../size_variables.scss';

$reps-counter-color: #b7ae44;

$preference-color: #139647;
$preference-background-color: #e6ffcb;

$default-animation-period: 0.4s;

$background-color: #e8e8e8;
$default-black-color: #3a3839;

$activate-period-border-color: #c3c8ce;

%exercise-column-side-padding {
  padding-left: 15px;
  padding-right: 12px;
}

.class-board {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  background: $background-color;
  width: 100vw;
  height: 100vh;

  &__main {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
    background: $background-color;
    margin: 0 0 7em;

    @media (max-width: $large-width-breakpoint) {
      margin: 0 0 6em;
    }

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 0 0 5.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 4.5em;
    }

    @media (max-width: $small-width-breakpoint) {
      margin: 0 0 4em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 0 0 3.5em;
    }

    &__nav-container {
      position: fixed;
      width: 100%;
      max-width: 60vw;
      bottom: 0;
      right: 0;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-end;
      padding: 0 1.5em 1.5em 0;
      gap: 1.25em;
      row-gap: 0.5em;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        padding: 0 1em 1em 0;
        gap: 1em;
        row-gap: 0.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.5em 0.8em 0;
      }
    }

    &__nav-button {
      flex: none;
      padding: 0.5em;
      font-size: 2.5em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
        border-radius: 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      // &:not(:last-child) {
      //   margin: 0 0.5em 0 0;

      //   @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
      //     margin: 0 1em 0 0;
      //   }
      // }

      &__icon:not(:first-child) {
        margin: 0 0 0 0.5em;
      }

      &__text {
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.9em;
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    background: $default-black-color;
    min-height: 2.8em;
    flex-shrink: 0;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.78em;
    }

    &__logo-wrapper {
      position: absolute;
      padding: 0 1.6em 1em 0;
      top: 0;
      left: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: flex-end;
      height: 8em;
      width: 9em;
      border: 4px solid $background-color;
      border-left-width: 0 !important;
      border-top-width: 0 !important;
      border-radius: 0 0 50% 0;
      background: $default-black-color;
      box-shadow: 0 1px 6px 1px lighten($default-black-color, 10%);

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        border-radius: 0 0 1em 0;
        padding: 0 0.35em 0.6em 0;
        width: 6em;
        height: 6.4em;
        border-width: 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0 0.35em 0.6em 0;
        width: 5em;
        height: 5.4em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
        align-items: flex-start;
        justify-content: flex-start;
        width: auto;
        height: auto;
        top: 0.5em;
        left: 0.2em;
      }
    }

    &__logo {
      width: 100%;
      max-width: 6em;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        max-width: 5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        max-width: 4em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        max-width: 3.5em;
      }
    }

    &__buttons-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: 0.8em;
      margin: 0 0 0 10em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        margin: 0 0 0 9em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 0 0 0 6.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 0 0 0 6em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin: 0 0 0 5em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        margin: 0 0 0 3.5em;
      }
    }

    &__control-button {
      padding: 0.5em;
      font-size: 1.5em;
      box-shadow: none;
      flex: none;

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.2em;
        padding: 0.3em;
        border-radius: 3px;
      }

      &:not(:last-of-type) {
        margin: 0 1em 0 0;

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0.7em 0 0;
        }
      }

      &__icon {
        &:not(:last-child) {
          margin: 0 .3em 0 0;
        }
      }
    }

    &__title-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      flex: 1;
    }

    &__title {
      position: relative;
      font-family: 'Black Ops One', cursive;
      color: white;
      font-size: 2.6em;
      margin: 0 1em 0 0;
      background-color: #0e0e0e;
      color: transparent;
      text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
      background-clip: text !important;
      text-transform: uppercase;
      text-align: right;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 2em;
        margin: 0 0.5em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.2em;
      }
    }
  }

  &__overview-navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid $default-black-color;
    border-width: 6px 0 4px 0;
    margin: 6em 0 2em;
    overflow: auto;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      border-width: 3px 0 2px 0;
      margin: 5em 0 2em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 4em 0 2em;
    }

    @media (max-width: $small-width-breakpoint) {
      // overflow: auto;
      border-width: 2px 0 1px 0;
      margin: 3.5em 0 1em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 2.5em 0 1em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      margin: 1.8em 0 1em;
    }

    @media (max-height: $tiny-height-breakpoint) {
      margin: 1em 0 1em;
    }

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        width: 0.2em;
        height: 0.2em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.2em;
    }

    &__buttons-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;

      @media (max-width: $small-width-breakpoint) {
        min-width: 680px;
      }
    }

    &__nav-button {
      padding: 0.8em 0.2em;
      font-size: 1.2em;
      box-shadow: none;
      border-radius: 0;
      min-width: 9.5em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.04em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.88em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.72em;
        padding: 0.6em 0.2em;
      }

      &:not(:last-of-type) {
        border: 0 solid $default-black-color;
        border-right-width: 2px;

        @media (max-width: $small-width-breakpoint) {
          border-right-width: 1px;
        }
      }

      &:disabled {
        background: #ff8585;

        & .default-menu-button__outset-text {
          background: #a75050;
          text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.17);
        }
      }

      & p {
        flex-wrap: wrap;
      }
    }
  }

  &__training-group {
    margin: 0 0.5em 1.5em;
    max-height: calc(100vh - 26.5em);

    @media (max-width: $large-width-breakpoint) {
      max-height: calc(100vh - 25em);
    }
    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 21.5em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 20em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 17.5em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (max-width: $tiny-width-breakpoint) {
      max-height: calc(100vh - 14.5em);
    }

    @media (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 15.5em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 19.7em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 14.8em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 20.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 18.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.4em);
    }

    &--fullscreen {
      margin: 6.5em 0.5em 1.5em;

      @media (max-width: 1525px) {
        margin-top: 4.5em;
      }
      @media (max-width: 1400px) {
        margin-top: 3.5em;
      }
      @media (max-width: $large-width-breakpoint) {
        margin-top: 1.7em;
      }
      @media (max-width: $medium-width-breakpoint) {
        margin-top: 2.8em;
      }
      @media (max-width: 980px) {
        margin-top: 1.3em;
      }
      @media (max-width: 885px) {
        margin-top: 0.9em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        margin-top: 1.1em;
      }
      @media (max-width: 750px) {
        margin-top: 0.3em;
      }
      @media (max-width: $small-width-breakpoint) {
        margin-top: 1.2em;
      }
      @media (max-width: 615px) {
        margin-top: 0.5em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        margin-top: 0.8em;
      }
      @media (max-width: 460px) {
        margin-top: 0.2em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        margin-top: 0.2em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        margin-top: -0.3em;
      }

      @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 1.4em;
      }
      @media (min-width: 615px) and (max-height: $small-height-breakpoint) {
        margin-top: 2.9em;
      }
      @media (min-width: 615px) and (max-height: $tiny-height-breakpoint) {
        margin-top: 1.4em;
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 4.2em;
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        margin-top: 2.3em;
      }
      @media (min-width: 750px) and (max-height: $small-height-breakpoint) {
        margin-top: 5em;
      }
      @media (min-width: 750px) and (max-height: $tiny-height-breakpoint) {
        margin-top: 2.3em;
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
        margin-top: 0.8em;
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        margin-top: 2.7em;
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 1.4em;
      }
      @media (min-width: 885px) and (max-height: $high-height-breakpoint) {
        margin-top: 1.3em;
      }
      @media (min-width: 885px) and (max-height: $medium-height-breakpoint) {
        margin-top: 3.1em;
      }
      @media (min-width: 885px) and (max-height: $small-height-breakpoint) {
        margin-top: 1.7em;
      }
      @media (min-width: 980px) and (max-height: $high-height-breakpoint) {
        margin-top: 2.7em;
      }
      @media (min-width: 980px) and (max-height: $medium-height-breakpoint) {
        margin-top: 4.6em;
      }
      @media (min-width: 980px) and (max-height: $small-height-breakpoint) {
        margin-top: 2.7em;
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
        margin-top: 4.5em;
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        margin-top: 6.3em;
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 3.8em;
      }
      @media (min-width: $large-width-breakpoint) and (max-height: $high-height-breakpoint) {
        margin-top: 7.3em;
      }
      @media (min-width: $large-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        margin-top: 9em;
      }
      @media (min-width: $large-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 5.6em;
      }
      @media (min-width: 1400px) and (max-height: $high-height-breakpoint) {
        margin-top: 8.7em;
      }
      @media (min-width: 1400px) and (max-height: $medium-height-breakpoint) {
        margin-top: 10.7em;
      }
      @media (min-width: 1400px) and (max-height: $small-height-breakpoint) {
        margin-top: 6.75em;
      }
    }

    &,
    &--fullscreen {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 4px solid $default-black-color;
      border-radius: 4px;
      overflow: auto;
      // overflow-y: hidden;
      background: linear-gradient(140deg,#797979, #bbbbbb 80%,#797979);

      &::-webkit-scrollbar {
        width: 1.8em;
        height: 1.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }

      &__title {
        font-family: 'Black Ops One', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.3em 0.8em 0;
        text-transform: uppercase;
        font-size: 2em;
        background-color: $default-black-color;
        color: transparent;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.54);
        background-clip: text !important;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1em;
        }
      }

      &__exercises-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__note-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0 0 0 0.8em;
        margin: 0.5em 0 0.2em;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          padding: 0 0 0 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          padding: 0 0 0 0.2em;
        }
      }

      &__note-label {
        font-family: 'Iceland', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        margin: 0 0.3em 0 0;
        background-color: $default-black-color;
        color: transparent;
        font-size: 1.5em;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.27);
        background-clip: text !important;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.2em;
          text-orientation: upright;
          writing-mode: vertical-rl;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.9em;
          margin: 0 0.2em 0 0;
        }
      }

      &__note-text {
        font-family: 'Montserrat', sans-serif;
        background: white;
        padding: 0.3em 0.3em 0.3em 0.5em;
        color: $default-black-color;
        font-size: 1.5em;
        font-weight: bold;
        border-radius: 3px;
        flex: 1;
        white-space: pre-wrap;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.1em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 0.9em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.65em;
        }
      }
    }
  }

  &__training-exercise {
    &__header {
      &,
      &--centered {
        @extend %exercise-column-side-padding;
        font-family: 'Iceland', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        height: 1em;
        min-height: 1em;
        font-weight: bold;
        font-size: 1.8em;
        background-color: $default-black-color;
        color: transparent;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.27);
        background-clip: text !important;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1em;
        }
      }

      &--centered {
        justify-content: center;
        text-align: center;
      }
    }

    &__reps,
    &__intensities,
    &__intensities--collapsed,
    &__names,
    &__diffculties,
    &__easier-options,
    &__references {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      text-align: left;
    }

    &__intensities-wrapper {
      &--vertical,
      &--horizontal {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
      }

      &--vertical {
        flex-flow: column nowrap;
      }

      &--horizontal {
        flex-flow: row nowrap;
      }
    }

    &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__header {
      border-right: 2px solid rgba(0, 0, 0, 0.38);
    }

    &__intensities-wrapper--horizontal &__intensities:not(:first-child) &__header {
      border-left: 2px solid rgba(255, 255, 255, 0.16);
    }

    &__intensities,
    &__names,
    &__diffculties,
    &__easier-options {
      flex: 1;
    }

    &__reps-text {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom, lighten($reps-counter-color, 30%), $reps-counter-color 30%, $reps-counter-color 70%, darken($reps-counter-color, 25%));
      font-weight: bold;
      color: white;
      text-shadow: 1px 1px 3px black;
      flex: 1;
      padding: 0 0.5em;
      font-size: 1.8em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0 0.2em;
      }
    }

    &__reps-text-span {

    }

    &__reps-text,
    &__intensity-text,
    &__intensity-cycle-text,
    &__difficult-text,
    &__name-text,
    &__easier-option-text,
    &__reference-link {
      font-family: 'Montserrat', sans-serif;
      font-size: 2em;
      border: 0 solid white;
      white-space: nowrap;
      color: white;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.9em;
      }

      &:not(:last-child) {
        border-bottom-width: 1px;
      }
    }

    &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__intensity-cycle-text {
      border-right: 2px solid rgba(255, 255, 255, 0.32);
    }

    &__intensity-text,
    &__intensity-cycle-text,
    &__difficult-text,
    &__name-text,
    &__easier-option-text,
    &__reference-link {
      @extend %exercise-column-side-padding;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      background: rgba(0, 0, 0, 0.58);

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0.2em 0.7em;
      }
    }

    &__reference-link {
      text-align: center;
      color: white;
      cursor: pointer;
    }

    &__first-column {
      width: 0.4em;
      min-width: 0.4em;
    }

    &__last-column {
      flex: 1;
      min-width: 0.4em;
    }

    &__not-configured {
      color: $default-text-color;
      font-weight: bold;
      background: $default-alert-color;
      border-radius: 3px;
      padding: 0.5em;
      flex: 1;
      text-align: center;
      margin: 0.5em;
    }
  }

  &__student-panel {
    top: 100%;

    &,
    &--visible {
      position: fixed;
      will-change: transform;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      transition: top $default-animation-period;
      background: white;
      height: 100vh;
    }

    &--visible {
      top: 9em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        top: 8em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        top: 6.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        top: 5em;
      }

      @media (max-height: $tiny-height-breakpoint) {
        top: 4.2em;
      }
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      background: $default-black-color;
      box-shadow: 0 -4px 4px 0px #545153a8;
      border: 0 solid #e8e8e8;
      border-top-width: 4px;

      &__title-wrapper {
        position: relative;
        left: -25%;
        transform: translateY(-100%);
        will-change: transform;
        transition: $default-animation-period;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: center;
        background: $default-black-color;
        width: 30%;
        border-radius: 1em 1em 0 0;
        box-shadow: 0 -4px 4px 0px #545153a8;
        border: 4px solid #e8e8e8;
        border-bottom-width: 0;
        padding: 0.3em;
        margin: 0 0 1px;

        @media (max-width: $large-width-breakpoint) {
          width: 25%;
          left: -28%;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          width: 22%;
          left: -30%;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 22%;
          left: -28%;
        }

        @media (max-width: $small-width-breakpoint) {
          transform: translateY(0);
          left: 0;
        }
      }

      &__title {
        font-family: 'Black Ops One', cursive;
        color: white;
        font-size: 2.2em;
        background-color: #0e0e0e;
        color: transparent;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
        background-clip: text !important;
        text-transform: uppercase;
        padding: 0.2em 0;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.9em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.7em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.25em;
        }
      }

      &__update-button,
      &__add-button {
        position: absolute;
        top: 0.32em;
        font-size: 1.8em;
        padding: 0.5em;
        flex: 0;
        box-shadow: none;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.45em;
        }

        @media (max-width: $small-width-breakpoint) {
          border-radius: 3px;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.35em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__update-button {
        right: 3em;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          right: 2.6em;
        }
      }

      &__add-button {
        right: 0.5em;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          right: 0.3em;
        }
      }
    }

    &--visible &__header__title-wrapper {
      left: 0;
      transform: translateY(0);
      border: none;
      box-shadow: none;
    }

    &__controls {
      position: absolute;
      left: 0.5em;
      top: 4px;
      transform: rotateX(180deg);
      will-change: transform;
      transform-origin: top;
      transition: transform $default-animation-period;
    }

    &--visible &__controls {
      transform: rotateX(0deg);
    }

    &__toggle-button {
      box-shadow: none;
      font-size: 2em;
      padding: 0.5em;
      border-radius: 0 0 0.3em 0.3em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.9em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $small-width-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.35em;
      }
    }

    &__data-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      overflow: auto;
      padding: 1.5em 0.5em 0 0.5em;
      max-height: calc(100vh - 14em);

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 1em 0.5em 0 0.5em;
      }

      @media (max-width: $medium-width-breakpoint) {
        max-height: calc(100vh - 12.5em);
      }
      @media (max-width: $medium-less-width-breakpoint) {
        max-height: calc(100vh - 10.5em);
      }
      @media (max-width: $small-width-breakpoint) {
        max-height: calc(100vh - 8.8em);
      }
      @media (max-width: $smaller-width-breakpoint) {
        max-height: calc(100vh - 8.4em);
      }

      @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $very-small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.5em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.7em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 12.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 10.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 12.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 10.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.8em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__metrics-container {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      justify-content: space-around;
    }

    &__metric {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border-radius: 3px;
      border: 1px solid $default-black-color;
      margin: 0 0.4em 0.4em;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.7em;
      }

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 2.5em;
        color: $default-black-color;
        font-family: 'Iceland', cursive;
        text-shadow: 0 0 1px $default-black-color;
        border-radius: 3px 0 0 3px;
        padding: 0.2em 0.3em 0.2em 0.5em;
        border: 0 solid $default-black-color;
        border-width: 0 1px 0 0;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0.1em 0.2em;
        }
      }

      &__value {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Orbitron', sans-serif;
        font-weight: bold;
        font-size: 1.5em;
        padding: 0 1em;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0 0.6em;
        }
      }

      & .disc--d {
        background: #f37778;
      }
      & .disc--di {
        background: linear-gradient(to right, #f37778, #fcd602);
      }
      & .disc--ds {
        background: linear-gradient(to right, #f37778, #50bc84);
      }
      & .disc--dc {
        background: linear-gradient(to right, #f37778, #35ade3);
      }
      & .disc--dis {
        background: linear-gradient(to right, #f37778, #fcd602, #50bc84);
      }
      & .disc--dic {
        background: linear-gradient(to right, #f37778, #fcd602, #35ade3);
      }
      & .disc--dsi {
        background: linear-gradient(to right, #f37778, #50bc84, #fcd602);
      }
      & .disc--dsc {
        background: linear-gradient(to right, #f37778, #50bc84, #35ade3);
      }
      & .disc--dci {
        background: linear-gradient(to right, #f37778, #35ade3, #fcd602);
      }
      & .disc--dcs {
        background: linear-gradient(to right, #f37778, #35ade3, #50bc84);
      }
      & .disc--disc {
        background: linear-gradient(to right, #f37778, #fcd602, #50bc84, #35ade3);
      }
      & .disc--dics {
        background: linear-gradient(to right, #f37778, #fcd602, #35ade3, #50bc84);
      }
      & .disc--dsic {
        background: linear-gradient(to right, #f37778, #50bc84, #fcd602, #35ade3);
      }
      & .disc--dsci {
        background: linear-gradient(to right, #f37778, #50bc84, #35ade3, #fcd602);
      }
      & .disc--dcis {
        background: linear-gradient(to right, #f37778, #35ade3, #fcd602, #50bc84);
      }
      & .disc--dcsi {
        background: linear-gradient(to right, #f37778, #35ade3, #50bc84, #fcd602);
      }
      & .disc--i {
        background: #fcd602;
      }
      & .disc--id {
        background: linear-gradient(to right, #fcd602, #f37778);
      }
      & .disc--is {
        background: linear-gradient(to right, #fcd602, #50bc84);
      }
      & .disc--ic {
        background: linear-gradient(to right, #fcd602, #35ade3);
      }
      & .disc--ids {
        background: linear-gradient(to right, #fcd602, #f37778, #50bc84);
      }
      & .disc--idc {
        background: linear-gradient(to right, #fcd602, #f37778, #35ade3);
      }
      & .disc--isd {
        background: linear-gradient(to right, #fcd602, #50bc84, #f37778);
      }
      & .disc--isc {
        background: linear-gradient(to right, #fcd602, #50bc84, #35ade3);
      }
      & .disc--icd {
        background: linear-gradient(to right, #fcd602, #35ade3, #f37778);
      }
      & .disc--ics {
        background: linear-gradient(to right, #fcd602, #35ade3, #50bc84);
      }
      & .disc--idsc {
        background: linear-gradient(to right, #fcd602, #f37778, #50bc84, #35ade3);
      }
      & .disc--idcs {
        background: linear-gradient(to right, #fcd602, #f37778, #35ade3, #50bc84);
      }
      & .disc--isdc {
        background: linear-gradient(to right, #fcd602, #50bc84, #f37778, #35ade3);
      }
      & .disc--iscd {
        background: linear-gradient(to right, #fcd602, #50bc84, #35ade3, #f37778);
      }
      & .disc--icds {
        background: linear-gradient(to right, #fcd602, #35ade3, #f37778, #50bc84);
      }
      & .disc--icsd {
        background: linear-gradient(to right, #fcd602, #35ade3, #50bc84, #f37778);
      }
      & .disc--s {
        background: #50bc84;
      }
      & .disc--sd {
        background: linear-gradient(to right, #50bc84, #f37778);
      }
      & .disc--si {
        background: linear-gradient(to right, #50bc84, #fcd602);
      }
      & .disc--sc {
        background: linear-gradient(to right, #50bc84, #35ade3);
      }
      & .disc--sdi {
        background: linear-gradient(to right, #50bc84, #f37778, #fcd602);
      }
      & .disc--sdc {
        background: linear-gradient(to right, #50bc84, #f37778, #35ade3);
      }
      & .disc--sid {
        background: linear-gradient(to right, #50bc84, #fcd602, #f37778);
      }
      & .disc--sic {
        background: linear-gradient(to right, #50bc84, #fcd602, #35ade3);
      }
      & .disc--scd {
        background: linear-gradient(to right, #50bc84, #35ade3, #f37778);
      }
      & .disc--sci {
        background: linear-gradient(to right, #50bc84, #35ade3, #fcd602);
      }
      & .disc--sdic {
        background: linear-gradient(to right, #50bc84, #f37778, #fcd602, #35ade3);
      }
      & .disc--sdci {
        background: linear-gradient(to right, #50bc84, #f37778, #35ade3, #fcd602);
      }
      & .disc--sidc {
        background: linear-gradient(to right, #50bc84, #fcd602, #f37778, #35ade3);
      }
      & .disc--sicd {
        background: linear-gradient(to right, #50bc84, #fcd602, #35ade3, #f37778);
      }
      & .disc--scdi {
        background: linear-gradient(to right, #50bc84, #35ade3, #f37778, #fcd602);
      }
      & .disc--scid {
        background: linear-gradient(to right, #50bc84, #35ade3, #fcd602, #f37778);
      }
      & .disc--c {
        background: #35ade3;
      }
      & .disc--cd {
        background: linear-gradient(to right, #35ade3, #f37778);
      }
      & .disc--ci {
        background: linear-gradient(to right, #35ade3, #fcd602);
      }
      & .disc--cs {
        background: linear-gradient(to right, #35ade3, #50bc84);
      }
      & .disc--cdi {
        background: linear-gradient(to right, #35ade3, #f37778, #fcd602);
      }
      & .disc--cds {
        background: linear-gradient(to right, #35ade3, #f37778, #50bc84);
      }
      & .disc--cid {
        background: linear-gradient(to right, #35ade3, #fcd602, #f37778);
      }
      & .disc--cis {
        background: linear-gradient(to right, #35ade3, #fcd602, #50bc84);
      }
      & .disc--csd {
        background: linear-gradient(to right, #35ade3, #50bc84, #f37778);
      }
      & .disc--csi {
        background: linear-gradient(to right, #35ade3, #50bc84, #fcd602);
      }
      & .disc--cdis {
        background: linear-gradient(to right, #35ade3, #f37778, #fcd602, #50bc84);
      }
      & .disc--cdsi {
        background: linear-gradient(to right, #35ade3, #f37778, #50bc84, #fcd602);
      }
      & .disc--cids {
        background: linear-gradient(to right, #35ade3, #fcd602, #f37778, #50bc84);
      }
      & .disc--cisd {
        background: linear-gradient(to right, #35ade3, #fcd602, #50bc84, #f37778);
      }
      & .disc--csdi {
        background: linear-gradient(to right, #35ade3, #50bc84, #f37778, #fcd602);
      }
      & .disc--csid {
        background: linear-gradient(to right, #35ade3, #50bc84, #fcd602, #f37778);
      }

      & .rac--alert {
        background: radial-gradient(#ced618, #c4b716);
      }
      & .rac--normal {
        background: radial-gradient(#7ee84d, #67b736);
      }
      & .rac--danger {
        background: radial-gradient(#ecb6a8, #da9484);
      }
      & .rac--extreme-danger {
        background: radial-gradient(#fd5b5b, #c71919);
      }
    }

    &__note {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border-radius: 3px;
      border: 1px solid $site-red-color;
      margin: 0 0 0.4em;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }

      &__title,
      &__text {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }

      &__title {
        font-size: 1.8em;
        background: $site-red-color;
        color: white;
        padding: 0.2em 0.3em 0.2em 0.5em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.5em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.2em;
          padding: 0.2em 0.2em 0.2em 0.4em;
        }
      }

      &__text {
        color: $default-black-color;
        font-size: 1.2em;
        padding: 0.3em 0.5em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.1em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1em;
          padding: 0.2em 0.4em;
        }
      }
    }

    &__list-section {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }

      &__header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        background: $default-black-color;
        border: 1px solid $default-black-color;
        color: white;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;

        &__text {
          font-family: 'Montserrat', sans-serif;
        }

        &__text-icon {
          font-size: 0.9em;
          margin: 0 0.5em 0 0;
        }
      }

      &__content {
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #2c3e50;
          border-radius: 0.4em;
        }
      }

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 1em;
        border: 1px solid $activate-period-border-color;
        border-top-width: 0;
      }

      &__generic-note {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        color: $site-red-color;
        text-align: justify;
        border: 1px solid $site-red-color;
        border-radius: 3px;
        margin: 0 0 0.5em;
        align-self: center;
        padding: 0 0.4em 0 0;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }

        &__label {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          padding: 0.2em 0.2em 0.2em 0.4em;
          background: $site-red-color;
          color: white;
          border-radius: 1px 0 0 1px;
          margin: 0 0.4em 0 0;
          align-self: stretch;
        }
      }
    }

    &__pr-section {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em 1.5em 0.8em;
      min-width: 90vw;

      &__controls-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        margin: 0 0 0.8em;
      }

      &__toggle-button {
        flex: none;
        font-size: 1.2em;
        padding: 0.5em;
        box-shadow: none;
        border-radius: 3px;
        margin: 0 0 0 0.5em;
      }

      &__reference-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        padding: 1em;
        max-height: 70vh;
        overflow: auto;

        @media (max-width: $small-width-breakpoint) {
          padding: 0.4em;
        }

        @media (max-height: $high-height-breakpoint) {
          max-height: 55vh;
        }

        @media (max-height: $small-height-breakpoint) {
          max-height: 40vh;
        }

        &::-webkit-scrollbar {
          width: 1.8em;
          height: 1.8em;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            width: 0.2em;
            height: 0.2em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.2em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.2em;
        }
      }

      &__action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-end;
        align-self: stretch;
        margin: 1em 0 0;
      }

      &__action-button {
        font-size: 2.8em;
        padding: 0.2em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.5em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 2em;
          border-radius: 3px;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.8em;
        }

        &:not(:last-of-type) {
          margin: 0 0.6em 0 0;
        }
      }

      &__reference-wrapper {
        border: 1px solid $default-black-color;
        border-radius: 3px;
        padding: 0.6em 0.6em 0.4em;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          padding: 0.2em;
        }

        &:not(:last-of-type) {
          margin: 0 0 0.8em;
        }
      }

      &__reference-content {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &__reference {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0 0.2em;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 1.8em;
          height: 1.8em;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            width: 0.2em;
            height: 0.2em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.2em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.2em;
        }

        & + & {
          margin: 0.5em 0 0;
        }

        &__date-text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          font-size: 0.8em;
          font-weight: bold;
          color: #8d8d8d;
          padding: 0.2em 0.5em 0 0;
        }

        &__content-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          padding: 0.6em 0.6em 0.4em;

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            padding: 0.2em;
          }
        }

        &__header {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
        }

        &__label {
          font-weight: bold;
          margin: 0 0.5em 0 0;
          min-width: 7em;
          text-align: justify;

          @media (max-width: $small-width-breakpoint) {
            font-size: 0.95em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            font-size: 0.9em;
          }
        }

        &__input {
          &,
          &--extended {
            border: 0 solid white;
            border-width: 0 1px;
          }

          & input,
          &--extended input {
            font-weight: bold;
            font-size: 1.1em;
            padding: 0.3em 0.2em !important;
            flex-shrink: 0;
          }

          & input {
            width: 4em;
            border: 0 solid white;

            // @media (max-width: $small-width-breakpoint) {
            //   width: 3.5em;
            // }
          }

          &--extended input {
            width: 4.5em;
            border: 0 solid white;

            // @media (max-width: $small-width-breakpoint) {
            //   width: 3.5em;
            // }
          }

          & .default-input__input-suffix,
          &--extended .default-input__input-suffix {
            border-radius: 0;
            border: 0 solid white;
          }
        }

        &__input-prefix {
          &--highlighted {
            font-weight: bold;
          }
        }

        &__input-container {
          &,
          &--selected {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: center;
            flex: 1;

            // &:not(:first-child) {
            //   margin: 0 0 0 0.5em;
            // }

            &--horizontal {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }

        &__pr-input-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: center;
        }

        &__pr-text {
          color: $default-black-color;
          min-width: 10em;
          text-align: center;
        }

        &__arrow-icon {
          font-size: 1.4em;
          margin: 0 0.5em;
          color: $default-black-color;
        }

        &__generic-text {
          text-align: center;
          color: #7d7d7d;
          margin: 0.2em 0 0;
        }

        &__input-group-container {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;

          &:first-child:last-child &__title-wrapper--resistance,
          &:first-child:last-child &__title-wrapper--hypertrophy,
          &:first-child:last-child &__title-wrapper--strength {
             border-radius: 3px 3px 0 0;
          }
          &:first-child:not(:last-child) &__title-wrapper--resistance,
          &:first-child:not(:last-child) &__title-wrapper--hypertrophy,
          &:first-child:not(:last-child) &__title-wrapper--strength {
            border-radius: 3px 0 0 0;
          }
          &:not(:first-child):last-child &__title-wrapper--resistance,
          &:not(:first-child):last-child &__title-wrapper--hypertrophy,
          &:not(:first-child):last-child &__title-wrapper--strength {
            border-radius: 0 3px 0 0;
          }

          &__title-wrapper {
            &,
            &--resistance,
            &--hypertrophy,
            &--strength {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              font-weight: bold;
              text-shadow: 1px 1px white;
              padding: 0.3em;
              border: 1px solid white;
              border-bottom-width: 0;
            }

            &--resistance {
              background: linear-gradient(348deg, #b7b34c, #fff605, #b7b34c);
              color: #3c3a02;
            }
            &--hypertrophy {
              background: linear-gradient(348deg, #a78425, #ffbc00, #a78425);
              color: #584203;
            }
            &--strength {
              background: linear-gradient(348deg, #ad5b22, #ff6a00, #ad5b22);
              color: #6a2f06;
            }
          }

          &__title {
            font-size: 0.9em;
          }
        }

        &__input-groups-wrapper,
        &__percentages-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: flex-start;
        }

        &__pr-input-header {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          background: #c7c7c7;
          padding: 0.4em 0.4em;
          // border-radius: 3px 3px 0 0;
          border: 0 solid white; //#c2cad8
          border-width: 0 1px;
        }

        &__input-container--selected &__pr-input-header {
          background: #7bd053;
          border-color: #45a01a;
        }

        &__note-input {
          margin: 0.5em 0 0;
        }

        &__difficulty-input {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: stretch;
          margin: 0.4em 0 0;
          width: 100%;

          &__option {
            &,
            &--red,
            &--yellow,
            &--green {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: center;
              padding: 0.4em 0.2em;
              outline: none;
              cursor: pointer;
              -webkit-tap-highlight-color:  transparent;
              border: none;
              user-select: none;
              font-size: 1em;
              text-decoration: none;
              min-width: 35px;
              min-height: 35px;
              font-weight: bold;
              border: 1px solid #868686;
              background: #e8e8e8;
              flex: 1 1;
              color: #868686;

              &:disabled {
                cursor: default;
                color: white;
                background: #73bd65;
                border-color: #73bd65 #868686;
              }

              &:first-of-type {
                border-radius: 0.3em 0 0 0.3em;

                @media (max-width: $smaller-width-breakpoint) {
                  border-radius: 0.15em 0 0 0.15em;
                }
              }
              &:last-of-type {
                border-radius: 0 0.3em 0.3em 0;

                @media (max-width: $smaller-width-breakpoint) {
                  border-radius: 0 0.15em 0.15em 0;
                }
              }
              &:not(:last-of-type) {
                border-right-width: 0;
              }
            }

            &--red:disabled {
              background: #c36b5d;
              border-color: #c36b5d #c36b5d #c36b5d #868686;
            }
            &--yellow:disabled {
              color: #888427;
              background: #dad669;
              border-color: #dad669 #868686 #dad669 #dad669;
            }

            &__icon {
              margin: 0 0.4em 0 0;

              @media (max-width: 375px) {
                margin: 0;
                font-size: 1.25em;
              }
            }
          }
        }
      }
    }

    &__action-button {
      font-size: 1.5em;
      padding: 0.5em;
      align-self: stretch;
      margin: 0 0.5em 0 0;
      border-radius: 3px;
      box-shadow: none;
      min-width: auto;
      flex: 0;
    }
  }

  &__clock-container {
    margin: 0.5em 0;

    &,
    &--hidden {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      padding: 0 1em 0 0;

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.55em 0;
      }
    }

    &--hidden {
      margin: 2.5em 0;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 1.9em 0;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin: 1.2em 0;
      }
    }
  }

  &__clock-container--hidden &__clock {
    display: none;
  }

  &__clock {
    &,
    &--expanded,
    &--super-expanded {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      background: $default-black-color;
      border-radius: 0.5em;
      padding: 0.2em;
      will-change: transform;
      transform-origin: top right;
      transition: $default-animation-period;

      @media (max-width: $large-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.54em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.45em;
        border-radius: 3px;
        padding: 1px;
      }
    }

    &--expanded {
      position: relative;
      // transform: scale(1.4, 1.4);
      //
      // @media (max-width: $tiny-width-breakpoint) {
      //   transform: scale(1.25, 1.25);
      // }
    }

    &--super-expanded {
      position: relative;
      right: -1em;
      // transform: scale(2.2, 2.2);

      @media (max-width: $small-width-breakpoint) {
        right: -0.55em;
      }

      // @media (max-width: 1525px) {
      //   transform: scale(2, 2);
      // }
      // @media (max-width: 1400px) {
      //   transform: scale(1.88, 1.88);
      // }
      // @media (max-width: $large-width-breakpoint) {
      //   transform: scale(1.78, 1.78);
      // }
      // @media (max-width: $medium-width-breakpoint) {
      //   transform: scale(2, 2);
      // }
      // @media (max-width: 980px) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: 885px) {
      //   transform: scale(1.74, 1.74);
      // }
      // @media (max-width: $medium-less-width-breakpoint) {
      //   transform: scale(1.95, 1.95);
      // }
      // @media (max-width: 750px) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: $small-width-breakpoint) {
      //   transform: scale(2.2, 2.2);
      // }
      // @media (max-width: 615px) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: $smaller-width-breakpoint) {
      //   transform: scale(2.3, 2.3);
      // }
      // @media (max-width: 460px) {
      //   transform: scale(2.1, 2.1);
      // }
      // @media (max-width: $very-small-width-breakpoint) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: $tiny-width-breakpoint) {
      //   transform: scale(1.6, 1.6);
      // }
      //
      // @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: 615px) and (max-height: $small-height-breakpoint) {
      //   transform: scale(3, 3);
      // }
      // @media (min-width: 615px) and (max-height: $tiny-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      //   transform: scale(3, 3);
      // }
      // @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: 750px) and (max-height: $small-height-breakpoint) {
      //   transform: scale(3.2, 3.2);
      // }
      // @media (min-width: 750px) and (max-height: $tiny-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      //   transform: scale(1.74, 1.74);
      // }
      // @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.25, 2.25);
      // }
      // @media (min-width: 885px) and (max-height: $high-height-breakpoint) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (min-width: 885px) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.32, 2.32);
      // }
      // @media (min-width: 980px) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2, 2);
      // }
      // @media (min-width: 980px) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.6, 2.6);
      // }
      // @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2.25, 2.25);
      // }
      // @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.9, 2.9);
      // }
      // @media (min-width: $large-width-breakpoint) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2.65, 2.65);
      // }
      // @media (min-width: $large-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(3.4, 3.4);
      // }
      // @media (min-width: 1400px) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2.85, 2.85);
      // }
      // @media (min-width: 1400px) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(3.7, 3.7);
      // }
    }

    &__time-wrapper,
    &__lap-wrapper {
      // background: radial-gradient(#ff8585, #d05353);

      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.5em 2.2em;

        background: black;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.88em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.65em;
          padding: 0.5em 1.5em;
        }
      }

      // &--red {
      //   background: radial-gradient(#ff8585, #d05353);
      // }
      //
      // &--green {
      //   background: radial-gradient(#7ebf5e, #62ab3f);
      // }
      //
      // &--yellow {
      //   background: radial-gradient(#e0dc4b, #989517);
      // }
      //
      // &--blue {
      //   background: radial-gradient(#5ea9bf, #3996b1);
      // }
    }

    &__time-wrapper {
      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        border-radius: 0 0.5em 0.5em 0;
        cursor: pointer;
      }
    }

    &--super-expanded &__time-wrapper {
      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        cursor: default;
      }
    }

    &__lap-wrapper {
      &,
      &--red,
      &--green,
      &--blue,
      &--yellow {
        margin: 0 1em 0 0;
      }
    }

    &--super-expanded &__time-wrapper,
    &--super-expanded &__lap-wrapper {
      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        border-radius: 0.5em;
      }
    }

    &__time-wrapper &__compress-button,
    &__time-wrapper--red &__compress-button,
    &__time-wrapper &__time,
    &__time-wrapper--red &__time,
    &__lap-wrapper &__lap,
    &__lap-wrapper--red &__lap {
      &,
      &--centered {
        // background-color: #af2626;

        color: #d62323;
      }
    }

    &__time-wrapper--green &__compress-button,
    &__time-wrapper--green &__time,
    &__lap-wrapper--green &__lap {
      &,
      &--centered {
        // background-color: #3c791d;

        color: #89f155;
      }
    }

    &__time-wrapper--yellow &__compress-button,
    &__time-wrapper--yellow &__time,
    &__lap-wrapper--yellow &__lap {
      &,
      &--centered {
        // background-color: #7d7b1d;

        color: #dad61e;
      }
    }

    &__time-wrapper--blue &__compress-button,
    &__time-wrapper--blue &__time,
    &__lap-wrapper--blue &__lap {
      &,
      &--centered {
        // background-color: #33798e;

        color: #1a9fc7;
      }
    }

    &__time,
    &__lap {
      &,
      &--centered {
        font-family: 'Orbitron', sans-serif;
        font-size: 7em;
        color: transparent;
        // text-shadow: -0.055em -0.055em 1px rgba(255, 255, 255, 0.85);
        // background-clip: text !important;
        text-transform: uppercase;
        font-weight: bold;
      }

      &--centered {
        text-align: center;
      }
    }

    &__lap {
      @media (max-width: $tiny-width-breakpoint) {
        text-align: center;
      }
    }

    &__time {
      &,
      &--centered {
        width: 3.7em;
      }
    }

    &__controls {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 2.2em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0 1em;
      }
    }

    &__control-button {
      padding: 0.3em;
      box-shadow: none;
      font-size: 3.8em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 2.5em;
      }

      &:not(:last-of-type) {
        margin: 0 0.7em 0 0;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          margin: 0 0.4em 0 0;
        }
      }
    }

    &__compress-button {
      font-size: 1.2em;
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 0.5em;
      cursor: pointer;
    }

    & &__compress-button,
    &--expanded &__compress-button {
      display: none;
    }
  }

  &__overlay,
  &__exercise-video-overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      &__title {
        font-family: 'Black Ops One', cursive;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: justify;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.6em;
        }
      }

      &__toggle-button {
        flex: none;
        font-size: 1.2em;
        padding: 0.5em;
        box-shadow: none;
        border-radius: 3px;
        margin: 0 0 0 0.5em;
      }
    }

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 1.8em;
        height: 1.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__exercise-video-overlay {
    width: 100%;
    height: 90%;
    margin: 0;
    align-self: center;

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../../../images/preloader.gif), white;
      padding: 0;
      margin: 0;
      max-height: none;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #eee;
    border-width: 1px 0 0;
    margin: 0.5em 0 1em;
  }

  &__class-time-container {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__class-time-button {
    font-size: 2em;
    border-radius: 3px;
    padding: 0.2em 0.4em;
    margin: 0 0.3em 0.5em;
    min-width: 5em;
  }

  &__rac-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.1em;
      }
    }

    &__graph {
      flex: 1;
      background: white; //#e8e8e8
      border-radius: 0 5px 0 5px;
      height: 15em;
      min-height: 15em;

      @media (max-width: $medium-width-breakpoint) {
        height: 14em;
        min-height: 14em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        height: 12em;
        min-height: 12em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        height: 11em;
        min-height: 11em;
        border-radius: 0;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        height: 10em;
        min-height: 10em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        height: 8em;
        min-height: 8em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        height: 7.5em;
        min-height: 7.5em;
      }
    }

    &__result {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex-shrink: 0;
      padding: 0.5em 2em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.92em;
        padding: 0.5em 1em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.88em;
        padding: 0.2em 0.8em;
      }

      @media (max-width: $small-width-breakpoint) {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.8em 0.4em;
      }

      &__label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        color: white;
        font-size: 1.5em;
        align-self: stretch;
        border: 0 solid white;
        border-bottom-width: 2px;
        padding: 0 0 0.5em;

        @media (max-width: $small-width-breakpoint) {
          border-bottom-width: 0;
          border-right-width: 1px;
          padding: 0 0.5em 0 0;
        }

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.3em;
          padding: 0 0.4em 0 0;
        }

        @media (max-height: $small-height-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          display: none;
        }
      }

      &__value-wrapper {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.5em 0;

        @media (max-width: $small-width-breakpoint) {
          flex-flow: row nowrap;
          margin: 0 0 0 1em;
          padding: 0;
        }

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0 0 0 0.6em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0;
        }
      }

      &__description {
        font-family: 'Montserrat', sans-serif;
        color: white;
        max-width: 23em;
        text-align: justify;
        font-size: 0.9em;
        margin: 0.8em 0 0;

        @media (max-width: $medium-less-width-breakpoint) {
          max-width: 19em;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 1em;
          max-width: none;
        }

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0 0 0 0.8em;
          font-size: 0.85em;
        }

        @media (max-height: $small-height-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 0 0.8em;
          font-size: 0.82em;
        }
      }

      &__value {
        &--alert,
        &--normal,
        &--danger,
        &--extreme-danger {
          font-family: 'Montserrat', sans-serif;
          color: white;
          font-size: 1.3em;
          font-weight: bold;
          padding: 0.5em 0.8em;
          border-radius: 5px;

          @media (max-width: $smaller-width-breakpoint) {
            border-radius: 3px;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            padding: 0.3em 0.4em;
            border-radius: 3px;
            font-size: 1.2em;
          }

          @media (max-width: $very-small-width-breakpoint) {
            font-size: 1.1em;
          }
        }

        &--alert {
          background: #fdcd01;
        }
        &--normal {
          background: #029642;
        }
        &--danger {
          background: #e82310;
        }
        &--extreme-danger {
          background: #bc0100;
        }
      }
    }
  }

  &__rac-section-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    border: 1px solid $default-black-color;
    border-top-width: 0;
    border-radius: 0 0 5px 5px;

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
    }

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 0 0 3px 3px;
    }
  }

  &__overlay-note {
    margin: 0.5em 0 0;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;

    &__text {
      color: #564f36;
      text-align: justify;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__overlay-content-wrapper {
    margin: 1em 0 0;
  }

  &__overlay-content-sub-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.6em;
    color: $default-black-color;
    margin: 0 0 0.2em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }

    &--highlighted {
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 0.4em 0 0;
      flex: 0 1;
      white-space: nowrap;
    }
  }

  &__overlay-note {
    margin: 0.3em 0 0;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;

    &__text {
      color: #564f36;
      text-align: justify;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__parsed-text {
    white-space: pre;

    &__icon {
      text-shadow: none;
    }
  }

  &__training-group-not-configured {
    color: $default-black-color;
    font-weight: bold;
    background: $default-alert-color;
    border-radius: 3px;
    padding: 0.5em;
    text-align: center;
    margin: 0.5em;
  }
}
