@import '../../color_variables.scss';
@import '../../constants.scss';
@import '../../size_variables.scss';

$default-dark-blue-color: #2c3e50;
$default-text-color: rgb(102, 102, 102);
$default-light-blue: #3598dc;
$default-green-color: #809e5f;
$default-gray-color: #949494;

$service-button-text-color: #737373;
$service-button-background-color: #c5c5c5;
$service-button-border-color: #a0a0a0;
$service-button-disabled-background-color: #f1f1f1;
$service-button-disabled-border-color: #2c3e50;
$service-button-disabled-text-color: #2c3e50;

$activated-periods-border-color: #c3c8ce;

$gray-color: #8e96a0;
$blue-color: #659be0;

$calendar-header-background: #f1f1f1;
$calendar-header-color: #3e3e3e;

.training-period-schedule {
  max-width: 70em;

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__enable-day-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: $default-gray-color;
      cursor: default;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
  }

  &__save-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: $default-gray-color;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__data-saved-text {
    color: $default-gray-color;
    font-size: 1em;
    white-space: nowrap;
    margin: 0 0 0 1em;
  }

  &__links-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-end;
    margin: 0 0 0.5em;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__default-button {
    font-family: 'Open Sans', sans-serif;
    background: $default-dark-blue-color;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    color: white;
    user-select: none;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;

    &:not(:first-of-type) {
      margin: 0 0 0 0.5em;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      text-align: center;
    }
  }

  &__activated-periods {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $activated-periods-border-color;
      border-top-width: 0;
    }
  }

  &__status-text {
    &--scheduled,
    &--in-progress,
    &--finished {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--scheduled {
      background: $blue-color;
    }

    &--in-progress {
      background: $default-green-color;
    }

    &--finished {
      background: $gray-color;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__calendar {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.8em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
      margin: 0 0 1em;
    }

    &__date-change-button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #9c9c9c;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      color: #636363;
      user-select: none;
      font-size: 0.9em;
      font-weight: bold;
      text-decoration: none;
      flex: 1;

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }
    }

    &__header-text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 1em;
      background: $calendar-header-background;
      color: $calendar-header-color;
      font-weight: bold;
      flex: 3;
      box-shadow: inset 0px 0px 6px #ccc;
    }

    &__days-list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      list-style: none;
      background: #f9f9f9;
      border-radius: 3px;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__day-item {
      &,
      &--today {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        min-width: 26em;
        font-size: 0.95em;

        &__id {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          width: 11em;
          color: white;
          padding: 0.3em 0.8em;
          border: 0 solid white;
          background: $default-dark-blue-color;

          @media (max-width: $small-width-breakpoint) {
            width: 6.5em;
          }

          @media (max-width: $smaller-width-breakpoint) {
            font-size: 0.9em;
            padding: 0.3em 0.4em;
            justify-content: space-around;
            width: 4.8em;
          }
        }

        &:first-child > &__id {
          border-radius: 3px 0 0 0;
        }

        &:last-child > &__id {
          border-radius: 0 0 0 3px;
        }

        &__content {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          border: 0 solid $default-dark-blue-color;
          flex: 1;
          padding: 0.4em 0.8em;
        }

        &:not(:last-child) &__id {
          border-bottom-width: 1px;
        }

        &:not(:last-child) &__content {
          border-bottom-width: 1px;
        }

        &__trainings {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__training-info {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          padding: 0.2em 0.6em;
          border-radius: 3px;
          margin: 0 0.5em 0.2em 0;
          color: white;
          text-align: center;
        }

        &__no-info {
          color: #797979;

          &,
          &--red {
            text-transform: uppercase;
            font-weight: bold;

            &:not(:last-child) {
              margin: 0.8em 0 1em;
            }
          }

          &--red {
            color: $site-red-color;
          }
        }

        &__enable-button,
        &__disable-button,
        &__config-button {
          font-family: 'Open Sans', sans-serif;
          padding: 0.6em 0.8em;
          outline: none;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          border: none;
          user-select: none;
          font-size: 0.85em;
          text-decoration: none;
          min-width: 35px;
          min-height: 35px;
        }

        &__enable-button,
        &__disable-button {
          padding: 0.6em 0.2em;
          min-width: 5.7em;
        }

        &__enable-button {
          background: $default-green-color;
          color: white;

          &:hover:not(:disabled) {
            background: darken($default-green-color, 15);
          }
        }

        &__disable-button {
          background: $burned-color;
          color: white;

          &:hover:not(:disabled) {
            background: darken($burned-color, 15);
          }
        }

        &__config-button {
          background: #ccc;
          color: #717171;
          margin: 0 0.5em 0 0;

          &:hover:not(:disabled) {
            background: darken(#ccc, 15);
          }
        }
      }

      &--today &__id {
        background: $site-red-color;
      }

      &__actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__default-link-button {
      font-family: 'Open Sans', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      user-select: none;
      padding: 0.8em;
      font-size: 0.9em;
      min-width: 35px;
      min-height: 35px;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      text-decoration: none;
      color: white;
    }
  }

  &__service-selector-header {
    box-shadow: none;
    margin: 0 0 0.5em;
  }

  &__service-selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0.8em;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__service-button {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: $service-button-background-color;
    color: $service-button-text-color;
    border: 0 solid $service-button-border-color;
    border-width: 2px 0;

    &,
    &:disabled {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.6em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-weight: bold;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &:disabled {
      cursor: default;
      background: $service-button-disabled-background-color;
      color: $service-button-disabled-text-color;
      border: 0 solid $service-button-disabled-border-color;
      border-width: 2px 0;
    }
  }

  &__warning-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    text-align: justify;
    padding: 0;
    background: #fbe1e3;
    margin: 0.5em 0 1.2em;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0.5em 0 0.6em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: #e73d4a;
      text-align: justify;
      padding: 1em;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
        padding: 0.6em 0.6em 0.6em 0;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__checkin-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    &__class-section {
      color: $site-red-color;
      margin: 0;

      &:not(:first-child) {
        margin: 1em 0 0;
      }
    }

    &__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: space-between;
      border: 0 solid $default-text-color;
      border-bottom-width: 1px;
      width: 100%;

      &:not(:last-child) {
        margin: 0 0 0.3em;
      }

      &__name {
        &--red {
          color: $site-red-color;
        }
      }

      &__achievement-text {
        margin: 0 0 0 0.4em;
        color: $site-red-color;
      }

      &__link-button {
        background: none;
        border-radius: 50%;

        &:hover {
          background: none !important;
          color: $site-red-color;
        }
      }
    }
  }

  &__date-text {
    background: #bac3d0;

    &,
    &--past,
    &--alert,
    &--completed {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $site-red-color;
    }

    &--alert {
      background: #f0ad4e;
    }

    &--completed {
      background: $default-green-color;
    }
  }
}
