.cost-center-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__hierarchy-text {
    &,
    &--empty {
      flex: 0 1;
      background: #2c3e50;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--empty {
      background: #dcdcdc;
      color: #5a5a5a;
    }
  }
}
