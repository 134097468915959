@import '../../size_variables.scss';
@import '../../color_variables.scss';

$gray-color: #bac3d0;
$alert-color: #f0ad4e;
$default-dark-blue-color: #364150;
$filter-sction-border-color: #c3c8ce;

.financial-movement-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--alert,
    &--completed {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $site-red-color;
    }

    &--alert {
      background: $alert-color;
    }

    &--completed {
      background: $default-green-color;
    }
  }

  &__classification-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__sub-section {
    margin: 0 0 0.9em;
  }

  // &__filter-section {
  //   display: flex;
  //   flex-flow: column nowrap;
  //   align-items: stretch;
  //   justify-content: flex-start;
  //
  //   @media (max-width: $small-width-breakpoint) {
  //     font-size: 0.9em;
  //   }
  //
  //   &__header {
  //     display: flex;
  //     flex-flow: row nowrap;
  //     align-items: center;
  //     justify-content: space-between;
  //     padding: 0.4em 0.8em;
  //     background: $default-dark-blue-color;
  //     border: 1px solid $default-dark-blue-color;
  //     color: white;
  //     cursor: pointer;
  //     -webkit-tap-highlight-color:  transparent;
  //
  //     &__text {
  //       font-weight: lighter;
  //     }
  //
  //     &__text-icon {
  //       font-size: 0.9em;
  //       margin: 0 0.5em 0 0;
  //     }
  //   }
  //
  //   &__content {
  //     overflow: auto;
  //     overflow-y: hidden;
  //
  //     &::-webkit-scrollbar {
  //       width: 0.4em;
  //       height: 0.4em;
  //     }
  //
  //     &::-webkit-scrollbar-track {
  //       box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
  //       border-radius: 0.4em;
  //     }
  //
  //     &::-webkit-scrollbar-thumb {
  //       background-color: #2c3e50;
  //       border-radius: 0.4em;
  //     }
  //   }
  //
  //   &__content-wrapper {
  //     display: flex;
  //     flex-flow: column nowrap;
  //     align-items: stretch;
  //     justify-content: flex-start;
  //     padding: 1em;
  //     border: 1px solid $filter-sction-border-color;
  //     border-top-width: 0;
  //   }
  // }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button,
    &__reset-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__refresh-button {
      background: #829e7b;
    }
    &__reset-button {
      background: #9e7b7b;
    }
  }
}
