@import '../../../size_variables.scss';
@import '../../../color_variables.scss';
@import '../../../constants.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-text-color: rgb(102, 102, 102);
$default-gray-color: #949494;
$default-dark-blue-color: #364150;
$default-green-color: #809e5f;
$default-red-color: #e08283;

$info-source-button-text-color: #737373;
$info-source-button-background-color: #c5c5c5;
$info-source-button-border-color: #a0a0a0;
$info-source-button-disabled-background-color: #f1f1f1;
$info-source-button-disabled-border-color: #2c3e50;
$info-source-button-disabled-text-color: #2c3e50;

.food-ingredient-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button,
  &__default-delete-button,
  &__default-delete-button--chained,
  &__default-add-button,
  &__default-add-button--wide {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__default-add-button {
    &,
    &--wide {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background: $default-green-color;
      color: white;

      &:hover:not(:disabled) {
        background: darken($default-green-color, 15);
      }

      &__icon {
        &,
        &--locked {
          margin: 0 0.5em 0 0;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0;
        }
      }
    }

    &--wide {
      width: 100%;
    }
  }

  &__default-delete-button {
    &,
    &--chained {
      background: $default-red-color;
      color: white;
      border-radius: 3px;

      &:hover:not(:disabled) {
        background: darken($default-red-color, 15);
      }

      &__icon {
        margin: 0 0.5em 0 0;

        @media (max-width: $small-width-breakpoint) {
          margin: 0;
        }
      }
    }

    &--chained {
      border-radius: 0 3px 3px 0;
    }
  }

  &__info-source-selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0.8em;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__default-label {
    text-transform: uppercase;
    font-size: 0.9em;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }
  }

  &__info-source-button {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: $info-source-button-background-color;
    color: $info-source-button-text-color;
    border: 0 solid $info-source-button-border-color;
    border-width: 2px 0;

    &,
    &:disabled {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.6em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-weight: bold;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &:disabled {
      cursor: default;
      background: $info-source-button-disabled-background-color;
      color: $info-source-button-disabled-text-color;
      border: 0 solid $info-source-button-disabled-border-color;
      border-width: 2px 0;
    }
  }

  &__source-association {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.8em 0 0;

    &__tab-selector {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
    }

    &__tab-item {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      &__button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        background: #efefef;
        -webkit-tap-highlight-color:  transparent;
        border: 1px solid transparent;
        border-bottom-width: 0;
        border-radius: 4px 4px 0 0;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #2c3e50;
          color: white;
          cursor: default;
        }

        &:hover:not(:disabled) {
          border-color: #2c3e50;
        }
      }
    }

    &__tab-content {
      padding: 0.5em;
      border: 1px solid #2c3e50;
      border-radius: 0 5px 5px 5px;
    }

    &__nutrient-classification {
      margin-top: 0.5em;
    }

    &__instructions {
      font-size: 0.9em;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      margin: 0 0 1.5em;
      text-align: center;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__nutrient-label {
      min-width: 24.5em;

      @media (max-width: $small-width-breakpoint) {
        white-space: normal !important;
        min-width: 15em;
        max-width: 15em;
      }

      @media (max-width: 540px) {
        min-width: 0;
        max-width: none;
      }
    }

    &__default-measurement-cell {
      background: #2c3e50;
      color: white;
    }

    &__field-input {
      flex: 1;
    }
  }

  &__food-allergens {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }

    &__instructions {
      font-size: 0.9em;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      margin: 0 0 1.5em;
      text-align: justify;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__list {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      gap: 1em;
    }

    &__input {
      margin: 0 !important;
      width: 8em;
    }

    &__input .default-input__toggle-visual--inactive-neutral::before {
      background: rgba(255, 200, 0, 0.65);
    }
  }

  &__action-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin: 0 0 1em;
  }

  &__processing-method-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__field-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;

    & + & {
      margin: 1em 0 0;

      @media (max-height: $medium-height-breakpoint) {
        margin: 0.5em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 8px 0 0;
      }
    }
  }

  &__processing-method-input {
    flex: 1;
  }
}
