@import '../../../size_variables.scss';
@import '../../../constants.scss';
@import '../../../color_variables.scss';

$indicator-background-color: #f7f7f7;

$default-dark-blue-color: #364150;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.student-general-report {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 70em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__indicator-container {
    &,
    &--spaced {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
      }
    }

    &--spaced {
      margin: 0.5em 0;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;
    margin: 0 0.5em 0.5em 0;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.5em;
      flex: 1;
      justify-content: space-between;

      &:last-of-type {
        margin: 0
      }
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
    }

    // & + & {
    //   margin: 0 0 0 0.5em;
    //
    //   @media (max-width: $smaller-width-breakpoint) {
    //     margin: 0.5em 0 0;
    //   }
    // }
  }

  &__no-margin-subsection {
    margin-bottom: 0;
  }

  &__inputs-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
  }

  &__inputs-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
  }

  &__refresh-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    border-radius: 3px;
    margin: 0 0 0 0.5em;
    background: #829e7b;
    color: white;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__filter-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 1em;

    &:not(:first-of-type) {
      margin: 0.5em 0;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }

    &__wrapper {
      &,
      &--stretched {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        overflow: auto;
        padding: 0.4em;
        border: 1px solid $default-dark-blue-color;
        border-top-width: 0;
        border-radius:  0 0 3px 3px;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #2c3e50;
          border-radius: 0.4em;
        }
      }

      &--stretched {
        align-items: stretch;
      }
    }
  }
}
