@import '../../color_variables.scss';
@import '../../size_variables.scss';

.financial-search-tag-list {
  max-width: 70em;

  &__is-active-text {
    &__ativo,
    &__inativo {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &__ativo {
      background: $default-green-color;
      color: white;
    }

    &__inativo {
      background: $site-red-color;
      color: white;
    }
  }

  &__type-text {
    &__receita,
    &__gasto,
    &__ambos {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &__receita {
      background: $default-green-color;
      color: white;
    }

    &__gasto {
      background: $site-red-color;
      color: white;
    }

    &__ambos {
      background: #dcdcdc;
      color: #5a5a5a;
    }
  }
}
