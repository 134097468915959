@import '../color_variables.scss';
@import '../size_variables.scss';

$history-light-gray: #e7ecf1;
$header-gray: rgb(102, 102, 102);

%default-side-padding {
  padding-left: 1.1em;
  padding-right: 1.1em;

  @media (max-width: $small-width-breakpoint) {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
}

.content-frame {
  &__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;

    &__history-container {
      @extend %default-side-padding;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      border: 0 solid $history-light-gray;
      border-width: 0 0 1px;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.92em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.88em;
      }

      @media (max-height: $medium-height-breakpoint) {
        padding-top: 0.4em;
        padding-bottom: 0.4em;
      }
    }

    &__history-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: #888;
      text-decoration: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      font-size: 0.9em;

      &:hover:not([disabled]) {
        text-decoration: underline;
      }

      &[disabled] {
        cursor: default;
        color: $site-red-color;
      }

      &:not(:first-of-type) {
        &::before {
          content: "";
          display: block;
          width: 0.4em;
          height: 0.4em;
          background: $history-light-gray;
          margin: 0 0.7em;
          border-radius: 0.4em;
          position: relative;

          @media (max-width: $very-small-width-breakpoint) {
            width: 0.75em;
            height: 0.4em;
            border-radius: 0.75em;
            margin: 0 1.1em;
          }
        }
      }

      @media (max-width: $very-small-width-breakpoint) {
        flex: 0 1;
        text-align: justify;
        font-size: 0.88em;
      }

      &__text {
        text-align: center;
      }
    }

    &__title-container {
      @extend %default-side-padding;
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      color: $header-gray;
      margin: 1em 0;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.75em;
      }

      @media (max-height: $medium-height-breakpoint) {
        margin: 0.5em 0;
      }
    }

    &__title-text-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      width: 100%;
    }

    &__title-icon {
      font-size: 1.8em;
      margin: 0 0.2em 0 0;
    }

    &__title {
      font-size: 1.55em;
      font-weight: lighter;
      width: 100%;
    }

    &__title-description {
      margin: 0 0 0 0.5em;
      font-size: 0.92em;
      font-weight: lighter;
    }
  }

  &__loading-container {
    width: 100%;
    height: 100%;
  }
}
