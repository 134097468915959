@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.context-popup {
  position: fixed;
  background: #f7f7f7;
  z-index: 11;
  font-weight: normal;
  border-radius: 3px;
  color: $default-text-color;
  // box-shadow: 2px 2px 4px #000000ad;
  // border: 1px solid #6a6a6a;
  box-shadow: 0px 0px 0px 1px #6a6a6a;
  padding: 10px;
  text-align: justify;
  font-size: 0.9em;
  animation: fadeIn 0.3s ease-out both;
  pointer-events: none;
  overflow: visible;

  @media (max-width: $medium-less-width-breakpoint) {
    font-size: 0.85em;
  }
  @media (max-width: $small-width-breakpoint) {
    font-size: 0.8em;
    padding: 8px;
  }
  @media (max-width: $smaller-width-breakpoint) {
    font-size: 0.75em;
    padding: 5.5px;
  }

  &__arrow {
    position: fixed;
    display: block;
    // left: 50%;
    // top: 50%;
    width: 13px;
    height: 13px;
    background: #f7f7f7;
    // border: 1px solid #6a6a6a;
    // border-width: 0 1px 1px 0;
    // box-shadow: 1px 1px #6a6a6a;
    transform-origin: center;
    will-change: transform;
    transform: translate(-50%, -50%) rotate(45deg);

    @media (max-width: $small-width-breakpoint) {
      width: 11px;
      height: 11px;
    }
    @media (max-width: $smaller-width-breakpoint) {
      width: 8px;
      height: 8px;
    }
  }
}
