@import '../../../size_variables.scss';
@import '../../../color_variables.scss';
@import '../../../constants.scss';

$default-green-color: #809e5f;
$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-text-color: rgb(102, 102, 102);
$default-gray-color: #949494;
$default-dark-blue-color: #364150;
$default-black-color: #3a3839;

$default-dark-blue: #2c3e50;
$default-red-color: #e08283;

$info-source-button-text-color: #737373;
$info-source-button-background-color: #c5c5c5;
$info-source-button-border-color: #a0a0a0;
$info-source-button-disabled-background-color: #f1f1f1;
$info-source-button-disabled-border-color: #2c3e50;
$info-source-button-disabled-text-color: #2c3e50;

$indicator-background-color: #f7f7f7;

.food-prescription-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__nutrition-info {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }
  }

  &__nutrition-info {
    &__nutrient-label {
      min-width: 23em;

      @media (max-width: $small-width-breakpoint) {
        white-space: normal !important;
        min-width: 15em;
        max-width: 15em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        min-width: 9em;
        max-width: 9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        min-width: 0;
        max-width: none;
      }
    }

    &__nutrient-classification {
      margin-top: 0.5em;
    }
  }

  &__add-item-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__add-item-button {
    margin: 0.5em;
    background: $default-green-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__meal-period-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: #f7f7f7;
    margin: 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__item-association {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__content {
      align-self: stretch;
      margin: 0 0 0 0.4em;

      & input {
        font-size: 1.1em;
        font-weight: bold;
        color: #5d5d5d;
        padding: 0.3em 0.5em;
      }
    }

    &__name-label {
      margin: 0;
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    &__priority-text {
      font-size: 0.85em;
      margin: 0.4em 0 0.4em 1.85em;
      padding: 0.2em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 0.2em;
      align-self: flex-start;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &__weekdays {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0.4em 0 0 1.5em;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
      }
    }

    &__inputs-container {
      width: auto;
      align-self: stretch;
      margin: 0 0 0 0.8em;
    }

    &__recipe-ingredient {
      padding: 0;
      border: 1px solid $default-dark-blue-color;
      width: auto;
      align-self: stretch;
      margin: 0;

      &:not(:last-child) {
        border-bottom-width: 0;
      }
      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }

      &__name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.2em 0.4em;
        background: $default-dark-blue-color;
        color: white;

        &__icon {
          font-size: 0.9em;
          margin: 0 0.4em 0 0;
        }
      }

      &__inputs-container {
        width: auto;
        align-self: stretch;
        padding: 0.4em 0.4em 0.6em;
      }
    }
  }

  &__delete-recipe-association-button,
  &__add-food-recipe-button,
  &__switch-order-button,
  &__switch-order-button--hidden {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    align-self: stretch;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__add-food-recipe-button {
    margin: 0.8em 0 0;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__delete-recipe-association-button {
    margin: 0.5em;
    background: $default-red-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-red-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__switch-order-button-container {
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.4em 0 0;
  }

  &__switch-order-button {
    &,
    &--hidden {
      margin: 0.5em 0 0.5em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 3px;
      flex: 1;

      &:hover:not(:disabled) {
        background: darken(#bdbdbd, 15);
      }
    }

    &--hidden {
      visibility: hidden;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      &,
      &--with-border {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        padding: 1em;
        max-height: 70vh;
        overflow: auto;

        @media (max-width: $small-width-breakpoint) {
          padding: 0.4em;
        }

        @media (max-height: $high-height-breakpoint) {
          max-height: 55vh;
        }

        @media (max-height: $small-height-breakpoint) {
          max-height: 40vh;
        }

        &::-webkit-scrollbar {
          width: 0.8em;
          height: 0.8em;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            width: 0.25em;
            height: 0.25em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.2em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3a3839;
          border-radius: 0.2em;
        }
      }

      &--with-border {
        border: 1px solid $site-red-color;
        border-radius: 5px;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__weekday {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0 0.3em 0.6em;
      border-radius: 3px;
      text-align: center;
      white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      width: 7.5em;
      font-size: 0.95em;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.1em 0 0.1em 0.4em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        width: auto;
      }

      @media (max-width: $tiny-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__text {

    }

    &__select-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.2em 0;
      font-size: 1.2em;
    }

    &--disabled &__select-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__tab-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.8em 0 0;

    &__tab-selector {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
    }

    &__tab-item {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      &__button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        background: #efefef;
        -webkit-tap-highlight-color:  transparent;
        border: 1px solid transparent;
        border-bottom-width: 0;
        border-radius: 4px 4px 0 0;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #2c3e50;
          color: white;
          cursor: default;
        }

        &:hover:not(:disabled) {
          border-color: #2c3e50;
        }
      }
    }

    &__tab-content {
      padding: 0.5em;
      border: 1px solid #2c3e50;
      border-radius: 0 5px 5px 5px;
    }
  }

  &__student-name {
    align-self: flex-end;
    max-width: 15em;

    @media (max-width: $smaller-width-breakpoint) {
      align-self: stretch;
      max-width: none;
    }
  }

  &__alert-text {
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    border: solid 1px $site-red-color;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.4em 0.8em;
    margin: 0.4em 0 0.4em 0.4em;
    align-self: stretch;

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__add-meal-period-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0.5em 0 0;

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__add-meal-period-input {
    flex: 1;
  }

  &__add-meal-period-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: $default-gray-color;
      cursor: default;
    }
  }

  &__diet-preview {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 1.5em;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.95em;
      margin: 0 0 1.3em;
    }
    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
      margin: 0 0 1.1em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      margin: 0 0 0.8em;
    }

    &__header {
      background: $default-black-color;
      border: 1px solid $default-black-color;
      border-radius: 5px 5px 0 0;
      color: $default-black-color;
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        -webkit-tap-highlight-color:  transparent;
        font-family: 'Black Ops One', fantasy;
        color: white;
        font-size: 1.7em;
        padding: 0.3em 0.5em 0.2em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.1em;
        }

        &__text {
          font-weight: lighter;
        }

        &__text-icon {
          font-size: 0.9em;
          margin: 0 0.5em 0 0;
        }
      }

      &--disabled {
        background: #b5b5b5;
        color: #5a5a5a;
        cursor: default;
      }

      &__disabled-text {
        text-transform: uppercase;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 1px solid $default-black-color;
      background: white;
      padding: 0.4em;
      border-radius: 0 0 5px 5px;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.3em 0.2em;
      }
    }

    &__meal-period-list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;

      &:not(:last-child) {
        margin: 0 0 1.5em;
      }
    }

    &__meal-period {
      &__title {
        margin-bottom: 0.5em;
      }

      &__note {
        font-family: 'Iceland', cursive;
        white-space: pre-wrap;
        font-size: 1.3em;
        text-align: justify;
        background: #eef1f5;
        color: #606060;
        border: 1px solid #afafaf;
        border-radius: 3px;
        padding: 0.4em;
        margin: 0 0 0.5em;

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.1em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
        }
      }
    }

    &__meal-option {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;

      &:not(:first-child) {
        margin-top: 0.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.9em;
      }

      &__header {
        background: white;
        border: 1px solid #a2a2a2;

        &,
        &--highlighted,
        &--disabled {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.4em 0 0;
          -webkit-tap-highlight-color:  transparent;
          cursor: pointer;
          font-family: 'Montserrat', sans-serif;

          &__text {
            font-weight: bold;
            color: #505050;
          }

          &__text-icon {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            color: white;
            background: #a2a2a2;
            width: 1.4em;
            align-self: stretch;
            font-size: 0.85em;
            font-weight: bold;
          }
        }

        &--highlighted &__text {
          color: $site-red-color;
        }
        &--highlighted &__text-icon {
          color: white;
          background: $site-red-color;
        }

        &--highlighted {
          background: white;
          border: 1px solid $site-red-color;
        }

        &--disabled {
          background: #b5b5b5;
          color: #5a5a5a;
          cursor: default;
        }

        &__wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
        }

        &__content-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: center;
          padding: 0.4em;
          min-width: 0;
          flex: 1;

          @media (max-width: $smaller-width-breakpoint) {
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: flex-start;
          }
        }

        &__visible-icon {
          color: #505050;
        }

        &--highlighted &__visible-icon {
          color: $site-red-color;
        }

        &__disabled-text {
          text-transform: uppercase;
        }
      }

      &__content {
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.4em;
        }
      }

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 1em;
        border: 1px solid #a2a2a2;
        border-top-width: 0;
        background: #f3f3f3;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0.6em;
        }
      }

      &__text {
        text-align: justify;
        white-space: pre-wrap;
        color: $default-black-color;
        padding: 0.2em 0 0.4em;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.6em;
          height: 0.6em;

          @media (max-width: $medium-less-width-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #5d5d5d;
          border-radius: 0.4em;
        }
      }

      &__sub-title {
        font-size: 0.9em;
        margin: 0.2em 0 0 0.5em;
        color: $default-text-color;
        text-decoration: underline;

        @media (max-width: $small-width-breakpoint) {
          margin: 0.5em 0 0 0.3em;
        }
      }

      &__weekday {
        &,
        &--highlighted,
        &--disabled {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          padding: 0;
          border-radius: 0;
          white-space: nowrap;
          margin: 0;
          font-size: 0.85em;
        }

        &__text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          background: #2c3e50;
          color: white;
          border: 0 solid white;
          border-left-width: 1px;
          font-weight: bold;
          padding: 0.1em 0;
        }
        &--highlighted &__text {
          background: $site-red-color;
          color: white;
        }
        &--disabled &__text {
          background: #dcdcdc;
          color: $default-gray-color;
        }

        &:first-child &__text,
        &--highlighted:first-child &__text,
        &--disabled:first-child &__text {
          border-radius: 3px 0 0 0;
          border-left-width: 0;
        }

        &:last-child &__text,
        &--highlighted:last-child &__text,
        &--disabled:last-child &__text {
          border-radius: 0 3px 0 0;
        }

        &__select-button {
          cursor: default;
          -webkit-tap-highlight-color:  transparent;
          outline: none;
          background: none;
          min-width: 18px;
          min-height: 18px;
          color: #2c3e50;
          border: 1px solid $default-gray-color;
          border-right-width: 0;
          border-top-width: 0;
          border-radius: 0;
          margin: 0;
          font-size: 0.9em;
        }
        &--highlighted &__select-button {
          color: $site-red-color;
        }
        &--disabled &__select-button {
          color: $default-gray-color;
          border-color: $default-gray-color;
        }

        &:first-child &__select-button,
        &--highlighted:first-child &__select-button,
        &--disabled:first-child &__select-button {
          border-radius: 0 0 0 3px;
        }

        &:last-child &__select-button,
        &--highlighted:last-child &__select-button,
        &--disabled:last-child &__select-button {
          border-radius: 0 0 3px 0;
          border-right-width: 1px;
        }
      }

      &__weekdays {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0.1em 0 0 0.5em;

        @media (max-width: $small-width-breakpoint) {
          margin: 0.2em 0 0 0.3em;
        }

        // @media (max-width: $smaller-width-breakpoint) {
        //   flex-flow: column nowrap;
        // }
      }

      &__ingredient-list {
        font-family: 'Montserrat', sans-serif;
        list-style: none;
        color: #505050;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__ingredient {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        &:not(:last-child) {
          margin: 0 0 0.9em;
        }

        @media (max-width: $small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: flex-start;
        }

        &__title-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
        }

        &__name-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__bullet {
          align-self: center;
          font-size: 0.4em;
          margin: 0 1em 0 0;
        }

        &__name {
          color: #2c3e50;
          font-weight: bold;
          text-align: justify;
          padding: 0.2em 0 0 0;

          @media (max-width: $small-width-breakpoint) {
            padding: 0;
          }
        }

        &__category {
          font-size: 0.55em;
          text-transform: uppercase;
          background: #2c3e50;
          border-radius: 2px;
          color: white;
          padding: 0.2em 0.4em;
          font-weight: bold;

          @media (max-width: $small-width-breakpoint) {
            font-size: 0.6em;
          }
          @media (max-width: $smaller-width-breakpoint) {
            font-size: 0.65em;
          }
        }

        &__quantity {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-start;
          margin: 0 0 0 0.5em;
          line-height: 2em;

          @media (max-width: $small-width-breakpoint) {
            margin: 0.2em 0 0 1.5em;
          }

          &__wrapper {

          }

          &__label {
            display: none;
            text-transform: uppercase;
            font-size: 0.9em;
            text-decoration: underline;
          }

          &__number,
          &__unit {
            border: 1px solid #2c3e50;
            padding: 0.2em 0.4em;
            white-space: nowrap;
          }

          &__number {
            border-radius: 3px 0 0 3px;
            color: #2c3e50;
            font-weight: bold;
          }

          &__unit {
            background: #2c3e50;
            color: white;
            border-radius: 0 3px 3px 0;
          }

          &__separator {
            margin: 0 0.4em;
          }
        }

        &__sub-title {
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          font-weight: bold;
          color: #7e7e7e;
          font-size: 0.85em;
          text-decoration: underline;
          margin: 0 0 0.2em;
        }
      }

      &__preparation {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 0.9em;
        }

        &__header {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          -webkit-tap-highlight-color:  transparent;
          background: #7e7e7e;
          border: 1px solid #7e7e7e;
          border-radius: 0;
          cursor: pointer;
          font-family: 'Montserrat', sans-serif;
          padding: 0.3em 0.5em 0.2em;

          &__text {
            font-weight: bold;
            text-transform: uppercase;
            color: white;
            font-size: 0.85em;
          }

          &__visible-icon {
            color: white;
          }
        }

        &__content {
          overflow: auto;
          overflow-y: hidden;

          &::-webkit-scrollbar {
            width: 0.4em;
            height: 0.4em;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
            border-radius: 0.4em;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $default-black-color;
            border-radius: 0.4em;
          }
        }

        &__content-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          padding: 1em;
          border: 1px solid #a2a2a2;
          border-top-width: 0;
          background: #f3f3f3;

          @media (max-width: $smaller-width-breakpoint) {
            padding: 0.6em;
          }
        }

        &__description {
          font-family: 'Montserrat', sans-serif;
          text-align: justify;
          white-space: pre-wrap;
          color: $default-black-color;
          padding: 0.2em 0 0.4em;
          overflow: auto;
          overflow-y: hidden;

          &::-webkit-scrollbar {
            width: 0.6em;
            height: 0.6em;

            @media (max-width: $medium-less-width-breakpoint) {
              width: 0.4em;
              height: 0.4em;
            }
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
            border-radius: 0.4em;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #5d5d5d;
            border-radius: 0.4em;
          }
        }

        &__step-list {
          font-family: 'Montserrat', sans-serif;
          list-style: none;
          color: $default-black-color;
          margin: 0.5em 0 0;

          @media (max-width: $smaller-width-breakpoint) {
            font-size: 0.9em;
          }
        }

        &__step {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;

          &:not(:last-child) {
            margin: 0 0 0.9em;
          }

          // @media (max-width: $very-small-width-breakpoint) {
          //   flex-flow: column nowrap;
          //   align-items: flex-start;
          // }

          &__name-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }

          &__bullet {
            align-self: center;
            font-size: 0.4em;
            margin: 0 1em 0 0;
          }

          &__name {
            font-weight: bold;
            text-align: justify;
          }

          &__instruction {
            padding: 0 0 0 0.5em;
            border: 0 solid $default-black-color;
            border-left-width: 2px;
            font-size: 0.95em;
            text-align: justify;
            white-space: pre-wrap;
          }
        }
      }

      &__macronutrient-distribution {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid $default-black-color;
        border-radius: 3px;
        padding: 0.2em 0.4em;
        background: white;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.85em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.8em;
        }

        &__container {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          justify-content: flex-end;
          // margin: 0 0 0.5em;
        }

        &__nutrient {
          &,
          &--carb,
          &--protein,
          &--fat {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }

          &__label {
            margin: 0 0.4em 0 0;
            font-weight: bold;
            width: 0.7em;
            text-align: center;
          }

          &__bar {
            align-self: stretch;
            border-radius: 0 2px 2px 0;
          }

          &__value {
            margin: 0 0 0 0.3em;
          }

          &--carb &__label {
            color: #5899da;
          }
          &--protein &__label {
            color: #da5858;
          }
          &--fat &__label {
            color: #daac58;
          }

          &--carb &__bar {
            background: #5899da;
          }
          &--protein &__bar {
            background: #da5858;
          }
          &--fat &__bar {
            background: #daac58;
          }
        }
      }

      &__people-served {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 0.5em;
        line-height: 2em;
        font-size: 0.95em;

        &__label {
          text-transform: uppercase;
          font-size: 0.9em;
          font-weight: 600;
        }

        &__value,
        &__label {
          border: 1px solid $site-red-color;
          padding: 0 0.4em;
          white-space: nowrap;
        }

        &__value {
          color: $site-red-color;
          font-weight: bold;
          border-radius: 0 3px 3px 0;
        }

        &__label {
          background: $site-red-color;
          color: white;
          border-radius: 3px 0 0 3px;
        }
      }
    }
  }

  &__indicators-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 0 0.8em;
    }
  }

  &__indicators-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      order: 2;
    }
  }

  &__indicator {
    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0;
      border-radius: 3px;
      color: $default-text-color;
      background: $indicator-background-color;
      margin: 0 0.5em 0.5em 0;
      border: 1px solid $site-background-color;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
        justify-content: space-between;
        margin: 0;
        font-size: 0.9em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &--disabled {
      border: 1px solid #ababab;
    }

    &--clickable {
      cursor: pointer;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      color: #505050;
      margin: 0 0.2em 0 0;
      padding: 0.3em;
      max-width: 10.5em;
      text-transform: uppercase;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
        max-width: none;
      }
    }

    &__value {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 0.6em;
      background: $site-background-color;
      color: white;
      white-space: nowrap;

      @media (max-width: $smaller-width-breakpoint) {
        width: 8em;
      }

      &__number {
        font-size: 1.3em;
      }

      &__unit {
        font-size: 0.8em;
      }
    }

    &--disabled > &__value {
      background: #ababab;
      color: #565656;
    }

    & + & {
      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__default-button {
    &:not(:last-child) {
      margin: 0 0.5em 0 0;
    }
  }
}
