@import '../color_variables.scss';
@import '../size_variables.scss';


$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;

$default-border-color: #364150;
$default-dark-row-background: lighten(#364150, 20);

$left-padding-width: 3.5em;

.schedule-grid {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  background: white;
  margin: 1em 0 0;
  border: 1px solid $default-border-color;
  border-radius: 5px;
  max-width: 100%;
  overflow: auto;
  font-size: 1em;

  @media (max-width: $high-width-breakpoint) {
    margin: 0.6em 0 0;
    font-size: 0.95em;
  }
  @media (max-width: $medium-plus-width-breakpoint) {
    font-size: 0.9em;
  }
  @media (max-width: $medium-less-width-breakpoint) {
    font-size: 0.85em;
  }
  @media (max-width: $small-width-breakpoint) {
    font-size: 0.8em;
  }
  @media (max-width: $very-small-width-breakpoint) {
    font-size: 0.75em;
  }
  @media (max-width: $tiny-width-breakpoint) {
    font-size: 0.7em;
  }

  &::-webkit-scrollbar {
    width: 1em;
    height: 1em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.2em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 0.2em;
  }

  &__hour-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: $left-padding-width;

    &__text {
      color: $default-text-color;
      font-weight: bold;
      margin: 0 0 0 0.3em;
    }
  }

  &__vertical-division {
    position: absolute;
    display: block;
    height: 100%;
    width: 1px;
    background: #28282840;
    margin: 0 0 0 $left-padding-width;
  }

  &__interval {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: $default-dark-row-background;
    border-radius: 1em;
    padding: 0.1em 0.4em;
    z-index: 2;
    cursor: default;

    &__text {
      color: white;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.9em;
    }
  }

  &__interval-group {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 1.7em;
  }

  &__day-row {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.3em 0;

    &:nth-child(odd) {
      background: $default-dark-row-background;
    }

    &:not(:last-of-type) {
      border: 0 solid $default-border-color;
      border-bottom-width: 1px;
    }

    &__container {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: $left-padding-width;

      &__text {
        font-weight: 500;
        color: $default-text-color;
      }
    }

    &:nth-child(odd) &__title__text {
      color: white;
    }
  }

  &__day-row:nth-child(odd) &__interval {
    background: white;

    &__text {
      color: $default-text-color;
    }
  }

  &__header-container,
  &__header-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__header-container {
    border: 0 solid $default-text-color;
    border-bottom-width: 1px;
    padding: 0.3em 0;
  }

  &__left-padding {
    display: block;
    flex-shrink: 0;
    width: $left-padding-width;
  }

  &__division-container {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
}
