@import '../../size_variables.scss';
@import '../../constants.scss';
@import '../../color_variables.scss';

$default-dark-blue-color: #364150;
$default-light-gray: #e0e0e0;

$link-dark-gray-color: #656565;
$link-light-gray: #dedede;
$link-background-color: #3598dc;

$alert-list-background: #f7f7f7;
$alert-list-header-border-color: #c3c8ce;
$alert-list-border-color: #e7ecf1;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.desktop {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 70em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__today-text {
    color: $default-dark-blue-color;
    font-size: 1.4em;
    font-weight: lighter;
    background: linear-gradient(to right, $default-light-gray, $default-light-gray 12em, transparent);
    padding: 0.2em 0 0.2em 0.8em;
    border-radius: 3px 0 0 3px;
    text-shadow: 1px 1px 2px rgba( $default-dark-blue-color, 0.7 );
    margin: 0 0 0.8em;

    @media (max-width: $small-width-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      background: linear-gradient(to right, transparent, $default-light-gray 8%, $default-light-gray 92%, transparent);
      border-radius: 0;
      text-align: center;
      padding: 0.2em 0;
      font-size: 1.15em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      background: $default-light-gray;
      border-radius: 3px;
    }
  }

  &__not-found-text {
    text-align: center;
    background: #f5f5f5;
    color: #949494;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5em 0;

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__default-link-button {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
    padding: 0.8em;
    font-size: 0.9em;
    min-width: 35px;
    min-height: 35px;
    transition: background $default-transition-duration;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    text-decoration: none;
    background: $link-light-gray;
    color: $link-dark-gray-color;
    width: 8em;

    @media (max-width: $very-small-width-breakpoint) {
      width: 4em;
      min-height: 35px;
      min-width: 35px;
    }

    &:hover {
      background: darken($link-light-gray, 15);
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__alert-list,
  &__alert-sub-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      &,
      &--red,
      &--yellow {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
      }
    }

    &__list-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__list {
      display: inline-flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      list-style: none;
      flex: 1;
    }
  }

  &__alert-list {
    background: $alert-list-background;

    &__header {
      background: $default-dark-blue-color;
      border: 1px solid $alert-list-header-border-color;
      color: white;
    }

    &__header-text {
      font-weight: lighter;
    }
  }

  &__alert-sub-list {
    background: $alert-list-background;

    &__header {

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      &__bullet-icon {
        font-size: 0.6em;
        margin: 0 0.6em 0 0;
      }

      &--red {
        background: #cd4a4a;
        border: 0 solid white;
        border-bottom-width: 1px;
        color: white;
      }

      &--yellow {
        background: $default-alert-color;
        border: 0 solid white;
        border-bottom-width: 1px;
        color: white;
      }
    }
  }

  &__list-item {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid $alert-list-border-color;
    border-top-width: 0;

    &__description-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 0 0 0 0.8em;
    }

    &__description-text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      flex: 1;
      font-size: 0.8em;
      background: white;
      color: #364150b0;
      margin: 0.5em 1em;
      box-shadow: 0 0 2px #36415052;
      border-radius: 2px;
      padding: 0 0.5em;
      min-width: 10em;
    }

    &__id-text {
      min-width: 7em;
      padding: 0.2em 0.4em 0.2em 0;
    }
  }

  &__status-text {
    background: #cd4a4a;

    &,
    &--alert {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.4em 0.8em;
      text-align: center;
      white-space: nowrap;
      color: white;
      align-self: stretch;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.2em 0.6em;
        font-size: 0.95em;
        font-weight: bold;
      }
    }

    &--alert {
      background: $default-alert-color;
    }
  }

  &__operational-tasks {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    list-style: none;
    margin: 0 0 1.2em;
    background: $default-light-gray;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    padding: 0.4em;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      font-family: 'Iceland', cursive;
      color: #626262;
      font-weight: bold;

      &__initiated,
      &__finished {
        text-align: right;
      }
    }

    &__task  {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.1em 0;

        // &:not(:last-child) {
        //   margin: 0 0 0.2em;
        // }
        &:nth-child(even) {
          background: #a5a5a530;
        }
        &:nth-child(odd) {
          background: #ffffff30;
        }
      }

      &__priority-indicator {
        &--1,
        &--2,
        &--3 {
          align-self: stretch;
          width: 0.2em;
          border-radius: 2px;
          margin: 0 0.2em 0 0;
        }

        &--1 {
          background: #8d8d8d;
        }
        &--2 {
          background: $default-alert-color;
        }
        &--3 {
          background: #cd4a4a;
        }
      }

      &__main {
        cursor: pointer;

        &,
        &--disabled {
          display: flex;
          flex-flow: row nowrap;
          align-items: baseline;
          justify-content: flex-start;
        }
      }

      &__input-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-end;
        align-self: flex-start;
      }

      &__input {
        &,
        &--blue,
        &--green {
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          outline: none;
          background: white;
          min-width: 30px;
          min-height: 30px;
          border: 1px solid #d4d4d4;
          border-radius: 3px;
          margin: 0;

          &:disabled {
            cursor: default;
          }
        }

        &--blue {
          &:disabled {
            color: #4887e9;
            border-color: #4887e9;
          }
        }
        &--green {
          &:disabled {
            color: #73af34;
            border-color: #73af34;
          }
        }

        &__icon {
          font-size: 1.3em;
        }
      }

      &__expandable-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }

        &__header {
          &,
          &--red,
          &--yellow {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            -webkit-tap-highlight-color:  transparent;
          }

          &__visible-icon {
            &,
            &--hidden {
              font-size: 0.7em;
              align-self: center;
              margin: 0 0.4em 0 0;
            }

            &--hidden {
              visibility: hidden;
            }
          }
        }

        &__content {
          background: white;
          border-radius: 3px;
          border: 1px solid #d4d4d4;
          padding: 0.2em 0.4em;
        }
      }

      &__commentary {
        white-space: pre-wrap;
      }
    }

    &__header__name,
    &__task__main,
    &__task__main--disabled {
      flex: 1;
    }

    &__header__initiated,
    &__header__finished,
    &__task__input-wrapper {
      width: 4.6em;
    }

    &__confirmation-window {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em 1.5em 0.8em;
      min-width: 90vw;

      &__header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        align-self: stretch;

        &__title {
          font-family: 'Open Sans', sans-serif;
          // font-family: 'Montserrat', sans-serif;
          font-weight: bold;
          color: #3a3839;
          font-size: 2em;
          text-transform: uppercase;
          text-align: center;

          @media (max-width: $large-width-breakpoint) {
            font-size: 1.8em;
          }

          @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
            font-size: 1.5em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
            font-size: 1.3em;
          }

          @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
            font-size: 1.2em;
          }
        }

        &__red-text {
          color: #a75050;
        }
      }

      &__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1em 1em;
        max-height: 70vh;
        overflow: auto;

        @media (max-width: $small-width-breakpoint) {
          padding: 0 0.4em 0.4em;
        }

        @media (max-height: $high-height-breakpoint) {
          max-height: 55vh;
        }

        @media (max-height: $small-height-breakpoint) {
          max-height: 40vh;
        }

        &::-webkit-scrollbar {
          width: 0.8em;
          height: 0.8em;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            width: 0.25em;
            height: 0.25em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.2em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3a3839;
          border-radius: 0.2em;
        }
      }

      &__action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-end;
        align-self: stretch;
        margin: 1em 0 0;
      }

      &__action-button {
        font-size: 2em;
        padding: 0.2em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.7em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
          border-radius: 3px;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1em;
        }

        &:not(:last-of-type) {
          margin: 0 0.6em 0 0;
        }
      }

      &__horizontal-rule {
        width: 100%;
        border: 0 solid #3a3839;
        border-width: 2px 0 0;
        margin: 0.5em 0;

        @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
          border-width: 1px 0 0;
        }
      }

      &__description {
        text-align: justify;
        margin: 0 0 0.4em;
        color: #353535;
        background: #dbdbdb;
        border-radius: 3px;
        border: 1px solid #f5f5f5;
        padding: 0.3em 0.5em;
      }
    }

    &__no-data-found {
      background: white;
      border-radius: 3px;
      padding: 0.4em;
      font-family: 'Iceland', cursive;

      &__text {
        text-align: center;
        color: #626262;
      }
    }
  }

  &__actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0.4em;
  }

  &__action-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    background: #7c9b74;
    color: white;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken(#7c9b74, 15);
    }

    &:not(:first-of-type) {
      margin: 0.5em 0 0;
    }
  }
}
