@import '../../color_variables.scss';

.financial-category-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__cost-center-text,
  &__hierarchy-text {
    &,
    &--empty {
      flex: 0 1;
      background: #2c3e50;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--empty {
      background: #dcdcdc;
      color: #5a5a5a;
    }
  }

  &__no-cost-center-icon {
    color: $site-red-color;
  }

  &__type-text {
    &__receita,
    &__gasto,
    &__ambos {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &__receita {
      background: $default-green-color;
      color: white;
    }

    &__gasto {
      background: $site-red-color;
      color: white;
    }

    &__ambos {
      background: #dcdcdc;
      color: #5a5a5a;
    }
  }
}
