@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-green-color: #809e5f;
$default-dark-blue: #2c3e50;

$default-red-color: #e08283;
$default-dark-blue-color: #364150;

$parameters-container-background: #f7f7f7;

$stats-border-color: #c3c8ce;

$indicator-background-color: #f7f7f7;

.training-day-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button,
  &__delete-association-button,
  &__edit-association-button,
  &__switch-order-button,
  &__switch-order-button--hidden {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__delete-association-button {
    margin: 0.5em 0.5em 0.5em 0;
    background: $default-red-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-red-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__edit-association-button {
    margin: 0.5em;
    background: $default-light-blue;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__switch-order-button {
    &,
    &--hidden {
      margin: 0.5em 0 0.5em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 3px;

      &:hover:not(:disabled) {
        background: darken(#bdbdbd, 15);
      }
    }

    &--hidden {
      visibility: hidden;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__sub-section {
    margin: 0.9em 0;
  }

  &__add-exercise-group-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 1em 0 0;

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__add-exercise-group-input {
    flex: 1;
  }

  &__add-exercise-group-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: $default-gray-color;
      cursor: default;
    }
  }

  &__associations-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: $parameters-container-background;
    margin: 0.4em 0 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__association {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;
    min-width: 42em;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      padding: 0.4em 0.4em 0.4em 0;
      margin: 0.5em 0;
      min-width: 0;

      // @media (max-width: $smaller-width-breakpoint) {
      //   flex-flow: column nowrap;
      //   align-items: stretch;
      //   justify-content: flex-start;
      // }
    }

    &__content {
      font-size: 1.3em;
      font-weight: bold;
      margin: 0 0 0 0.8em;
      color: #5d5d5d;
    }

    &__exercise-list {
      margin: 0 0 0 1.6em;
      list-style: none;
      background: #efefef;
      border-radius: 3px;
      padding: 0.4em;
    }

    &__exercise {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: #777;
      font-size: 1em;

      &__link {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        user-select: none;
        min-width: 35px;
        min-height: 35px;
        transition: color $default-transition-duration;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        text-decoration: none;
        color: #777;

        &:hover {
          color: $default-red-color;
        }
      }
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    &__additional-info-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      padding: 0.5em;
    }

    &__additional-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.3em 1em;
      background: $default-dark-blue;
      color: white;
      border-radius: 1em;
      font-weight: lighter;
      font-size: 0.95em;

      &:not(:last-child) {
        margin: 0 0 0.5em;
      }

      &__icon {
        margin: 0 0.6em 0 0;
        font-size: 0.9em;
      }
    }

    &__id-text {
      font-size: 0.85em;
      margin: 0.4em 0 0 1.85em;
      padding: 0.2em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 0.2em;
      align-self: flex-start;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__stats {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $stats-border-color;
      border-top-width: 0;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;
    align-self: flex-start;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
      font-weight: bold;
    }

    &__value {
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__id-label {
    margin-right: auto;
    max-width: 9em;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 1em;
    }
  }

  &__import-exercise-group-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    margin: 0.8em 0 0;
    background: #bdbdbd;
    color: #6f6f6f;
    align-self: flex-start;

    @media (max-width: $very-small-width-breakpoint) {
      align-self: stretch;
    }

    &:hover:not(:disabled) {
      background: darken(#bdbdbd, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__association-merge-button {
    &,
    &--active {
      position: absolute;
      top: -1.15em;
      left: 50%;
      width: 0.9em;
      height: 1.6em;
      font-family: 'Open Sans', sans-serif;
      padding: 0;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: 2px solid white;
      border-radius: 1em;
      background: white;
      box-shadow: 0px 0px 1px 1px #ee4f36;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;

      &:disabled {
        cursor: default;
      }
    }

    &--active {
      background: #ee4f36;
    }
  }

  &__parsed-text {
    white-space: pre;
  }
}
