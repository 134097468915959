@import '../size_variables.scss';
@import '../constants.scss';

$default-text-color: #333;
$input-color: #555;
$default-border-color: #c2cad8;
$default-red-color: #ff7878;

$toggle-active-color: rgba(28, 138, 0, 0.55);
$toggle-inactive-color: rgba(245, 27, 16, 0.55);
$toggle-inactive-neutral-color: rgba(169, 169, 169, 0.55);

$disabled-background-color: #eef1f5;

$toggle-visual-width: 6.5em;

%default-input-margin {
  margin: 1em 0 0;

  @media (max-height: $medium-height-breakpoint) {
    margin: 0.5em 0 0;
  }

  @media (max-width: $small-width-breakpoint) {
    margin: 8px 0 0;
  }
}

.default-input {
  &,
  &--horizontal {
    display: flex;
    min-width: 0;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.8em;
    }

    & + .half-wrapper,
    .half-wrapper + &,
    & + & {
      @extend %default-input-margin;
    }
  }

  &__label {
    &,
    &--inverted {
      color: $default-text-color;
      font-size: 0.9em;
      text-align: justify;
    }

    &--inverted {
      order: 1;
    }

    &__warning-message {
      margin: 0 0 0 0.5em;

      &,
      &--bellow {
        font-size: 0.8em;
        color: $default-red-color;
        white-space: normal;
      }
    }
  }

  &__input-wrapper {
    width: 100%;
    min-width: 0;

    &,
    &--inverted {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
    }
  }

  &__input-prefix,
  &__input-suffix {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0.4em 0.8em;
    background: #e8e8e8;
    color: #2c3e50;
    font-size: 0.85em;
    border: 1px solid $default-border-color;
    border-radius: 0 5px 5px 0;
    transition: $default-transition-duration;
  }

  &__input-prefix {
    order: 1;
    border-radius: 5px 0 0 5px;
    border-right-width: 0;
  }

  &__input-suffix {
    order: 3;
    border-radius: 0 5px 5px 0;
    border-left-width: 0;
  }

  &__toggle-input {
    display: none;
  }

  &__toggle-visual {
    &,
    &--inactive-neutral {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: $toggle-visual-width;
      font-size: 0.75em;
      height: 2.95em;
      position: relative;
      border-radius: 2px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.64);
      overflow: hidden;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      transition: background 0.4s, color 0.4s;
      font-weight: bold;

      &::after,
      &::before {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0.3em 0.5em;
        white-space: nowrap;
        height: 100%;
        width: ($toggle-visual-width / 2);
      }

      &::after {
        content: attr(inactive-text);
        // text-shadow: 1px 1px 0 white;
        background: $toggle-inactive-color;
        color: rgba(0, 0, 0, 0.69);
      }

      &::before {
        content: attr(active-text);
        background: $toggle-active-color;
        color: rgba(0, 0, 0, 0.69);
      }

      & > span {
        display: block;
        position: absolute;
        height: 100%;
        width: calc(#{($toggle-visual-width / 2)} + 3px);
        background: linear-gradient(to bottom,#fafafa,#dadada);
        border: 1px solid #bbb;
        border-radius: 2px;
        left: 0;
        transition: left 0.4s;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.64);
      }
    }

    &--inactive-neutral {
      &::after {
        background: $toggle-inactive-neutral-color;
      }
    }
  }

  &__toggle-input:disabled + &__toggle-visual,
  &__toggle-input:disabled + &__toggle-visual--inactive-neutral {
    cursor: default;
  }

  &__toggle-input:checked + &__toggle-visual > span,
  &__toggle-input:checked + &__toggle-visual--inactive-neutral > span {
    left: calc(#{$toggle-visual-width / 2} - 2px);
  }

  &__input,
  &__text-area-input,
  &__select-input {
    &,
    &--highlighted {
      order: 2;
      border: 1px solid $default-border-color;
      outline: none;
      padding: 0.5em 0.8em;
      color: $input-color;
      transition: $default-transition-duration;
      min-width: 0;
      // background: transparent;

      &:not([type=checkbox]) {
        flex: 1;
      }

      &[type=checkbox] {
        width: 1.45em;
        height: 1.45em;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
      }

      &[type=range] {
        padding: 0;
        align-self: center;
        flex: 2;
      }

      &[type=range] + &[type=number] {
        margin-left: 0.5em;
        // border-radius: 5px 0 0 5px;
      }

      &, &::placeholder {
        font-family: 'Open Sans', sans-serif;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.4em;
        font-size: 0.95em;
      }
    }

    &:not([type=range]):disabled {
      &:not([type=checkbox]) {
        appearance: none;
      }

      padding: 0.55em 0.8em;
      background: $disabled-background-color;
      cursor: default;

      &::-ms-expand {
        display: none;
      }
    }
  }

  &__input:focus,
  &__text-area-input:focus,
  &__select-input:focus {
    border-color: darken($default-border-color, 30);
  }

  &__input:focus + &__input-prefix,
  &__text-area-input:focus + &__input-prefix,
  &__select-input:focus + &__input-prefix {
    border-color: #2c3e50;
    background: #2c3e50;
    color: white;
  }

  &__input:focus + &__input-suffix,
  &__text-area-input:focus + &__input-suffix,
  &__select-input:focus + &__input-suffix {
    border-color: #2c3e50;
    background: #2c3e50;
    color: white;
  }

  &__input--highlighted,
  &__text-area-input--highlighted,
  &__select-input--highlighted,
  &__input--highlighted + &__input-prefix,
  &__text-area-input--highlighted + &__input-prefix,
  &__select-input--highlighted + &__input-prefix,
  &__input--highlighted + &__input-suffix,
  &__text-area-input--highlighted + &__input-suffix,
  &__select-input--highlighted + &__input-suffix {
    &, &:focus {
      box-shadow: 0 0 0px 1px #ff7878;
      border-color: $default-red-color;
      outline-offset: 0;
    }
  }

  &__text-area-input,
  &__text-area-input--highlighted {
    resize: none;
  }

  &__question-icon {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 0 0 0.3em;
    color: white;
    background: #b5b5b5;
    border-radius: 50%;
    font-size: 0.7em;
  }
}

.default-input {
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;

  &__label {
    margin: 0 0 0.4em;

    &--bellow {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
    }

    &__text-wrapper {
      display: inline-flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.default-input {
  &--horizontal {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &.align-right {
      justify-content: flex-end;
    }
  }

  &--horizontal.align-right &__input-wrapper {
    width: auto;
  }

  &--horizontal &__label {
    margin: 0 0.6em 0 0;

    &,
    &--inverted {
      white-space: nowrap;
    }

    &--inverted {
      margin: 0 0 0 0.4em;
    }
  }
}

.half-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $small-width-breakpoint) {
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  .default-input,
  .default-input--horizontal,
  .dummy-input,
  & & {
    flex: 1;

    @media (max-width: $small-width-breakpoint) {
      flex: 0;
    }

    &:not(:first-of-type) {
      margin: 0 0 0 1.5em;

      @media (max-width: $medium-width-breakpoint) {
        margin: 0 0 0 1.2em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        margin: 0 0 0 0.8em;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 8px 0 0;
      }
    }
  }

  & + & {
    @extend %default-input-margin;
  }
}

.default-fieldset {
  & + & {
    @extend %default-input-margin;
  }
}

.default-fieldset + .half-wrapper,
.half-wrapper + .default-fieldset,
.default-input + .default-fieldset,
.default-input--horizontal + .default-fieldset,
.default-fieldset + .default-input--horizontal,
.default-fieldset + .default-input {
  @extend %default-input-margin;
}
