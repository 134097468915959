@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-text-color: rgb(102, 102, 102);
$default-gray-color: #949494;

$service-button-text-color: #737373;
$service-button-background-color: #c5c5c5;
$service-button-border-color: #a0a0a0;
$service-button-disabled-background-color: #f1f1f1;
$service-button-disabled-border-color: #2c3e50;
$service-button-disabled-text-color: #2c3e50;

.service-group-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__website-color {
    &__color-input-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0.5em 0 1em;
    }

    &__color-preview {
      display: block;
      width: 3em;
      height: 3.6em;
      border-radius: 3px;
      margin: 0 0.8em 0 0;
      border: 1px solid #c3c8ce;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.8em;
      }
    }
  }
}
