@import '../../size_variables.scss';
@import '../../constants.scss';
@import '../../color_variables.scss';

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.personal-training-service-report {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 80em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__status-cell {
    &--red-1 {
      background: #ffbaba;
    }
    &--red-2 {
      background: #fb9d9e;
    }
    &--red-3 {
      background: #f37778;
    }
    &--yellow {
      background: #fcd602;
    }
    &--green {
      background: #50bc84;
    }
    &--gray {
      background: #cdcdcd;
    }
    &--purple {
      background: #d194eb;
    }
  }

  &__status {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    &__progress {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 1.5em;
      background: white;
      border: 1px solid $default-text-color;
      border-radius: 1em;
      padding: 0;
      margin: 0.3em 0 0;
      z-index: 1;
      overflow: hidden;

      &__value {
        position: absolute;
        z-index: 3;
        font-weight: bold;
        padding: 0 0.4em;
      }

      &__background,
      &__background-cover {
        position: absolute;
        display: block;
        height: 100%;
      }

      &__background {
        background: linear-gradient(to right, #70ff7f 0%, #e7e03a 75%, #ff4700 100%);
        z-index: 1;
        width: 100%;
      }

      &__background-cover {
        background: white;
        z-index: 2;
        right: 0;
      }
    }

    &__info-container {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__info-icon {
      margin: 0 0 0 0.4em;
      font-size: 1.1em;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.2em;
        order: -1;
        margin: 0 0.4em 0 0;
      }
    }

    &__info-text {
      font-size: 0.85em;
    }

    &__sub-text {
      font-size: 0.8em;
      text-align: justify;
      font-weight: bold;
    }
  }
}
