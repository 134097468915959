@import '../../../size_variables.scss';
@import '../../../color_variables.scss';
@import '../../../constants.scss';

$default-green-color: #809e5f;
$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-text-color: rgb(102, 102, 102);
$default-gray-color: #949494;
$default-dark-blue-color: #364150;

$default-dark-blue: #2c3e50;
$default-red-color: #e08283;

$info-source-button-text-color: #737373;
$info-source-button-background-color: #c5c5c5;
$info-source-button-border-color: #a0a0a0;
$info-source-button-disabled-background-color: #f1f1f1;
$info-source-button-disabled-border-color: #2c3e50;
$info-source-button-disabled-text-color: #2c3e50;

.food-recipe-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__add-ingredient,
  &__nutrition-info,
  &__preparation {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }
  }

  &__nutrition-info {
    &__nutrient-label {
      min-width: 23em;

      @media (max-width: $small-width-breakpoint) {
        white-space: normal !important;
        min-width: 15em;
        max-width: 15em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        min-width: 9em;
        max-width: 9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        min-width: 0;
        max-width: none;
      }
    }

    &__nutrient-classification {
      margin-top: 0.5em;
    }
  }

  &__preparation__content-wrapper {
    padding: 0;
  }

  &__add-ingredient-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__add-ingredient-button {
    margin: 0.5em;
    background: $default-green-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__tab-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.8em 0 0;

    &__tab-selector {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
    }

    &__tab-item {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      &__button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        background: #efefef;
        -webkit-tap-highlight-color:  transparent;
        border: 1px solid transparent;
        border-bottom-width: 0;
        border-radius: 4px 4px 0 0;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #2c3e50;
          color: white;
          cursor: default;
        }

        &:hover:not(:disabled) {
          border-color: #2c3e50;
        }
      }
    }

    &__tab-content {
      padding: 0.5em;
      border: 1px solid #2c3e50;
      border-radius: 0 5px 5px 5px;
    }

    &__instructions {
      font-size: 0.9em;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      margin: 0 0 1.5em;
      text-align: center;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__nutrient-label {
      min-width: 23em;

      @media (max-width: $small-width-breakpoint) {
        white-space: normal !important;
        min-width: 15em;
        max-width: 15em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        min-width: 9em;
        max-width: 9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        min-width: 0;
        max-width: none;
      }
    }

    &__default-measurement-cell {
      background: #2c3e50;
      color: white;
    }
  }

  &__ingredients-container,
  &__preparation-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: #f7f7f7;
    margin: 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__ingredient,
  &__preparation-step {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    &__inputs-container {
      width: auto;
      align-self: stretch;
      margin-left: 0.4em !important;
    }
  }

  &__preparation-step {
    &__content {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0 0 0 0.8em;
      color: #5d5d5d;
      width: 10em;
    }
  }

  &__ingredient {
    &__content {
      align-self: stretch;
      margin: 0 0 0 0.4em;

      & input {
        font-size: 1.1em;
        font-weight: bold;
        color: #5d5d5d;
        padding: 0.3em 0.5em;
      }
    }

    &__name-label {
      margin: 0;
    }
  }

  &__preparation-step__content-wrapper {
    margin: 0 0 0 0.8em;
    align-items: stretch;
  }

  &__delete-ingredient-button,
  &__delete-preparation-step-button,
  &__add-preparation-step-button,
  &__switch-order-button,
  &__switch-order-button--hidden {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    align-self: stretch;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__add-preparation-step-button {
    margin: 0.8em 0 0;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__delete-ingredient-button,
  &__delete-preparation-step-button {
    margin: 0.4em 0.4em 0.4em 0;
    background: $default-red-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-red-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__delete-preparation-step-button {
    margin: 0.5em 0.5em 0.5em 0;
  }

  &__switch-order-button-container {
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.4em 0 0;
  }

  &__switch-order-button {
    &,
    &--hidden {
      margin: 0.4em 0 0.4em 0;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 3px;
      flex: 1;

      &:hover:not(:disabled) {
        background: darken(#bdbdbd, 15);
      }
    }

    &--hidden {
      visibility: hidden;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__alert-text {
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    border: solid 1px $site-red-color;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.4em 0.8em;
    margin: 0.4em 0 0.4em 0.4em;
    align-self: stretch;

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__student-name {
    align-self: flex-end;
    max-width: 15em;

    @media (max-width: $smaller-width-breakpoint) {
      align-self: stretch;
      max-width: none;
    }
  }
}
