@import '../color_variables.scss';
@import '../constants.scss';
@import '../size_variables.scss';

$default-dark-blue-color: #2c3e50;
$table-header-text-color: #3f444a;
$table-header-background-color: #f1f4f7;
$table-border-color: #e7ecf1;
$table-border-dark-color: #c3c8ce;
$table-row-text-color: #333;

$action-button-blue-color: #3598dc;
$action-button-red-color: #e08283;
$action-button-light-gray-color: #ccc;
$action-button-gray-color: #717171;

.model-table {
  &__model-actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__default-edit-button,
  &__default-link-button,
  &__default-delete-button {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
    padding: 0.8em;
    font-size: 0.8em;
    min-width: 35px;
    min-height: 35px;
    color: white;
    transition: background $default-transition-duration;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    text-decoration: none;

    &:disabled {
      cursor: default;
      border: 1px solid darken($action-button-light-gray-color, 20);
      color: darken($action-button-light-gray-color, 20);
      background: lighten($action-button-light-gray-color, 7);
    }
  }

  &__default-edit-button {
    background: $action-button-blue-color;

    &:hover:not(:disabled) {
      background: darken($action-button-blue-color, 15);
    }
  }

  &__default-delete-button {
    background: $action-button-red-color;

    &:hover:not(:disabled) {
      background: darken($action-button-red-color, 15);
    }
  }

  &__default-link-button {
    background: $action-button-light-gray-color;
    color: $action-button-gray-color;

    &:hover:not(:disabled) {
      background: darken($action-button-light-gray-color, 15);
    }
  }

  &__default-button {
    font-family: 'Open Sans', sans-serif;
    background: $default-dark-blue-color;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    color: white;
    user-select: none;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;
    transition: $default-transition-duration;
    text-align: center;

    &:not(:first-child) {
      margin: 0 0 0 0.5em;
    }

    &:disabled {
      background: #ccc;
      color: #636363;
      cursor: default;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      &:not(:first-child) {
        margin: 0.4em 0 0;
      }
    }
  }

  &__show-controls,
  &__page-controls,
  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }

  &__buttons {
    margin: 0 0 1.2em;
    justify-content: flex-end;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__show-controls,
  &__page-controls {
    justify-content: space-between;
  }

  &__show-controls {
    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__page-controls {
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__line-count-container,
  &__string-filter-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
    color: $table-row-text-color;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      justify-content: space-between;

      &:not(:first-of-type) {
        margin: 0.5em 0 0;
      }
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__line-count-label,
  &__string-filter-label {
    font-size: 0.9em;

    @media (max-width: $smaller-width-breakpoint) {
      min-width: 9em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      min-width: auto;
    }
  }

  &__line-count-input,
  &__string-filter-input {
    padding: 0.3em 0.5em;
    margin: 0 0 0 0.4em;
    color: #555;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
    }
  }

  &__string-filter-input {
    @media (max-width: $tiny-height-breakpoint) {
      width: 100%;
    }
  }

  &__entries-text-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
    color: $table-row-text-color;
  }

  &__entries-text {
    font-size: 0.9em;
  }

  &__entries-text-span {
    color: $site-red-color;
    font-size: 1.1em;
  }

  &__table-wrapper {
    // min-height: 18.1em;
    margin: 0.8em 0 0.5em;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &__row {
      width: 100%;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.92em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.85em;
      }
    }

    &__header-icon {
      font-size: 0.95em;
      margin: 0 0.3em 0 0;
    }

    &__header-sort-icon,
    &__header-sort-icon--selected {
      margin: 0 0 0 1.2em;
      color: rgba($table-header-text-color, 0.35);
      position: relative;
      right: 0;
    }

    &__header-sort-icon--selected {
      color: $site-red-color;
    }

    &__header-cell,
    &__header-cell--not-sortable,
    &__row-cell,
    &__row-cell--highlighted {
      padding: 0.6em 0.8em;

      @media (max-width: $medium-width-breakpoint) {
        padding: 0.6em 0.6em;
      }

      @media (max-height: $high-height-breakpoint) {
        padding: 0.4em;
      }
    }

    &__header-cell,
    &__header-cell--not-sortable {
      color: $table-header-text-color;
      background: $table-header-background-color;
      font-size: 0.92em;
      font-weight: 400;
      border: 1px solid $table-border-dark-color;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
    }

    &__header-cell--not-sortable {
      cursor: default;
    }

    &__row-cell,
    &__row-cell--highlighted {
      color: $table-row-text-color;
      font-size: 0.9em;
      border: 1px solid $table-border-color;
    }

    &__row-cell--highlighted {
      background: rgba($table-header-text-color, 0.05);
    }

    &__header-wrapper,
    &__header-wrapper--flex {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: space-between;
    }

    &__header-label {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      white-space: nowrap;
    }
  }

  &__page-buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0.5em 0 0;
      justify-content: center;
    }
  }

  &__page-button,
  &__page-button:disabled,
  &__page-button--selected {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.8em;
    color: #337ab7;
    background: none;
    border: 1px solid #ddd;
    outline: none;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    min-width: 35px;
    min-height: 35px;

    &:not(:first-of-type) {
      border-left-width: 0;
    }

    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.85em;
      flex: 1;
    }
  }

  &__page-button {
    &:hover {
      background: #eee;
    }
  }

  &__page-button:disabled {
    cursor: default;
    color: #a9a9a9;
    background: #f3f3f3;
  }

  &__page-button--selected {
    background: #337ab7;
    border-color: #337ab7;
    color: white;
    cursor: default;
  }
}
