@import '../../size_variables.scss';
@import '../../color_variables.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-green-color: #809e5f;
$default-dark-blue: #2c3e50;

$default-red-color: #e08283;

$parameters-container-background: #f7f7f7;

.training-group-exercise-association-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__sub-section {
    margin: 0.9em 0;
  }

  &__configurations-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: $parameters-container-background;
    margin: 0.4em 0 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__configuration,
  &__cycle-configuration {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    margin: 0.1em 0 0;

    & + & {
      margin: 0.6em 0 0;
    }

    &__input-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      padding: 0.8em;
      min-width: 0;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      font-weight: bold;
      color: white;
      background: $default-dark-blue;
      padding: 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
      writing-mode: vertical-lr;
      text-orientation: upright;
      text-transform: uppercase;
    }
  }

  &__configuration {
    min-width: 15em;

    &__detail {
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.7em;
      }
    }
  }

  &__cycle-configuration {
    &__detail {
      writing-mode: horizontal-tb;
    }
  }

  &__replace-exercise {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: #364150;
      border: 1px solid #364150;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }
  }

  &__exercise-filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__reset-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #9e7b7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }

  &__activate-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    background: $default-green-color;
    color: white;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__exercise-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__info-icon {
      margin: 0 0 0 0.4em;
    }
  }

  &__exercise-info {
    white-space: pre-wrap;
    font-size: 0.82em;
  }

  & .model-table__table__row-cell,
  & .model-table__table__row-cell--highlighted {
    font-size: 0.8em;
  }
}
