@import '../../../size_variables.scss';
@import '../../../color_variables.scss';

.contract-edit {
  &__sub-header-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1.2em;

    @media (max-width: $very-small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__sub-title {
    margin: 0 !important;
  }

  &__status-text {
    &__ativo,
    &__vencido,
    &__cancelado,
    &__aceito,
    &__inativo {
      flex: 0 1;
      padding: 0.4em 0.8em;
      text-align: center;
      white-space: nowrap;
      color: white;

      @media (max-width: $smaller-width-breakpoint) {
        align-self: flex-end;
        margin: 0.6em 0 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        align-self: stretch;
      }
    }

    &__ativo {
      background: $default-green-color;
    }

    &__aceito {
      background: $default-gray-color;
    }

    &__vencido,
    &__cancelado {
      background: $default-alert-color;
    }

    &__inativo {
      background: $site-red-color;
    }
  }
}
