@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$text-color: #333;
$activate-period-border-color: #c3c8ce;

$default-red-color: #e08283;
$default-dark-blue-color: #364150;
$indicator-background-color: #f7f7f7;

$default-dark-blue: #2c3e50;

$profile-border-color: #c3c8ce;

$data-column-background: white; // #f7f7f7

$data-color-d: #f37778;
$data-color-i: #fcd602;
$data-color-s: #50bc84;
$data-color-c: #35ade3;


@mixin background($color, $percentage) {
  background: linear-gradient(to left, darken($color, 12%), lighten($color, $percentage));
}

.food-prescription {
  &__name-cell {
    &--d {
      background: $data-color-d;
    }
    &--i {
      background: $data-color-i;
    }
    &--s {
      background: $data-color-s;
    }
    &--c {
      background: $data-color-c;
    }
  }

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__capacity-text {
    color: white;

    &,
    &--gray {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      background: black;
    }

    &--gray {
      color: #929292;
    }
  }

  &__activate-prescription {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $activate-period-border-color;
      border-top-width: 0;
    }
  }

  &__note-to-students {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      margin: 1em 0 0;
      background: #a2a2a2;
      border: 1px solid #a2a2a2;
      color: #5d5d5d;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #5d5d5d;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.4em;
      border: 1px solid #a2a2a2;
      border-top-width: 0;
      background: white;
    }
  }

  &__activate-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    background: $default-green-color;
    color: white;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__active-prescription-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
    border: 1px solid $activate-period-border-color;
    border-radius: 3px;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 0;
    }
  }

  &__active-prescription {
    &__header-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__header {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 0 solid $activate-period-border-color;
      border-width: 0 0 1px 0;
      padding: 0.4em 0.8em;
      background: #eaeaea;

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__controls-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $very-small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
        }
      }

      &__input-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0.5em 0 0 0.8em;

        @media (max-width: $tiny-width-breakpoint) {
          margin: 0.5em 0 0 0;
        }
      }

      &__input-container &__week-duration-input,
      &__input-container &__end-date-input,
      &__input-container &__visible-to-student-input,
      &__input-container &__visible-to-student__sub-input {
        margin: 0.4em 0 0;
      }

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.3em;
        color: $default-dark-blue-color;
        font-weight: bold;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.25em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.15em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.05em;
        }

        &__link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          user-select: none;
          min-width: 35px;
          min-height: 35px;
          transition: $default-transition-duration;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          text-decoration: none;
          color: $default-dark-blue-color;
          font-size: 0.8em;
          border: 1px solid $default-dark-blue-color;
          border-radius: 3px;
          background: transparent;
          margin: 0 0 0 0.4em;

          &:hover {
            color: white;
            background: $default-dark-blue-color;
          }
        }
      }

      &__deactivate-button,
      &__save-button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        border-radius: 3px;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #dcdcdc;
          color: #949494;
          cursor: default;
        }

        &__icon {
          margin: 0 0.5em 0 0;

          @media (max-width: $smaller-width-breakpoint) {
            margin: 0;
          }
        }

        &:not(:last-of-type) {
          margin: 0 0.5em 0 0;

          @media (max-width: $very-small-width-breakpoint) {
            margin: 0 0 0.4em;
          }
        }
      }

      &__save-button {
        background: $default-green-color;
        color: white;

        &:hover:not(:disabled) {
          background: darken($default-green-color, 15);
        }
      }

      &__deactivate-button {
        background: $default-red-color;
        color: white;

        &:hover:not(:disabled) {
          background: darken($default-red-color, 15);
        }
      }

      &__initial-date-input label,
      &__week-duration-input label,
      &__end-date-input label,
      &__visible-to-student-input .default-input__label {
        min-width: 7.4em;
      }

      &__visible-to-student__sub-input .default-input__label {
        min-width: 5.6em;
      }

      &__initial-date-input .default-input__input-wrapper,
      &__week-duration-input .default-input__input-wrapper,
      &__end-date-input .default-input__input-wrapper,
      &__visible-to-student-input .default-input__input-wrapper {
        @media (max-width: $smaller-width-breakpoint) {
          width: 12em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          width: 10em;
        }
      }

      &__visible-to-student-subcontainer {
        border: 0 solid #8f8f8f;
        border-left-width: 3px;
        padding: 0 0 0 0.4em;
      }

      &__alert {
        color: $site-red-color;
        max-width: 22em;
        text-align: center;
        font-size: 0.85em;
        font-weight: bold;
        align-self: center;

        @media (max-width: $small-width-breakpoint) {
          max-width: 18em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          max-width: 12em;
          font-size: 0.8em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          max-width: 12em;
          font-size: 0.75em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          max-width: 11em;
          font-size: 0.7em;
        }
      }
    }

    &__details {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.4em 0.6em;
      margin: 0.5em 0 0;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.85em;
      }

      &__indicators-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $smaller-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          margin: 0 0 0.8em;
        }
      }

      &__indicators-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $smaller-width-breakpoint) {
          flex-flow: column nowrap;
          order: 2;
        }
      }

      &__indicator {
        &,
        &--disabled {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: flex-start;
          padding: 0;
          border-radius: 3px;
          color: $default-text-color;
          background: $indicator-background-color;
          margin: 0 0.5em 0.5em 0;
          border: 1px solid $site-background-color;

          @media (max-width: $smaller-width-breakpoint) {
            flex: 1;
            justify-content: space-between;
            margin: 0;
            font-size: 0.9em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 0.8em;
          }
        }

        &--disabled {
          border: 1px solid #ababab;
        }

        &--clickable {
          cursor: pointer;
        }

        &__label {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 1em;
          font-weight: bold;
          color: #505050;
          margin: 0 0.2em 0 0;
          padding: 0.3em;
          max-width: 10.5em;
          text-transform: uppercase;

          @media (max-width: $smaller-width-breakpoint) {
            flex: 1;
            max-width: none;
          }
        }

        &__value {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          padding: 0.4em 0.6em;
          background: $site-background-color;
          color: white;
          white-space: nowrap;

          @media (max-width: $smaller-width-breakpoint) {
            width: 8em;
          }

          &__number {
            font-size: 1.3em;
          }

          &__unit {
            font-size: 0.8em;
          }
        }

        &--disabled > &__value {
          background: #ababab;
          color: #565656;
        }

        & + & {
          @media (max-width: $smaller-width-breakpoint) {
            margin: 0.5em 0 0;
          }
        }
      }

      &__current-week {
        font-family: 'Iceland', cursive;
        font-weight: bold;
        font-size: 1.5em;
        text-transform: uppercase;
        color: #5a5a5a;
        text-decoration: underline;
      }

      &__status,
      &__status-field {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;
        font-size: 1.3em;

        @media (max-width: $small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: flex-start;
        }

        &__label {
          font-family: 'Iceland', cursive;
          color: #5a5a5a;
        }

        &__value {
          &,
          &--disabled,
          &--danger,
          &--alert,
          &--ok {
            font-family: 'Iceland', cursive;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;

            @media (max-width: $small-width-breakpoint) {
              margin: 0 0 0 1em;
            }
            @media (max-width: $very-small-width-breakpoint) {
              margin: 0 0 0 0.5em;
            }
            @media (max-width: $tiny-width-breakpoint) {
              margin: 0;
            }
          }
        }

        &__number {
          font-family: 'Orbitron', sans-serif;
          font-size: 0.9em;
        }
      }

      &__status {
        font-size: 2em;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.8em;
        }

        &__label {
          min-width: 4.2em;
        }

        &__main-value {
          &,
          &--disabled,
          &--danger,
          &--alert,
          &--ok {
            border-radius: 3px;
            padding: 0.5em;
            text-transform: uppercase;
            font-weight: bold;
            text-align: justify;

            @media (max-width: $very-small-width-breakpoint) {
              font-size: 0.85em;
            }
            @media (max-width: $smaller-width-breakpoint) {
              font-size: 0.8em;
            }
          }

          &--disabled {
            color: #565656;
            background: #ababab;
          }
          &--danger {
            color: white;
            background: #e82310;
          }
          &--alert {
            background: #fdcd01;
            color: #483b00;
          }
          &--ok {
            color: white;
            background: #029642;
          }
        }

        &__description {
          margin: 0.2em 0 0;
          font-size: 0.7em;
          align-self: stretch;
          text-align: justify;
          background: #eef1f5;
          color: #606060;
          border: 1px solid #afafaf;
          border-radius: 3px;
          padding: 0.4em;
        }
      }

      &__status-field {
        margin: 0.2em 0 0;
        &__label {
          min-width: 6.5em;
        }

        &__value {
          &__text {
            &,
            &--disabled,
            &--danger,
            &--alert,
            &--ok {
              font-size: 1.05em;
              padding: 0.2em 0.3em;
              border-radius: 3px;
            }

            &--disabled {
              color: #565656;
              background: #ababab;
            }
            &--danger {
              color: white;
              background: #e82310;
            }
            &--alert {
              background: #fdcd01;
              color: #483b00;
            }
            &--ok {
              color: white;
              background: #029642;
            }
          }
        }

        &__description {
          display: flex;
          flex-flow: row wrap;
          align-items: baseline;
          justify-content: flex-start;
        }
      }
    }
  }

  &__alert-text,
  &__suttle-alert-text {
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5em 0;
    margin: 0 0 0.5em;

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__alert-text-container {
    background: #f5f5f5;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  &__alert-text {
    background: #f5f5f5;
    color: $site-red-color;

    &__button {
      align-self: stretch;
      background: $site-red-color;
      margin: 0 0.5em 0.5em !important;
      border-radius: 3px;
    }
  }

  &__alert-text-container > &__alert-text {
    margin: 0;
  }

  &__suttle-alert-text {
    background: #f5f5f5;
    color: $site-red-color;
  }

  &__actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__list-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 0 1.2em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }

  &__profile,
  &__physicalActivity,
  &__nutritionists-responsible,
  &__diet-sample {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $profile-border-color;
      border-top-width: 0;
    }

    &__buttons-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 1em 0 0;
    }

    &__save-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      border-radius: 3px;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      background: $default-green-color;
      color: white;

      &:hover:not(:disabled) {
        background: darken($default-green-color, 15);
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }

      &__icon {
        margin: 0 0.5em 0 0;

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0;
        }
      }

      &:not(:last-of-type) {
        margin: 0 0.5em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 0.4em;
        }
      }
    }
  }

  &__profile {
    &__disc {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      padding: 0;
      margin: 0 0 0.7em;
      align-self: flex-start;
      background: $data-column-background;
      border-radius: 3px;
      color: $text-color;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        align-self: stretch;
      }

      &__label {
        font-size: 1.2em;
        margin: 0 0.4em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          font-weight: bold;
          flex: 1;
        }
      }

      &__text {
        &--d,
        &--i,
        &--s,
        &--c {
          font-size: 1.1em;
          font-weight: bold;
          color: $text-color;
          padding: 0.4em;
          border-radius: 3px;

          @media (max-width: $very-small-width-breakpoint) {
            padding: 0.4em 0.9em;
          }

          & + & {
            margin: 0 0 0 0.2em;
          }
        }

        &--d {
          @include background($data-color-d, 12%);
        }

        &--i {
          @include background($data-color-i, 12%);
        }

        &--s {
          @include background($data-color-s, 12%);
        }

        &--c {
          @include background($data-color-c, 12%);
        }
      }
    }
  }

  &__diet-sample {
    &__student-instructions-input {
      margin: 1em 0;

      &__label {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    &__allergens-container {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__allergen {
      background: rgba(255,200,0,.65);
      color: rgba(0,0,0,.69);
      font-weight: bold;
      padding: 0.2em 0.5em;
      border-radius: 3px;

      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }
    }
  }

  &__question {
    &__label {
      font-weight: bold;
    }
  }

  &__meal-period-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: #f7f7f7;
    margin: 0;
    overflow: auto;

    &:not(:last-child) {
      margin: 0 0 1.4em;
    }

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__weekday {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0 0.3em 0.6em;
      border-radius: 3px;
      text-align: center;
      white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      width: 6.7em;
      font-size: 0.9em;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.1em 0 0.1em 0.4em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        width: auto;
      }

      @media (max-width: $tiny-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__select-button {
      cursor: default;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 20px;
      min-height: 20px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.2em 0;
      font-size: 0.9em;
    }

    &--disabled &__select-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__weekdays {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.1em 0 0 1.5em;

    @media (max-width: $small-width-breakpoint) {
      margin: 0.2em 0 0 1em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
    }
  }

  &__recipe-association {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__content {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0 0 0 0.8em;
      color: #5d5d5d;
      width: 10em;
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    &__inputs-container {
      width: auto;
      align-self: stretch;
      margin: 0 0 0 0.8em;
    }

    &__ingredient-list {
      list-style: disc inside;
      font-size: 0.85em;
      margin: 0.4em 0 0 1.85em;
      padding: 0.2em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 0.2em;
      align-self: flex-start;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.4em 0 0 1.35em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }

      &__item {
        color: #777;
        font-size: 1em;
      }
    }

    &__weekdays {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0.1em 0 0 1.5em;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.2em 0 0 1em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
      }
    }

    &__sub-title {
      font-size: 0.9em;
      margin: 0.5em 0 0 1.5em;
      color: $default-text-color;
      text-decoration: underline;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.5em 0 0 1em;
      }
    }
  }

  &__sub-section-title {
    margin-top: 1em;
  }

  &__class-energy-mean {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: center;
    background: #eef1f5;
    padding: 0.1em 0.3em;
    border: 1px solid #afafaf;
    border-radius: 2px;
    word-break: keep-all;
    margin: 0 0 0 0.4em;

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
    }

    &__label {
      color: #606060;
      margin: 0 0.2em 0 0;

      @media (max-width: $very-small-width-breakpoint) {
        text-align: center;
        margin: 0 0 0.2em 0;
      }
    }

    &__value {
      font-family: 'Orbitron', sans-serif;
      font-size: 0.85em;
      margin: 0 0.2em 0 0;
    }
  }

  &__graphs-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    overflow: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $site-red-color;
      border-radius: 0.4em;
    }
  }

  &__tracking-status-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.9em;
    }
  }

  &__tracking-status {
    &,
    &--red,
    &--green {
      border-radius: 3px;
      color: white;
      font-size: 1.1em;
      padding: 0.4em;
      text-align: center;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &--red {
      background: $site-red-color;
    }

    &--green {
      background: $default-green-color;
    }

    &__toggle-button {
      margin: 0 0 0 0.4em !important;
    }
  }

  &__tab-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.8em 0 0;

    &__tab-selector {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
    }

    &__tab-item {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      &__button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        background: #efefef;
        -webkit-tap-highlight-color:  transparent;
        border: 1px solid transparent;
        border-bottom-width: 0;
        border-radius: 4px 4px 0 0;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #2c3e50;
          color: white;
          cursor: default;
        }

        &:hover:not(:disabled) {
          border-color: #2c3e50;
        }
      }
    }

    &__tab-content {
      padding: 0.5em;
      border: 1px solid #2c3e50;
      border-radius: 0 5px 5px 5px;
    }

    &__instructions {
      font-size: 0.9em;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      margin: 0 0 1.5em;
      text-align: center;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__nutrient-label {
      min-width: 23em;

      @media (max-width: $small-width-breakpoint) {
        white-space: normal !important;
        min-width: 15em;
        max-width: 15em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        min-width: 9em;
        max-width: 9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        min-width: 0;
        max-width: none;
      }
    }

    &__default-measurement-cell {
      background: #2c3e50;
      color: white;
    }
  }

  &__macronutrient-distribution {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    &__nutrient {
      &,
      &--carb,
      &--protein,
      &--fat {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      &__label {
        margin: 0 0.4em 0 0;
        font-weight: bold;
        width: 0.7em;
        text-align: center;
      }

      &__bar {
        align-self: stretch;
        border-radius: 0 2px 2px 0;
      }

      &__value {
        margin: 0 0 0 0.3em;
      }

      &--carb &__label {
        color: #5899da;
      }
      &--protein &__label {
        color: #da5858;
      }
      &--fat &__label {
        color: #daac58;
      }

      &--carb &__bar {
        background: #5899da;
      }
      &--protein &__bar {
        background: #da5858;
      }
      &--fat &__bar {
        background: #daac58;
      }
    }
  }

  &__followup-report-access {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;

    &__show-button {
      flex: none;
      box-shadow: none;
      border-radius: 3px;
      font-size: 1.6em;
      padding: 0.6em 0.4em;
      flex: 1;
      font-family: 'Iceland', cursive;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 0.9em;
      }

      &__icon {
        font-size: 0.85em;
        margin: 0 0.5em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          display: none;
        }
      }
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    width: 100%;
    height: 90%;
    margin: 0;
    align-self: center;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        // font-family: 'Black Ops One', cursive;
        font-family: 'Black Ops One', fantasy;
        color: #3a3839;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.6em;
        }
      }
    }

    &__content-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      padding: 0.2em;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.4em;
      }
    }

    &__loading-icon {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../../images/preloader.gif), white;
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-family: 'Iceland', cursive;
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__followup-report {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    flex: 1;
    max-width: 105em;
    width: 100%;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.86em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.7em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.6em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.5em;
    }

    &__background {
      position: absolute;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
      background: #074074;
      border-radius: 0.5em;
      z-index: 1;

      @media (max-width: $tiny-width-breakpoint) {
        border-radius: 0.4em;
      }
    }

    &__header,
    &__objectives,
    &__self-evaluation-scores,
    &__score-history,
    &__additional-info,
    &__difficulties-reported {
      z-index: 2;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-around;
      padding: 1.5em 0 1em;
      align-self: center;
      width: 85%;

      @media (max-width: $medium-width-breakpoint) {
        width: 90%;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        width: 100%;
        padding: 1.5em 2em 1em;
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      &__title,
      &__sub-title {
        color: white;
        text-align: center;
      }

      &__title {
        font-size: 4em;
        font-weight: bold;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 3.6em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 3em;
        }

        &__wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          flex: 1;

          @media (max-width: $small-width-breakpoint) {
            order: 2;
          }
        }
      }

      &__sub-title {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        margin: 0 0 0 0.5em;

        @media (max-width: $small-width-breakpoint) {
          order: 1;
          margin: 0 0 0.5em;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-size: 2.2em;

          & span:not(:last-child) {
            margin: 0 0.5em 0 0;
          }
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 2em;
        }
      }
    }

    &__objectives {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      margin: 1.5em 0 0;

      @media (max-width: $small-width-breakpoint) {
        margin: 1em 0 0;
      }

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        @media (max-width: $tiny-width-breakpoint) {
          flex-flow: column nowrap;
        }
      }

      &__checklist {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: center;

        &__item {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 10em;
          height: 10em;
          padding: 2em 0 0;
          border: 0.3em solid white;
          border-radius: 50%;

          @media (max-width: $medium-less-width-breakpoint) {
            border-radius: 25px;
            padding: 1.5em 0 0;
            height: 9.3em;
            width: 9.8em;
            border-width: 0.2em;
          }
          @media (max-width: $small-width-breakpoint) {
            border-radius: 10px;
            width: 10.8em;
            height: 9.8em;
          }
          @media (max-width: $smaller-width-breakpoint) {
            font-size: 1.1em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            width: auto;
            padding: 1.5em 0.8em 0;
            border-radius: 4px;
          }
          @media (max-width: $tiny-width-breakpoint) {
            width: 10.8em;
            height: 8em;
            padding: 0.6em 0 0;
            border-radius: 3px;
          }

          &__background {
            position: absolute;
            width: 8.8em;
            height: 8.8em;
            top: 0.3em;
            border: 0.15em solid white;
            border-radius: 50%;

            @media (max-width: $medium-less-width-breakpoint) {
              border-radius: 20px;
              height: 8.3em;
            }
            @media (max-width: $small-width-breakpoint) {
              border-radius: 7px;
              height: 8.8em;
              width: 9.8em;
            }
            @media (max-width: $smaller-width-breakpoint) {
              display: none;
            }
          }

          &__mark {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-size: 1.2em;
            width: 2.5em;
            height: 2.5em;

            &__icon {
              &--positive,
              &--negative {
                font-size: 2.6em;
              }

              &--positive {
                color: #16d656;
              }

              &--negative {
                color: #ff1765;
              }
            }

            &__corner-1,
            &__corner-2,
            &__corner-3,
            &__corner-4 {
              position: absolute;
              border: 0 solid white;
              width: 0.7em;
              height: 0.7em;
            }

            &__corner-1 {
              top: 0;
              left: 0;
              border-top-width: 4px;
              border-left-width: 4px;

              @media (max-width: $medium-less-width-breakpoint) {
                border-top-width: 2px;
                border-left-width: 2px;
              }
            }

            &__corner-2 {
              top: 0;
              right: 0;
              border-top-width: 4px;
              border-right-width: 4px;

              @media (max-width: $medium-less-width-breakpoint) {
                border-top-width: 2px;
                border-right-width: 2px;
              }
            }

            &__corner-3 {
              bottom: 0;
              left: 0;
              border-bottom-width: 4px;
              border-left-width: 4px;

              @media (max-width: $medium-less-width-breakpoint) {
                border-bottom-width: 2px;
                border-left-width: 2px;
              }
            }

            &__corner-4 {
              bottom: 0;
              right: 0;
              border-bottom-width: 4px;
              border-right-width: 4px;

              @media (max-width: $medium-less-width-breakpoint) {
                border-bottom-width: 2px;
                border-right-width: 2px;
              }
            }
          }

          &__text,
          &__sub-text {
            text-align: center;
            color: white;
          }

          &__text {
            font-weight: bold;
            margin: 0.5em 0 0;
            text-transform: uppercase;
            text-decoration: underline;
            font-size: 1.1em;

            @media (max-width: $small-width-breakpoint) {
              font-size: 1.4em;
            }
          }

          &__sub-text {
            font-size: 0.8em;

            @media (max-width: $medium-less-width-breakpoint) {
              font-size: 1em;
            }
            @media (max-width: $small-width-breakpoint) {
              font-size: 1.2em;
            }
          }
        }
      }

      &__symbol {
        margin: 0 2em;

        @media (max-width: 760px) {
          margin: 0 1em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0.5em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          margin: 0 1.3em;
        }

        &,
        &--more-spaced {
          align-self: center;
        }

        &--more-spaced {
          margin: 0 4em;

          @media (max-width: 760px) {
            margin: 0 2em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            margin: 0 1em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            margin: 1em 0;
          }
        }

        &__icon {
          color: #16d656;

          &,
          &--white {
            font-size: 1.5em;
          }

          &--white {
            color: white;
          }
        }
      }

      &__result {
        &--1,
        &--2,
        &--3 {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: center;
          border: 0.3em solid white;
          padding: 0.6em;
          font-size: 1.3em;

          @media (max-width: $very-small-width-breakpoint) {
            padding: 0.4em;
          }
        }

        &--1 {
          background: #af195d;
        }
        &--2 {
          background: #8e0094;
        }
        &--3 {
          background: #7d18b2;
        }

        &__text,
        &__sub-text {
          text-align: center;
          color: white;
          text-transform: uppercase;
        }

        &__text {
          font-weight: bold;
          font-size: 1.85em;
        }
      }
    }

    &__self-evaluation-scores {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      margin: 2em 0 0;

      @media (max-width: 950px) {
        font-size: 0.9em;
      }
      @media (max-width: 760px) {
        font-size: 0.8em;
      }

      &__days-track {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 24em;
        height: 24em;

        @media (max-width: $very-small-width-breakpoint) {
          width: 18em;
          height: 18em;
          align-self: center;
        }
        @media (max-width: $tiny-width-breakpoint) {
          width: 14em;
          height: 14em;
        }

        &__background {
          &-1,
          &-2 {
            position: absolute;
            display: block;
            object-fit: contain;
          }

          &-1 {
            width: 100%;
            height: 100%;
          }
          &-2 {
            width: 92%;
            height: 92%;
          }
        }

        &__wrapper {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          margin: 3.6em 0 0;

          @media (max-width: $very-small-width-breakpoint) {
            align-self: center;
            margin: 0;
          }
        }

        &__logo-image {
          width: 100%;

          &__wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            max-width: 9em;
            margin: 0 0 0.4em;
          }

          @media (max-width: $very-small-width-breakpoint) {
            display: none;
          }
        }

        &__title,
        &__text {
          color: white;
        }

        &__title {
          font-size: 2.1em;
          font-weight: bold;
          text-transform: uppercase;

          @media (max-width: $tiny-width-breakpoint) {
            font-size: 1.65em;
          }
        }

        &__text {
          font-size: 2.25em;

          @media (max-width: $tiny-width-breakpoint) {
            font-size: 1.75em;
          }

          &--highlight {
            font-size: 1.1em;
            font-weight: bold;
          }
        }
      }

      &__current-values {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 1.8em 0;

        @media (max-width: $very-small-width-breakpoint) {
          padding: 0;
        }

        &__diet,
        &__training {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          width: 4.8em;
          height: 4.8em;
          border: 0.2em solid white;
          border-radius: 50%;
          font-size: 2em;
          box-shadow: 0 0 20px 3px #002342ad;

          @media (max-width: $very-small-width-breakpoint) {
            width: 4.6em;
            height: 4.6em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 1.8em;
            border-width: 1px;
            width: 4.2em;
            height: 4.2em;
          }

          &__value,
          &__label {
            color: white;
            text-transform: uppercase;
            font-weight: bold;
          }

          &__value {
            font-size: 1.7em;

            @media (max-width: $tiny-width-breakpoint) {
              font-size: 1.6em;
            }
          }
          &__label {
            font-size: 0.7em;
          }
        }

        &__diet {
          background: #005f5b;

          @media (max-width: $very-small-width-breakpoint) {
            margin: 0 0 0.5em;
          }
        }
        &__training {
          background: #004f74;
        }
      }

      &__result {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
        }
        @media (max-width: 570px) {
          font-size: 0.9em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.8em;
        }

        &__wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          width: 8.5em;
          height: 8.5em;
          border: 0.45em solid white;
          border-radius: 50%;
          font-size: 2em;
          background: #0094aa;
          box-shadow: 0 0 20px 3px #002342ad;

          @media (max-width: $very-small-width-breakpoint) {
            width: 7.5em;
            height: 7.5em;
            border-width: 3px;
          }
          @media (max-width: $tiny-width-breakpoint) {
            border-width: 2px;
            width: 6.5em;
            height: 6.5em;
          }
        }

        &__value,
        &__label {
          color: white;
          font-weight: bold;
        }

        &__value {
          font-size: 2.8em;

          @media (max-width: $very-small-width-breakpoint) {
            font-size: 2.5em;
          }
        }
        &__label {
          font-size: 0.9em;
        }
      }

      &__separator {
        &-1,
        &-2 {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: space-between;
          width: 6.5em;
          align-self: center;

          @media (max-width: 570px) {
            width: 4em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            width: 3.2em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            width: 2.8em;
          }
        }

        &-1 {
          height: 12em;
          left: -0.5em;
          margin: 0 1em 0 0;

          @media (max-width: $very-small-width-breakpoint) {
            left: 0em;
            height: 9em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            height: 7.5em;
          }
        }
        &-2 {
          height: 6.5em;
          margin: 0 2em 0 1.2em;

          @media (max-width: $tiny-width-breakpoint) {
            margin: 0 1em 0 1.2em;
          }
        }

        &__decorator {
          &-1,
          &-2,
          &-3,
          &-4 {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            height: 0.18em;
            background: white;
            transform-origin: left;

            @media (max-width: $small-width-breakpoint) {
              height: 0.25em;
            }

            &::before,
            &::after {
              display: block;
              position: absolute;
              content: '';
              width: 0.5em;
              height: 0.5em;
              background: white;
              border-radius: 50%;
              transform-origin: center;

              @media (max-width: $small-width-breakpoint) {
                width: 0.8em;
                height: 0.8em;
              }
              @media (max-width: $very-small-width-breakpoint) {
                width: 4px;
                height: 4px;
              }
              @media (max-width: $tiny-width-breakpoint) {
                width: 3px;
                height: 3px;
              }
            }

            &::before {
              left: 0;
              transform: translateX(-50%);
            }
            &::after {
              right: 0;
              transform: translateX(50%);
            }
          }

          &-1,
          &-3 {
            transform: rotate(10deg);
          }
          &-2,
          &-4 {
            transform: rotate(-10deg);
          }

          &-1 {
            @media (max-width: $very-small-width-breakpoint) {
              transform: rotate(-10deg);
            }
          }
          &-2 {
            @media (max-width: $very-small-width-breakpoint) {
              transform: rotate(10deg);
            }
          }
        }
      }
    }

    &__score-history {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      padding: 0 2em;
      margin: 2em 0 1em;

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.8em;
      }

      &__list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        overflow: auto;
        overflow-y: hidden;
        padding: 0 0 0.3em;

        &::-webkit-scrollbar {
          width: 0.6em;
          height: 0.6em;

          @media (max-width: $medium-less-width-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ffffff;
          border-radius: 0.4em;
        }

        &__item {
          &,
          &--faded {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 0.3em 0.3em 0.8em;
            border: 0.2em solid white;
            border-radius: 1em;
            background: linear-gradient(153deg, #0a457a, #003566);
            min-width: 8.4em;

            @media (max-width: 760px) {
              border-radius: 0.5em;
            }
            @media (max-width: 570px) {
              font-size: 0.9em;
              min-width: 8.3em;
            }
            @media (max-width: $smaller-width-breakpoint) {
              min-width: 6.8em;
            }
            @media (max-width: $tiny-width-breakpoint) {
              border-radius: 2px;
              min-width: 5.25em;
            }

            &:not(:last-child) {
              margin: 0 0.8em 0 0;

              @media (max-width: 760px) {
                margin: 0 0.6em 0 0;
              }
              @media (max-width: $tiny-width-breakpoint) {
                margin: 0 0.4em 0 0;
              }
            }
          }

          &__value,
          &__label {
            color: white;
            text-transform: uppercase;
            font-weight: bold;
          }

          &__value {
            font-size: 4.75em;

            @media (max-width: 760px) {
              font-size: 4.5em;
            }
            @media (max-width: $small-width-breakpoint) {
              font-size: 4.4em;
            }
            @media (max-width: $very-small-width-breakpoint) {
              font-size: 4em;
            }
            @media (max-width: $tiny-width-breakpoint) {
              font-size: 3.05em;
            }
          }
          &__label {
            font-size: 1.15em;

            @media (max-width: $smaller-width-breakpoint) {
              font-size: 1.4em;
            }
            @media (max-width: $tiny-width-breakpoint) {
              text-align: center;
              max-width: 3em;
            }
          }

          &--faded &__value,
          &--faded &__label {
            color: #5c80a1;
          }
        }
      }

      &__graphs-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: center;
        margin: 1.7em 0 0;

        @media (max-width: $medium-less-width-breakpoint) {
          flex-flow: column nowrap;
          justify-content: flex-start;
        }
        @media (max-width: $small-width-breakpoint) {
          margin: 0.7em 0 0;
        }
        @media (max-width: $tiny-width-breakpoint) {
          margin: 0.3em 0 0;
        }
      }

      &__graph {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        border: 0.2em solid white;
        border-radius: 1em;
        padding: 0.4em 0 0;
        min-width: 0;
        background: linear-gradient(153deg, #0a457a, #003566);

        @media (max-width: 760px) {
          border-radius: 0.5em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          border-radius: 2px;
        }

        &:not(:last-child) {
          margin: 0 2em 0 0;

          @media (max-width: $medium-width-breakpoint) {
            margin: 0 1em 0 0;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            margin: 0 0 1em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            margin: 0 0 0.6em;
          }
        }

        &__title {
          text-align: center;
          color: white;
          text-transform: uppercase;
          font-size: 2.2em;
          margin: 0 0 0.1em;

          @media (max-width: 950px) {
            font-size: 2.05em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 2.2em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 2em;
          }

          &__highlight {
            &--diet,
            &--training {
              font-weight: 900;
            }

            &--diet {
              color: #16d8c5;
            }
            &--training {
              color: #29a8d0;
            }
          }
        }

        &__wrapper {
          position: relative;
          display: block;
          align-self: stretch;
          height: 28em;
          width: 100%;
          min-height: 12.5em;

          @media (max-width: $medium-width-breakpoint) {
            height: 31vh;
          }
          @media (max-width: 950px) {
            height: 26vh;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            height: 24vh;
          }
          @media (max-width: $small-width-breakpoint) {
            height: 22vh;
          }
          @media (max-width: $very-small-width-breakpoint) {
            height: 20vh;
          }
        }

        &__scroll-container {
          &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
          }
        }
      }
    }

    &__additional-info {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: space-around;
      margin: 1.5em 0 0;
      padding: 0 2.2em;

      @media (max-width: 900px) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        align-self: center;
      }
      @media (max-width: 600px) {
        align-self: stretch;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.8em;
        margin: 1em 0 0;
      }

      &__indicator {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: center;
        margin: 0 0 2em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          margin: 0 0 1.5em;
        }

        &__wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-end;

          @media (max-width: 900px) {
            flex: 1;
          }
          @media (max-width: 600px) {
            min-width: 0;
          }
        }

        &__image {
          &--water,
          &--sleep,
          &--stress {
            &__wrapper {
              display: flex;
              flex-flow: row nowrap;
              align-items: flex-end;
              justify-content: flex-start;

              @media (max-width: $medium-width-breakpoint) {
                font-size: 0.9em;
              }
              @media (max-width: 600px) {
                align-items: center;
                align-self: center;
              }
              @media (max-width: $tiny-width-breakpoint) {
                font-size: 0.7em;
              }
            }
          }

          &--water {
            width: 100%;

            @media (max-width: 900px) {
              width: 4em;
            }
            @media (max-width: 600px) {
              width: 3em;
              margin: 0 0 0 1.6em;
            }

            &__wrapper {
              max-width: 4em;
              margin: 0 1em 0 0;

              @media (max-width: 900px) {
                justify-content: center;
                min-width: 8.3em;
              }
              @media (max-width: 600px) {
                justify-content: flex-start;
                margin: 0 0.5em 0 0;
              }
            }
          }
          &--sleep {
            width: 10.4em;

            @media (max-width: 600px) {
              width: 100%;
            }

            &__wrapper {
              overflow: visible;
              width: 8.3em;
              margin: 0 1em 0 0;

              @media (max-width: 600px) {
                margin: 0 0.5em 0 0;
              }
            }
          }
          &--stress {
            width: 100%;

            @media (max-width: 900px) {
              width: 7.5em;
            }
            @media (max-width: 600px) {
              width: 6em;
            }

            &__wrapper {
              width: 7.5em;
              margin: 0 1em 0 0;

              @media (max-width: 900px) {
                width: 8.3em;
              }
              @media (max-width: 600px) {
                margin: 0 0.5em 0 0;
              }
            }
          }
        }

        &__header {
          &--water,
          &--sleep,
          &--stress {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            justify-content: flex-start;
          }

          &__image {
            width: 100%;

            &__wrapper {
              max-width: 3em;
            }
          }

          &__text-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-end;
          }

          &__subtext,
          &__title {
            color: white;
            text-transform: uppercase;
          }

          &__subtext {
            font-size: 1.1em;
            margin: 0 0 0.2em;

            @media (max-width: $small-width-breakpoint) {
              font-size: 1.3em;
            }

            &--green,
            &--yellow,
            &--red {
              color: white;
              padding: 0.1em 0.6em;
              border-radius: 1em;
              font-weight: bold;
            }

            &--green {
              background: #0cad68;
            }
            &--yellow {
              background: #dc9a43;
            }
            &--red {
              background: #ed3e7d;
            }

            &__icon {
              margin: 0 0.3em 0 0;
              font-size: 1.2em;
            }

            &__icon.fa-angles-up {
              color: #3cefa2;
            }
            &__icon.fa-angles-down {
              color: #ed3e7d;
            }
            &__icon.fa-angles-right {
              color: #dc9a43;
            }
          }

          &__title {
            font-size: 2.2em;
            font-weight: bold;

            @media (max-width: $medium-width-breakpoint) {
              font-size: 2em;
            }
            @media (max-width: 600px) {
              font-size: 1.9em;
            }
            @media (max-width: $tiny-width-breakpoint) {
              font-size: 1.7em;
            }
          }
        }

        &__value-bar {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          justify-content: space-between;

          @media (max-width: $medium-width-breakpoint) {
            font-size: 0.9em;
          }
          @media (max-width: 600px) {
            flex-flow: row wrap;
            justify-content: flex-start;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 0.75em;
          }

          &__image {
            width: 100%;

            &__wrapper {
              &--water,
              &--sleep,
              &--stress {
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-end;
                justify-content: flex-start;
                margin: 0 0.5em 0 0;

                @media (max-width: 600px) {
                  margin: 0 0.5em 0.5em 0;
                }
              }

              &--water {
                max-width: 2.2em;
                width: 2.2em;
              }
              &--sleep {
                max-width: 2.3em;
                width: 2.3em;
              }
              &--stress {
                max-width: 4em;
                width: 4em;
                margin: 0 0.2em 0 0;

                @media (max-width: 600px) {
                  margin: 0 0.2em 0.5em 0;
                }
              }
            }
          }
        }

        &__graph {
          position: relative;
          display: block;
          align-self: stretch;
          height: 22vh;
          width: 34em;
          min-height: 12.5em;
          background: linear-gradient(153deg, #0a457a, #003566);
          border: 1px solid white;
          border-radius: 5px;
          padding: 0.5em 0 0;

          @media (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }

    &__difficulties-reported {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      padding: 0 2em;
      margin: 0 0 2em;

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.2em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        font-size: 1.4em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.8em;
      }

      &__header {
        margin: 0 0 0.5em;

        &__title {
          color: white;
          font-size: 2em;
          text-transform: uppercase;
          font-weight: bold;

          @media (max-width: $smaller-width-breakpoint) {
            font-size: 1.6em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 1.35em;
          }
        }
      }

      &__list {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        list-style: none;
      }

      &__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;

        &:not(:last-child) {
          margin: 0 0 0.8em;

          @media (max-width: 760px) {
            margin: 0 0 0.6em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            margin: 0 0 0.4em;
          }
        }

        &__value,
        &__label {
          color: white;
        }

        &__value {
          border: 1px solid white;
          border-radius: 3px;
          background: linear-gradient(153deg, #0a457a, #003566);
          font-size: 1.2em;
          flex: 1;
          padding: 0.2em 0.4em;
          text-align: justify;
        }
        &__label {
          margin: 0 0.4em 0 0;
          font-size: 1.15em;
          min-width: 7em;
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: bold;

          @media (max-width: $smaller-width-breakpoint) {
            min-width: 5em;
          }
        }

        &--faded &__value,
        &--faded &__label {
          color: #5c80a1;
        }
      }
    }
  }

  &__prescription-row {
    background: #7533333d;
  }
}
