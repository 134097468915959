@import '../../size_variables.scss';
@import '../../color_variables.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-dark-blue: #2c3e50;
$sub-section-background: #f7f7f7;

.financial-movement-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__sub-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: $sub-section-background;
    margin: 0.4em 0 0;
  }

  &__transaction {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;

    & + & {
      margin: 0.6em 0 0;
    }

    &__input-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    & &__value,
    & &__date {
      flex: 1;
    }

    & &__value,
    & &__date,
    & &__completed {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        &:not(:first-of-type) {
          margin: 0.8em 0 0 0.5em;
        }
      }
    }
  }

  &__default-input {
    justify-content: flex-end;
  }

  &__action-button {
    &,
    &--purple {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      &__icon {
        margin: 0 0.5em 0 0;
      }
    }

    &--purple {
      background: #aa78b7;
      color: white;

      &:hover {
        background: darken(#aa78b7, 15) !important;
      }
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__payment-device-process {
    &__message {
      font-size: 0.9em;
      text-align: justify;
      margin: 0 0 0.65em;
      color: #3a3839;
    }
  }

  &__default-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 1em 0;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        background: #f5f5f5;
        border: 1px solid #adadad;
        color: #7b7b7b;

        -webkit-tap-highlight-color:  transparent;
      }

      &--disabled {
        cursor: default;
      }

      &__text-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;
        flex-shrink: 0;
      }

      &__text {
        font-weight: lighter;
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;

        @media (max-width: $small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }

      &--disabled &__visible-icon {
        display: none;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #7b7b7b;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #adadad;
      border-top-width: 0;
    }
  }

  &__search-tag-options-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.6em 0 0;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
    }
  }

  &__search-tag-option {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0.6em 0.3em 0;
      border-radius: 3px;
      text-align: center;
      // white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      font-size: 0.8em;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__check-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.5em 0 0.2em;
      font-size: 1.2em;
    }

    &--disabled &__check-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__active-search-tag {
    padding: 0 0 0 0.5em;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #364150;
      border-radius: 3px;
      color: white;
      font-size: 0.8em;
    }

    &--disabled {
      padding: 0.5em;
    }

    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: none;
      margin: 0;
      font-size: 1.1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0 0 0 0.4em;
      flex-shrink: 1;
      gap: 0.4em;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.3em 0 0 0;
      }
    }
  }

  &__alert-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
      padding: 0.5em;
    }

    &__text-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }
}
