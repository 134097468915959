@import '../color_variables.scss';
@import '../size_variables.scss';

$default-red-button-background: #ff8585;
$default-dark-red-button-background: #a75050;
$default-black-button-background: #929292;
$default-dark-black-button-background: #5d5d5d;
$default-green-button-color: #7ebf5e;
$default-dark-green-button-color: #60844e;
$default-purple-button-color: #ae7cdc;
$default-dark-purple-button-color: #7843a9;
$default-blue-button-color: #5ea9bf;
$default-dark-blue-button-color: #307b90;

.default-menu-button {
  &--red,
  &--black,
  &--green,
  &--purple,
  &--blue {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    // font-family: 'Iceland', cursive;
    font-size: 2em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    color: white;
    flex: 1;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 0 #525252;

    &:disabled {
      cursor: default;
      background: $default-black-button-background;
    }

    &:not(:disabled):active {
      box-shadow: none;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 3.2em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 2.9em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 2.5em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 2.2em;
    }

    @media (max-width: $tiny-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.8em;
    }
  }

  &--red {
    background: radial-gradient($default-red-button-background, darken($default-red-button-background, 4%));

    &:not(:disabled):active {
      background: $default-dark-red-button-background;
    }
  }
  &--black {
    background: radial-gradient($default-black-button-background, darken($default-black-button-background, 4%));

    &:not(:disabled):active {
      background: $default-dark-black-button-background;
    }
  }
  &--purple {
    background: radial-gradient($default-purple-button-color, darken($default-purple-button-color, 8%));

    &:not(:disabled):active {
      background: $default-dark-purple-button-color;
    }
  }
  &--green {
    background: radial-gradient($default-green-button-color, darken($default-green-button-color, 8%));

    &:not(:disabled):active {
      background: $default-dark-green-button-color;
    }
  }
  &--blue {
    background: radial-gradient($default-blue-button-color, darken($default-blue-button-color, 8%));

    &:not(:disabled):active {
      background: $default-dark-blue-button-color;
    }
  }

  &__outset-text {
    position: relative;
    top: 0.055em;
    left: 0.055em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-shadow: -0.055em -0.055em 1px rgba(255, 255, 255, 0.85);
    background-clip: text !important;
    color: transparent;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  &--red &__outset-text {
    background: $default-dark-red-button-background;
  }
  &--black &__outset-text {
    background: $default-dark-black-button-background;
  }
  &--green &__outset-text {
    background: $default-dark-green-button-color;
  }
  &--purple &__outset-text {
    background: $default-dark-purple-button-color;
  }
  &--blue &__outset-text {
    background: $default-dark-blue-button-color;
  }

  &--red:disabled &__outset-text {
    background: $default-dark-black-button-background;
    text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.29);
  }
  &--black:disabled &__outset-text {
    background: $default-dark-black-button-background;
    text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.29);
  }
  &--green:disabled &__outset-text {
    background: $default-dark-black-button-background;
    text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.29);
  }
  &--purple:disabled &__outset-text {
    background: $default-dark-black-button-background;
    text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.29);
  }
  &--blue:disabled &__outset-text {
    background: $default-dark-black-button-background;
    text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.29);
  }
}
