@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$indicator-background-color: #f7f7f7;
$gray-color: #8e96a0;
$red-color: #e08283;
$blue-color: #659be0;

.physical-evaluation {
  max-width: 70em;

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--today {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $red-color;
    }

    &--today {
      background: $blue-color;
    }
  }

  &__status-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__status {
    &,
    &--red,
    &--green {
      border-radius: 3px;
      color: white;
      font-size: 1.1em;
      padding: 0.4em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &--red {
      background: $site-red-color;
    }

    &--green {
      background: $default-green-color;
    }

    &__toggle-button {
      margin: 0 !important;
    }
  }

  &__status-text {
    &--completed,
    &--incompleted {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--incompleted {
      background: $site-red-color;
    }

    &--completed {
      background: $default-green-color;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__time-text {
    font-weight: bold;
    margin: 0 0 0 0.5em;
  }

  &__absent-button {
    border: 0 solid white;
    border-left-width: 1px;
  }

  &__action-container {
    margin: 0 !important;
  }

  &__indicator-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
      font-size: 0.8em;
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__alert-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
      padding: 0.5em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }

  &__photo-button {
    background: #aa78b7;

    &:hover {
      background: darken(#aa78b7, 15);
    }
  }
}
