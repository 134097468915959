@import '../../color_variables.scss';

.exercise-function-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
}
