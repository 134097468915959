@import '../../size_variables.scss';
@import '../../constants.scss';
@import '../../color_variables.scss';

$alert-list-background: #f7f7f7;

$default-border-color: #e7ecf1;
$default-text-color: #333;

$gray-color: #bac3d0;
$alert-color: #f0ad4e;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.nf-coupon-report {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 80em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }

    & .model-table__table__row-cell,
    & .model-table__table__row-cell--highlighted {
      font-size: 0.8em;
    }
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &__title {
      color: $default-text-color;
      font-size: 1.3em;
      background: $alert-list-background;
      border: 1px solid $default-border-color;
      text-align: center;
      font-weight: lighter;
      padding: 0.2em 0;
    }

    &__inputs-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.5em;
      border: 1px solid $default-border-color;
      border-top-width: 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
    }

    &__refresh-button,
    &__reset-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__refresh-button {
      background: #829e7b;
    }
    &__reset-button {
      background: #9e7b7b;
    }
  }

  &__default-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 1em 0 0;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
        color: #7b7b7b;

        -webkit-tap-highlight-color:  transparent;
      }

      &--disabled {
        cursor: default;
      }

      &__text-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;
        flex-shrink: 0;
      }

      &__text {
        font-weight: lighter;
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;

        @media (max-width: $small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }

      &--disabled &__visible-icon {
        display: none;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #7b7b7b;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #d9d9d9;
      border-top-width: 0;
    }
  }

  &__search-tag-options-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.6em 0 0;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
    }
  }

  &__search-tag-option {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0.6em 0.3em 0;
      border-radius: 3px;
      text-align: center;
      // white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      font-size: 0.8em;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__check-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.5em 0 0.2em;
      font-size: 1.2em;
    }

    &--disabled &__check-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__active-search-tag {
    padding: 0 0 0 0.5em;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #364150;
      border-radius: 3px;
      color: white;
      font-size: 0.8em;
    }

    &--disabled {
      padding: 0.5em;
    }

    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: none;
      margin: 0;
      font-size: 1.1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0 0 0 0.4em;
      flex-shrink: 1;
      gap: 0.4em;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.3em 0 0 0;
      }
    }
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--alert,
    &--completed {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $site-red-color;
    }

    &--alert {
      background: $alert-color;
    }

    &--completed {
      background: $default-green-color;
    }
  }

  &__description {
    text-align: justify;
    white-space: pre-wrap;
    font-size: 0.9em;
  }

  &__cell-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__search-tags {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
    gap: 0.2em;
    margin: 0.2em 0 0;
  }

  &__search-tag {
    padding: 0.1em 0.3em;
    background: #2c3e50;
    border-radius: 3px;
    color: white;
  }

  &__select-checkbox {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    outline: none;
    background: none;
    min-width: 35px;
    min-height: 30px;
    color: #2c3e50;
    border: 1px solid #2c3e50;
    border-radius: 3px;
    margin: 0;
    font-size: 1.2em;
  }

  &__selected-input-header {
    width: 3.95em;
  }

  &__selected-input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__indicator-container {
    &,
    &--spaced {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
      }
    }

    &--spaced {
      margin: 0.5em 0 1em;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: #f7f7f7;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
      text-align: center;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__action-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    background: #7c9b74;
    color: white;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken(#7c9b74, 15);
    }

    &:not(:first-of-type) {
      margin: 0.5em 0 0;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__message {
      font-size: 0.9em;
      text-align: justify;
      margin: 0 0 0.65em;
      color: #777777;
      background: #e7e7e7;
      padding: 0.4em 0.8em;
      border: 1px solid #b7b7b7;
      border-radius: 3px;
      font-weight: bold;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__property-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__info-icon {
      font-size: 1.1em;
      margin: 0 0 0 0.4em;
    }
  }
}
