@import '../../size_variables.scss';

.horizontal-bar-graph {
  // position: absolute;

  &__wrapper {
    width: 100%;
    height: 100%;
    min-width: 0;
    padding: 0 10px 10px;

    @media (max-width: 950px) {
      padding: 0 8px 8px;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 0 5px 5px;
    }
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    min-width: 0;
    overflow: visible;
    overflow-y: visible;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;

      @media (max-width: $medium-less-width-breakpoint) {
        width: 4px;
        height: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3a3839;
      border-radius: 0.4em;
    }
  }
}
