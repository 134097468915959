@import '../../../size_variables.scss';
@import '../../../color_variables.scss';

$default-text-color: #333;
$alert-color: #f0ad4e;
$date-text-color: #2c3e50;

.contract-list {
  &__status-text {
    &__ativo,
    &__vencido,
    &__cancelado,
    &__aceito,
    &__inativo {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      color: white;
    }

    &__ativo {
      background: $default-green-color;
    }

    &__aceito {
      background: $default-gray-color;
    }

    &__vencido,
    &__cancelado {
      background: $alert-color;
    }

    &__inativo {
      background: $site-red-color;
    }
  }

  &__manage-button {
    text-transform: uppercase;
    font-size: 0.9em;

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__action-icon {
    margin: 0 0.4em 0 0;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__date-text {
    background: $default-green-color;

    &,
    &--past,
    &--alert {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $site-red-color;
    }

    &--alert {
      background: $alert-color;
    }
  }

  &__service-cell {
    text-align: justify;
    min-width: 12em;
  }
}
