@import '../color_variables.scss';
@import '../size_variables.scss';


$default-border-color: #364150;


.tab-view {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  &__options {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
  }

  &__option {
    z-index: 1;
    transform: translateY(1px);

    &:not(:first-child) {
      margin: 0 0 0 0.4em;
    }

    &__button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: $default-border-color;
      border: 1px solid $default-border-color;
      border-radius: 4px 4px 0 0;
      padding: 0.3em 0.5em;

      @media (max-width: $small-width-breakpoint) {
        border-radius: 3px 3px 0 0;
      }

      &:hover {
        // background: #dcdcdc;
        border-bottom-color: white;
      }

      &:disabled {
        cursor: default;
        background: $default-border-color;
        border-bottom-color: $default-border-color;
        color: white;
      }
    }

    &__text {
      font-size: 1.15em;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.05em;
      }
    }
  }

  &__content-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid $default-border-color;
    width: 100%;
    border-radius: 0 4px 4px 4px;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 0 3px 3px 3px;
    }
  }
}
