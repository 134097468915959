@import '../constants.scss';
@import '../color_variables.scss';
@import '../size_variables.scss';

$default-hover-background-color: #2c3542;
$lighter-hover-background-color: #3e4b5c;
$default-border-color: #3e4b5c;

$default-light-blue-color: #c6cfda;
$default-blue-color: #708096;

%default-side-padding {
  padding-left: 1.2em;
  padding-right: 1.2em;

  @media (max-width: $small-width-breakpoint) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}


.menu-items {
  &__default-item,
  &__default-item--selected,
  &__accordion-item,
  &__sub-item,
  &__sub-item--selected,
  &__organizer-item,
  &__expandable-organizer-item {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    user-select: none;
    transition: $default-transition-duration;

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__default-item,
  &__accordion-item,
  &__sub-item,
  &__organizer-item,
  &__expandable-organizer-item {
    &--selected {
      cursor: default;
      background: rgba( $site-red-color, 0.75 );
    }

    &--selected &__link,
    &--selected &__icon,
    &--selected &__wrapper {
      cursor: default;
      color: white;
    }

    &__link,
    &__wrapper {
      @extend %default-side-padding;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding-top: 0.6em;
      padding-bottom: 0.6em;
      color: $default-light-blue-color;
      font-weight: lighter;
      text-decoration: none;
      outline: none;
      transition: color $default-transition-duration;
    }

    &__text {
      font-size: 0.9em;
      overflow: hidden;
      width: 10.2em;
      white-space: nowrap;
      transition: width $default-transition-duration;
    }

    &__icon {
      margin: 0 0.8em 0 0;
      color: $default-blue-color;
      transition: $default-transition-duration;
    }
  }

  &__default-item + &__default-item,
  &__default-item--selected + &__default-item,
  &__default-item + &__default-item--selected,
  &__default-item--selected + &__default-item--selected,
  &__accordion-item + &__accordion-item,
  &__default-item + &__accordion-item,
  &__default-item--selected + &__accordion-item,
  &__accordion-item + &__default-item,
  &__accordion-item + &__default-item--selected {
    border: 0 solid $default-border-color;
    border-width: 1px 0 0;
  }

  &__default-item:hover,
  &__accordion-item--opened,
  &__accordion-item__wrapper:hover,
  &__expandable-organizer-item__wrapper:hover {
    background: $default-hover-background-color;
  }

  &__sub-item:hover,
  &__expandable-organizer-item &__default-item:hover {
    background: $lighter-hover-background-color;
  }

  &__accordion-item,
  &__expandable-organizer-item {
    &__wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__wrapper:not(&--opened) {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
    }

    &__text-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0 35px 0 0;
      transition: $default-transition-duration;
    }

    &__status-icon {
      width: 1em;
      color: $default-blue-color;
      font-size: 0.7em;
      transition: $default-transition-duration;
      overflow: hidden;
    }

    &__sub-menu {
      padding: 0.5em 0;
      opacity: 1;
      transition: padding $default-transition-duration, opacity $default-transition-duration*0.7;
    }
  }

  &__organizer-item {
    cursor: default;

    &__text {
      color: $default-blue-color;
      text-transform: uppercase;
    }
  }

  &__expandable-organizer-item {
    &__text {
      color: $default-blue-color;
      text-transform: uppercase;
    }

    &__status-icon {
      font-size: 1em;
    }

    &__sub-menu {
      padding: 0;
    }
  }

  &__sub-item,
  &__sub-item--selected {
    &__link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding-left: 2.9em;
      padding-right: 1.1em;
      height: 1.6em;
      transition: $default-transition-duration;
      min-width: 30px;
      min-height: 30px;
    }
  }
}
