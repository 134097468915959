@import '../../size_variables.scss';
@import '../../color_variables.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-dark-gray: #656565;
$sub-section-background: #f7f7f7;

.hr-device-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button,
  &__sync-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  // &__import-training-button  {
  //   font-family: 'Open Sans', sans-serif;
  //   padding: 0.6em 0.8em;
  //   outline: none;
  //   cursor: pointer;
  //   -webkit-tap-highlight-color:  transparent;
  //   border: none;
  //   user-select: none;
  //   font-size: 0.9em;
  //   text-decoration: none;
  //   min-width: 35px;
  //   min-height: 35px;
  //
  //   &:disabled {
  //     background: #dcdcdc;
  //     color: #949494;
  //     cursor: default;
  //   }
  // }

  &__sync-button {
    margin: 0;
    background: #bdbdbd;
    color: #6f6f6f;

    @media (max-width: $very-small-width-breakpoint) {
      align-self: stretch;
    }

    &:hover:not(:disabled) {
      background: darken(#bdbdbd, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__sync-contrainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }
}
