@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$data-border-header-color: #c3c8ce;
$data-border-color: #e7ecf1;
$data-column-background: #f7f7f7;

$data-color-d: #f37778;
$data-color-i: #fcd602;
$data-color-s: #50bc84;
$data-color-c: #35ade3;

$text-color: #333;

%disc-padding {
  padding: 0.4em 0.8em;

  @media (max-width: $small-width-breakpoint) {
    padding: 0.2em 0.6em;
  }
}

@mixin background($color, $percentage) {
  background: linear-gradient(to left, darken($color, 12%), lighten($color, $percentage));
}


.disc {
  &__classification {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
    padding: 0.4em 0.8em;
    align-self: flex-start;
    background: $data-column-background;
    border-radius: 3px;
    color: $text-color;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      align-self: stretch;
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.4em 0 0;

      @media (max-width: $very-small-width-breakpoint) {
        font-weight: bold;
        flex: 1;
      }
    }

    &__text {
      &--d,
      &--i,
      &--s,
      &--c {
        font-size: 1.1em;
        font-weight: bold;
        color: $text-color;
        padding: 0.4em;
        border-radius: 3px;

        @media (max-width: $very-small-width-breakpoint) {
          padding: 0.4em 0.9em;
        }

        & + & {
          margin: 0 0 0 0.2em;
        }
      }

      &--d {
        @include background($data-color-d, 12%);
      }

      &--i {
        @include background($data-color-i, 12%);
      }

      &--s {
        @include background($data-color-s, 12%);
      }

      &--c {
        @include background($data-color-c, 12%);
      }
    }
  }
  
  &__graph {
    max-width: 41.2em;
  }

  &__data-container {
    @extend %default-scroll;
    overflow-y: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.86em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.82em;
    }
  }

  &__data-column {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    list-style: none;
    flex: 0;

    // &__item-wrapper {
    //   flex: 1;
    // }

    &__header-item,
    &__footer-item {
      &--d,
      &--i,
      &--s,
      &--c {
        @extend %disc-padding;
        border: 1px solid $data-border-header-color;
        color: $text-color;
        font-weight: bold;
      }

      &--d {
        @include background($data-color-d, 16%);
      }

      &--i {
        @include background($data-color-i, 16%);
      }

      &--s {
        @include background($data-color-s, 16%);
      }

      &--c {
        @include background($data-color-c, 16%);
      }
    }

    &__footer-item,
    &__item {
      &__value {
        &--d,
        &--i,
        &--s,
        &--c {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          margin: 0 0 0 0.8em;
          width: 1.6em;
          height: 1.6em;
          border-radius: 0.8em;
        }
      }
    }

    &__footer-item {
      &--d,
      &--i,
      &--s,
      &--c {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &__value {
        &--d,
        &--i,
        &--s,
        &--c {
          background: white;
          width: 2em;
          height: 2em;
          border-radius: 1em;
        }

        &--d {
          color: $data-color-d;
        }

        &--i {
          color: $data-color-i;
        }

        &--s {
          color: $data-color-s;
        }

        &--c {
          color: $data-color-c;
        }
      }
    }

    &__item {
      @extend %disc-padding;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
      border: 1px solid $data-border-color;
      border-top-width: 0;
      color: $text-color;
      background: $data-column-background;
      white-space: nowrap;

      &__value {
        &--d,
        &--i,
        &--s,
        &--c {
          color: white;
        }

        &--d {
          @include background($data-color-d, 5%);
        }

        &--i {
          @include background($data-color-i, 5%);
        }

        &--s {
          @include background($data-color-s, 5%);
        }

        &--c {
          @include background($data-color-c, 5%);
        }
      }
    }
  }
}
