@import '../size_variables.scss';

$header-color: #364150;
$border-color: #c3c8ce;

.expandable-section {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  @media (max-width: $small-width-breakpoint) {
    font-size: 0.9em;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.4em 0.8em;
    background: $header-color;
    border: 1px solid $header-color;
    color: white;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;

    &__text {
      font-weight: lighter;
    }

    &__text-icon {
      font-size: 0.9em;
      margin: 0 0.5em 0 0;
    }
  }

  &__content {
    overflow: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em;
    border: 1px solid $border-color;
    border-top-width: 0;
  }
}
