@import '../../color_variables.scss';
@import '../../size_variables.scss';

$gray-color: #8e96a0;
$red-color: #e08283;
$yellow-color: #c5aa48;
$blue-color: #659be0;

.student-list {
  max-width: 75em;

  &__action-container {
    justify-content: center;
  }

  &__status-text {
    &__ativo,
    &__inativo {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &__ativo {
      background: $default-green-color;
      color: white;
    }

    &__inativo {
      background: $site-red-color;
      color: white;
    }
  }

  &__manage-button {
    text-transform: uppercase;
    font-size: 0.9em;

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--warning,
    &--future {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $red-color;
    }

    &--warning {
      background: $yellow-color;
    }

    &--future {
      background: $blue-color;
    }
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button,
    &__reset-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__refresh-button {
      background: #829e7b;
    }
    &__reset-button {
      background: #9e7b7b;
    }

    &__name-initial-selector {
      &,
      &--highlight {
        padding: 0 0.5em 0;
        margin: 1em 0 0;

        @media (max-height: $medium-height-breakpoint) {
          margin: 0.5em 0 0;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 8px 0 0;
        }

        color: $default-text-color;
      }

      &__label {
        font-size: 0.9em;
      }

      &--highlight &__label {
        color: #ed585a;
        font-weight: bold;
        text-decoration: underline;
        margin: 0 0 0.2em;
      }

      &__options {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        justify-content: flex-start;
        gap: 5px;

        &__button {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-family: 'Open Sans', sans-serif;
          padding: 0 0.3em;
          outline: none;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          border: 1px solid #dbdbdb;
          user-select: none;
          font-size: 0.9em;
          text-decoration: none;
          min-width: 35px;
          min-height: 35px;
          border-radius: 3px;
          margin: 0;
          color: $default-text-color;
          background: #dbdbdb;
          font-weight: bold;

          &:hover {
            border: 1px solid $red-color;
          }

          &:disabled {
            background: $red-color;
            cursor: default;
            border: 1px solid $red-color;
          }
        }
      }
    }
  }
}
