@import '../../../color_variables.scss';

.meal-period-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__is-active-text {
    &--green,
    &--red {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--green {
      background: $default-green-color;
      color: white;
    }

    &--red {
      background: $site-red-color;
      color: white;
    }
  }
}
