@import '../../size_variables.scss';
@import '../../constants.scss';

$default-dark-blue-color: #2c3e50;
$default-light-blue: #3598dc;
$default-gray: #e1e5ec;

.student-general-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  &__action-button {
    font-family: 'Open Sans', sans-serif;
    background: $default-dark-blue-color;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    color: white;
    user-select: none;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;
    transition: $default-transition-duration;
    text-align: center;

    &:not(:first-child) {
      margin: 0 0 0 0.5em;
    }

    &:disabled {
      background: #ccc;
      color: #636363;
      cursor: default;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      &:not(:first-child) {
        margin: 0.4em 0 0;
      }
    }
  }
}
