@import '../color_variables.scss';

.preloader {
  &__local-container,
  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: white;
  }

  &__container {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  &__local-container {
    width: 100%;
    // height: 50%;
    height: 100%;
  }

  &__image {
    max-width: 100%;
  }
}
