@import '../../size_variables.scss';
@import '../../color_variables.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-green-color: #809e5f;
$default-dark-blue: #2c3e50;

$default-red-color: #e08283;

$parameters-container-background: #f7f7f7;

.training-exercise-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button,
  &__add-parameter-button,
  &__delete-parameter-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__add-parameter-button {
    margin: 0.8em 0 0;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__delete-parameter-button {
    margin: 0 0.5em;
    background: $default-red-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-red-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__sub-section {
    margin: 0.9em 0;
  }

  &__parameters-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: $parameters-container-background;
    margin: 0.4em 0 0;
  }

  &__parameter {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;

    & + & {
      margin: 0.6em 0 0;
    }

    &__input-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    & &__name,
    & &__unit {
      flex: 1;
    }

    & &__name,
    & &__unit {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        &:not(:first-of-type) {
          margin: 0.8em 0 0 0.5em;
        }
      }
    }
  }
}
