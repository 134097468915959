@import '../../size_variables.scss';
@import '../../color_variables.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-green-color: #809e5f;
$default-dark-blue: #2c3e50;

$default-red-color: #e08283;

$parameters-container-background: #f7f7f7;

$default-dark-blue-color: #364150;

$add-exercise-border-color: #c3c8ce;

$cycle-number-background: #b9b9b9;
$cycle-number-color: #6d6d6d;

.training-day-group-association-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button,
  &__delete-association-button,
  &__exercise-url-button,
  &__edit-association-button,
  &__save-association-button,
  &__add-exercise-button,
  &__switch-order-button,
  &__switch-order-button--hidden {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__delete-association-button {
    margin: 0.5em 0.5em 0.5em 0;
    background: $default-red-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-red-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__exercise-url-button {
    margin: 0.5em 0.5em 0.5em 0;
    background: #ccc;
    color: #717171;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken(#ccc, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__edit-association-button {
    margin: 0.5em;
    background: $default-light-blue;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__save-association-button {
    margin: 0.5em;
    background: $default-green-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__add-exercise-button {
    margin: 0.5em;
    background: $default-green-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__switch-order-button {
    &,
    &--hidden {
      margin: 0.5em 0 0.5em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 3px;

      &:hover:not(:disabled) {
        background: darken(#bdbdbd, 15);
      }
    }

    &--hidden {
      visibility: hidden;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__sub-section {
    margin: 0.9em 0;
  }

  &__associations-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 0.8em;
    background: $parameters-container-background;
    margin: 0.4em 0 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.8em;
      height: 0.8em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.15em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.15em;
    }
  }

  &__associations-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
  }

  &__cycle-number {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 0.4em 0 0;
    font-weight: bold;
    font-size: 1.1em;
    padding: 0 0.4em;
    background: $cycle-number-background;
    color: $cycle-number-color;
    border-radius: 3px;
  }

  &__association {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;
    min-width: 42em;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;
    }

    &__content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.3em;
      font-weight: bold;
      margin: 0 0 0 0.8em;
      color: #5d5d5d;

      &__alert-text {
        font-weight: normal;
        font-size: 0.65em;
        margin: 0 0 0 0.8em;
        background: #ffd8d8;
        border-radius: 3px;
        padding: 0.3em 0.6em;
        color: #ff2121;
      }

      &__edit-button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans', sans-serif;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        background: none;
        border: none;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;
        color: inherit;

        &:disabled {
          cursor: default;
        }
      }
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
      white-space: nowrap;
    }

    &__configuration-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0.5em 0 0 2em;
    }

    &__configuration-entry {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid $default-dark-blue;
      border-radius: 3px;

      &:not(:last-child) {
        margin: 0 0 0.2em;
      }

      &__sub-title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin: 0 0.4em;
        white-space: nowrap;
      }

      &__text-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0.3em 0.4em 0.3em 0;
      }

      &__text {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0.3em 0.4em 0.3em 0;
      }

      &__value {
        &,
        &--extended {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          min-width: 2.5em;
          background: #e0e7f1;
          border: 1px solid #9198a2;
          border-radius: 3px;
          font-weight: bold;
          color: red;
        }

        &--extended {
          min-width: 5em;
        }
      }

      &__input-value {
        margin: 0 !important;
      }

      &__input-value input {
        font-weight: bold;
        color: red;
      }
    }

    &__additional-info-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      padding: 0.5em;
      flex: 0 1;
    }

    &__additional-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.3em 1em;
      background: $default-dark-blue;
      color: white;
      border-radius: 1em;
      font-weight: lighter;
      font-size: 0.95em;

      &:not(:last-child) {
        margin: 0 0 0.5em;
      }

      &__icon {
        margin: 0 0.6em 0 0;
        font-size: 0.9em;
      }
    }
  }

  &__add-exercise,
  &__clock-configuration,
  &__support-table-configuration,
  &__stats {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $add-exercise-border-color;
      border-top-width: 0;
    }
  }

  &__clock-configuration {
    &__color-input-container {
      margin: 1em 0 0;

      @media (max-height: $medium-height-breakpoint) {
        margin: 0.5em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 8px 0 0;
      }

      &,
      &--tabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &__color-preview {
      height: 3.6em;
      border-radius: 3px;
      border: 1px solid #c3c8ce;
      margin: 0 0.8em 0 0;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.8em;
      }

      &,
      &--tabled {
        display: block;
        width: 3em;
      }

      &--tabled {
        align-self: stretch;
        margin: 1px 0;
      }
    }

    &__round-index {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $default-dark-blue-color;
      color: white;
      line-height: 2em;
      border-radius: 3px 0 0 3px;
      min-width: 2em;
    }
  }

  &__support-table-configuration,
  &__clock-configuration {
    &__table-wrapper {
      padding: 0 1em;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0;
      }

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__cell-input {
      flex: 1;

      @media (max-width: $small-width-breakpoint) {
        & input {
          width: 5em;
        }
      }

      @media (max-width: $very-small-width-breakpoint) {
        & input {
          font-size: 1.3em;
        }
      }

      & .default-input__input-suffix {
        border-radius: 0;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.3em;
        }
      }
      & .default-input__input-wrapper {
        justify-content: center;
      }
    }

    &__table {
      width: 100%;
      border-collapse: collapse;

      &__header-cell {
        color: #3f444a;
        background: #f1f4f7;
        font-size: 1em;
        font-weight: bold;
        border: 1px solid #c3c8ce;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
      }

      &__row-cell {
        color: #333;
        font-size: 0.9em;
        border: 1px solid #e7ecf1;
        padding: 0;
      }

      &__row {
        width: 100%;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.92em;
        }
      }
    }

    &__add-row-button,
    &__remove-row-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 2px;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__add-row-button {
      background: $default-green-color;
      color: white;
      width: 100%;

      &__icon {
        margin: 0 0.5em 0 0;
      }

      &:hover:not(:disabled) {
        background: darken($default-green-color, 15);
      }
    }

    &__remove-row-button {
      background: $default-red-color;
      color: white;

      &:hover:not(:disabled) {
        background: darken($default-red-color, 15);
      }
    }
  }

  &__description-cell {
    text-align: justify;
    white-space: pre-wrap;
    min-width: 20em;
    font-size: 0.7em;
  }

  &__parsed-text {
    white-space: pre;
  }

  &__id-label {
    margin-right: auto;
    max-width: 9em;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 1em;
    }
  }

  &__alert-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
      padding: 0.5em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    & .model-table__table__row-cell,
    & .model-table__table__row-cell--highlighted {
      font-size: 0.8em;
    }
  }

  &__exercise-filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__reset-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #9e7b7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }

  &__activate-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    background: $default-green-color;
    color: white;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__exercise-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__info-icon {
      margin: 0 0 0 0.4em;
    }
  }

  &__exercise-info {
    white-space: pre-wrap;
    font-size: 0.82em;
  }

  &__file-input {
    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      background: #b593be;
      color: white;
      text-transform: uppercase;

      &:hover {
        background: darken(#aa78b7, 15);
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }

      &__icon {
        margin: 0 0.5em 0 0;
      }
    }

    &__input {
      display: none;
    }
  }

  &__music-file {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    // background: #b593be;
    border: 2px solid #b593be;
    padding: 0.4em;
    border-radius: 4px;
    gap: 2em;

    @media (max-width: $small-width-breakpoint) {
      border: 1px solid #b593be;
      gap: 1em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      gap: 0.5em;
      padding: 0.3em 0.2em;
    }

    &__audio-player {
      flex: 1;

      @media (max-width: $very-small-width-breakpoint) {
        flex: auto;
        width: 100%;
      }
    }

    &__remove-button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      margin: 0 0.5em 0 0;
      background: $default-red-color;
      color: white;
      border-radius: 3px;

      @media (max-width: $small-width-breakpoint) {
        margin: 0 0.3em 0 0;
      }
      @media (max-width: $very-small-width-breakpoint) {
        margin: 0;
      }

      &:hover:not(:disabled) {
        background: darken($default-red-color, 15);
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }

      &__icon {
        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0.4em 0 0;
        }
      }
    }
  }

  & .model-table__table__row-cell,
  & .model-table__table__row-cell--highlighted {
    font-size: 0.8em;
  }
}
