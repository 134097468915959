@import '../../size_variables.scss';
@import '../../constants.scss';
@import '../../color_variables.scss';

$default-dark-blue-color: #364150;
$default-light-gray: #e0e0e0;

$link-dark-gray-color: #656565;
$link-light-gray: #dedede;
$link-background-color: #3598dc;

$alert-list-background: #f7f7f7;

$default-header-border-color: #c3c8ce;
$default-border-color: #e7ecf1;
$default-text-color: #333;

$indicator-background-color: #f7f7f7;

$default-red-color: #e08283;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.class-report {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 70em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__period-control {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &__title {
      color: $default-text-color;
      font-size: 1.3em;
      background: $alert-list-background;
      border: 1px solid $default-border-color;
      text-align: center;
      font-weight: lighter;
      padding: 0.2em 0;
    }

    &__inputs-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.5em;
      border: 1px solid $default-border-color;
      border-top-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }

  &__indicator-container {
    &,
    &--spaced {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
      }
    }

    &--spaced {
      margin: 0.5em 0;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
      text-align: center;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__report-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 0.5em;

    &:not(:first-of-type) {
      margin: 0.5em 0;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }

    &__wrapper {
      &,
      &--stretched {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: auto;
        padding: 0.4em;
        border: 1px solid $default-dark-blue-color;
        border-top-width: 0;
        border-radius:  0 0 3px 3px;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #2c3e50;
          border-radius: 0.4em;
        }
      }

      &--stretched {
        align-items: stretch;
      }
    }
  }

  &__graph {
    align-self: stretch;
  }

  &__coach-report {
    &__green-header {
      background: #78bb67;
    }
    &__yellow-header {
      background: #edf570;
    }
    &__red-header {
      background: #ea6767;
    }

    &__green-cell {
      background: #78bb679c;
    }
    &__yellow-cell {
      background: #edf5709c;
    }
    &__red-cell {
      background: #ea67679c;
    }

    &__role-experiente-level {
      margin: 0.3em 0 0;
      align-self: flex-end;
      background: linear-gradient(144deg, #919191, #d5d5d5, #919191);
      color: #565656;
      padding: 0.4em 0.6em;
      border-radius: 3px;
      text-shadow: 1px 1px 0px #e5e5e5;
      font-weight: bold;
      border: 1px solid #a7a7a7;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &__resume {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 0 0.5em;
      background: #c1c1c1;
      padding: 0.5em;
      border-radius: 3px;

      &__line {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: space-between;

        &:not(:first-of-type) {
          margin: 0.5em 0 0;
        }
      }

      &__value,
      &__label {
        font-size: 1em;
        font-weight: bold;
        text-decoration: underline;
      }

      &__value {
        color: $default-text-color;
        text-decoration-color: #686868;
      }
      &__label {
        color: #686868;
      }

      &__separator {
        flex: 1;
        border: 0 solid #686868;
        border-bottom-width: 1px;
        height: 1em;
        margin: 0 0 0.151em;
      }
    }

    &__actions-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__action-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      background: #7c9b74;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }

      &:hover:not(:disabled) {
        background: darken(#7c9b74, 15);
      }

      &:not(:first-of-type) {
        margin: 0.5em 0 0;
      }
    }

    &__total-text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      &__link {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        user-select: none;
        min-width: 35px;
        min-height: 35px;
        transition: color $default-transition-duration;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        text-decoration: none;
        color: #777;

        &:hover {
          color: $default-red-color;
        }
      }
    }
  }

  &__alert-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
      padding: 0.5em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }
}
