@import '../../../color_variables.scss';

.food-classification-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__icon-cell {
    text-align: center;
    padding: 0;
    font-size: 1.2em;
    width: 7.5em;
  }

  &__hierarchy-text {
    &,
    &--empty {
      flex: 0 1;
      background: #2c3e50;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--empty {
      background: #dcdcdc;
      color: #5a5a5a;
    }
  }
}
