@import '../../color_variables.scss';
@import '../../constants.scss';
@import '../../size_variables.scss';

$blue-color: #659be0;
$red-color: #e08283;
$gray-color: #bac3d0;
$dark-blue-color: #2b3643;
$green-color: #809e5f;
$deny-red-color: #c75563;

$service-button-text-color: #737373;
$service-button-background-color: #c5c5c5;
$service-button-border-color: #a0a0a0;
$service-button-disabled-background-color: #f1f1f1;
$service-button-disabled-border-color: #2c3e50;
$service-button-disabled-text-color: #2c3e50;

$date-text-width-breakpoint: 550px;

.experimental-class-list {
  max-width: 80em;

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__contact {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;

    &__email,
    &__phone {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--today {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $red-color;
    }

    &--today {
      background: $blue-color;
    }
  }

  &__date-text--today &__time-text {
    color: $dark-blue-color;
    font-size: 1.05em;
    font-weight: bold;
  }

  &__time-text {
    margin: 0 0 0 0.6em;

    @media (max-width: $date-text-width-breakpoint) {
      margin: 0;
    }
  }

  &__physical-condition-text {
    &,
    &--empty {
      flex: 0 1;
      background: #2c3e50;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--empty {
      background: #dcdcdc;
      color: #5a5a5a;
    }
  }

  &__no-physical-condition-icon {
    color: $site-red-color;
  }

  &__check-in-button,
  &__check-out-button {
    &,
    &--flex {
      font-family: 'Open Sans', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      user-select: none;
      padding: 0.8em;
      font-size: 0.8em;
      min-width: 35px;
      min-height: 35px;
      color: white;
      transition: background $default-transition-duration;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      text-decoration: none;

      &:disabled {
        cursor: default;
      }
    }

    &--flex {
      flex: 1;
      justify-content: space-between;

      @media (max-width: 420px) {
        justify-content: center;
      }
    }
  }

  &__check-in-button {
    &,
    &--flex {
      background: $green-color;

      &:hover:not(:disabled) {
        background: darken($green-color, 15);
      }
    }
  }

  &__check-out-button {
    &,
    &--flex {
      background: $deny-red-color;

      &:hover:not(:disabled) {
        background: darken($deny-red-color, 15);
      }
    }
  }

  &__check-in-button &__check-icon {
    margin: 0 0.5em 0 0;
  }

  &__check-in-button--flex &__check-icon {
    margin: 0;
    order: 2;
  }

  &__check-out-button &__check-icon {
    margin: 0 0 0 0.5em;
  }

  &__check-out-button--flex &__check-icon {
    margin: 0;
    order: 2;
  }

  &__service-selector-header {
    box-shadow: none;
    margin: 0 0 0.5em;
  }

  &__service-selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__service-button {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: $service-button-background-color;
    color: $service-button-text-color;
    border: 0 solid $service-button-border-color;
    border-width: 2px 0;

    &,
    &:disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.6em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-weight: bold;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    & &__counter,
    &:disabled &__counter {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 1.8em;
      height: 1.8em;
      margin: 0 0 0 0.5em;
      border-radius: 50%;
      font-size: 0.85em;
    }

    & &__counter {
      background: $service-button-text-color;
      color: $service-button-background-color;
    }

    &:disabled &__counter {
      background: $service-button-disabled-text-color;
      color: $service-button-disabled-background-color;
    }

    &:disabled {
      cursor: default;
      background: $service-button-disabled-background-color;
      color: $service-button-disabled-text-color;
      border: 0 solid $service-button-disabled-border-color;
      border-width: 2px 0;
    }
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }
}
