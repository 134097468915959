@import '../../../color_variables.scss';
@import '../../../size_variables.scss';

.food-prescription-list {
  max-width: 70em;

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }
  }

  &__list-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 0 1.2em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__is-active-text {
    &__ativo,
    &__inativo {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &__ativo {
      background: $default-green-color;
      color: white;
    }

    &__inativo {
      background: $site-red-color;
      color: white;
    }
  }
}
