@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';


.image-picker {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  &__image-container {
    border: 1px solid #d5d5d5;
    border-bottom-width: 0;

    &,
    &--no-border {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background: #ebebeb;
      width: 5.5em;
      height: 5.5em;
      border-radius: 4px 4px 0 0;      
      
      @media (max-width: $very-small-width-breakpoint) {
        border-radius: 3px 3px 0 0;
      }
    }

    &--no-border {
      border: none;
    }
  }

  &__current-image {
    object-fit: contain;
    max-width: 100%;
  }

  &__image-placeholder {
    font-size: 2.5em;
    color: #7c7c7c;
  }

  &__controls {
    &,
    &--hidden {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;
    }

    &--hidden {
      visibility: hidden;
    }

    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-family: 'Open Sans', sans-serif;
      border: 1px solid #d5d5d5;
      outline: none;
      user-select: none;
      padding: 0.2em;
      font-size: 0.55em;
      min-width: 27px;
      min-height: 27px;
      color: #8c8c8c;
      transition: background 0.4s;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      text-decoration: none;
      background: white;

      &:hover:not(:disabled) {
        background: darken(white, 15);
      }

      &:not(:first-child) {
        border-left-width: 0;
      }
      &:first-child {
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }

    &__file-input {
      display: none;
    }
  }

  &__selection-window {
    &,
    &--hidden {
      position: fixed;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(32,33,36,.6);
      z-index: 20;
      touch-action: none;
    }

    &--hidden {
      display: none;
    }

    &__main-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      border-radius: 0.5em;
      box-shadow: 2px 2px 3px #00000033;
      max-width: 99vw;

      @media (max-width: $very-small-width-breakpoint) {
        border-radius: 3px;
      }
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.1em 0.1em;

      &__close-button,
      &__switch-camera {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans', sans-serif;
        border: none;
        outline: none;
        user-select: none;
        padding: 0.2em;
        font-size: 0.7em;
        width: 2.5em;
        height: 2.5em;
        border-radius: 50%;
        min-width: 35px;
        min-height: 35px;
        color: #787878;
        transition: background 0.4s;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        text-decoration: none;
        background: white;
  
        &:hover:not(:disabled) {
          background: darken(white, 12);
        }

        &__icon {
          font-size: 1.3em;
        }
      }
    }

    &__content-wrapper {
      &,
      &--loaded {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        background: center no-repeat url(../images/preloader.gif), white;
        background-size: 250px;  
        transition: width 0.8s, height 0.8s;     
        overflow: hidden;
      }

      &--loaded {
        background: #4a4a4a;
      }
    }

    &__video {
      transition: all 0.8s;
    }

    &__mask-overlay {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #00000069;
      mask-image: radial-gradient(closest-side circle at center, transparent 100%, black 100%);
    }

    &__control-frame {
      &::before,
      &::after {
        opacity: 0;
      }
      
      &,
      &--dragging {
        opacity: 1;

        &::before,
        &::after {
          position: absolute;
          display: block;
          content: "";
          border: 0 solid #a5a5a5;
          user-select: none;
          transition: opacity 0.4s;
        }
  
        &::before {
          border-width: 0 2px;
          width: 33.333%;
          height: 100%;
        }
        &::after {
          border-width: 2px 0;
          width: 100%;
          height: 33.333%;
        }
      }

      &--dragging {
        &::before,
        &::after {
          opacity: 1;
        }
      }

      &,
      &--dragging,
      &--hidden {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: opacity 0.8s;
        border: 2px solid #a5a5a5;
        cursor: grab;
      }

      &--hidden {
        display: none;
        opacity: 0;
      }

      &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &__top-left-control,
      &__top-right-control,
      &__bottom-left-control,
      &__bottom-right-control {
        position: absolute;
        border: 0 solid white;
        width: 1.1em;
        height: 1.1em;
      }

      &__top-left-control {        
        top: -4px;
        left: -4px;
        border-width: 4px 0 0 4px;
        cursor: nwse-resize;

        @media (max-width: $very-small-width-breakpoint) {
          top: -3px;
          left: -3px;
          border-width: 3px 0 0 3px;
        }
      }
      &__top-right-control{        
        top: -4px;
        right: -4px;
        border-width: 4px 4px 0 0;
        cursor: nesw-resize;

        @media (max-width: $very-small-width-breakpoint) {
          top: -3px;
          right: -3px;
          border-width: 3px 3px 0 0;
        }
      }
      &__bottom-left-control {        
        bottom: -4px;
        left: -4px;
        border-width: 0 0 4px 4px;
        cursor: nesw-resize;

        @media (max-width: $very-small-width-breakpoint) {
          bottom: -3px;
          left: -3px;
          border-width: 0 0 3px 3px;
        }
      }
      &__bottom-right-control {        
        bottom: -4px;
        right: -4px;
        border-width: 0 4px 4px 0;
        cursor: nwse-resize;

        @media (max-width: $very-small-width-breakpoint) {
          bottom: -3px;
          right: -3px;
          border-width: 0 3px 3px 0;
        }
      }
    }

    &__canvas {
      display: none;
      position: absolute;
    }

    &__selected-image {
      display: none;
      position: absolute;
      transition: all 0.8s;
      max-width: 100vw;
      max-height: 100vh;
    }

    &__footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    &__action-button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans', sans-serif;
      flex: 1;
      border: none;
      outline: none;
      user-select: none;
      padding: 0.2em;
      font-size: 0.7em;
      min-width: 35px;
      min-height: 35px;
      border-radius: 0 0 0.5em 0.5em;
      color: #787878;
      transition: background 0.4s, color 0.4s;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      text-decoration: none;
      background: linear-gradient(22deg, #e3e3e3, white, #e3e3e3);
      box-shadow: inset -1px -1px 1px white, inset 1px 1px 1px #979797;
      text-shadow: 1px 1px 1px white;
      text-transform: uppercase;
      font-weight: bold;

      @media (max-width: $very-small-width-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      &:hover:not(:disabled) {
        background: linear-gradient(22deg, #e3e3e3, #e3e3e3, #e3e3e3);
        color: darken(#787878, 10);
      }

      &:not(:first-child) {
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        border-bottom-right-radius: 0;
      }

      &:disabled {
        cursor: default;
        color: #c3c3c3;
        background: #a0a0a0;
        box-shadow: none;
        text-shadow: 1px 1px 1px #686868;
      }

      &__icon {
        margin: 0 0.4em 0 0;
      }
    }
  }

  &__message-prompt {
    font-size: 0.8em;
    z-index: 30;
  }
}
