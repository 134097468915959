@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$default-text-color: #333;
$alert-color: #f0ad4e;
$date-text-color: #2c3e50;
$default-dark-blue-color: #2c3e50;

$status-right-margin: 0.5em;

$indicator-background-color: #f7f7f7;

.overview {
  &__data-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__registry-data-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 1em 0 0;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0;
    }
  }

  &__status-data-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    flex: 1;
    margin: 0 -1*$status-right-margin 0 0;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin-top: 0.6em;
    }
  }

  &__registry-data {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.9em;
    color: $default-text-color;

    @media (max-width: $smaller-width-breakpoint) {
      align-self: stretch;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 0 0.3em 0 0;
      font-weight: bold;
      width: 7.5em;

      &__icon {
        margin: 0 0.2em 0 0;
      }
    }

    &__text {
      box-shadow: 0 1px 0 $default-text-color;
    }
  }

  &__status {
    &,
    &--ativo,
    &--alerta,
    &--normal,
    &--inativo {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.5em 1em;
      color: white;
      margin: 0 $status-right-margin 0.4em 0;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.7em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        order: 1;
      }
    }

    &--ativo,
    &--inativo {
      @media (max-width: $smaller-width-breakpoint) {
        order: 0;
      }
    }

    &--ativo {
      background: $default-green-color;
    }

    &--inativo {
      background: $site-red-color;
    }

    &--alerta {
      background: $alert-color;
    }

    &--normal {
      background: $default-gray-color;
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }

    &__text {
      text-transform: uppercase;
    }

    &__date-text {
      color: $date-text-color;
    }
  }

  &__notifications {
    &__cell-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      &--centered {
        justify-content: center;
      }

      &--button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
      }
    }

    &__status-text {
      &--processing,
      &--accepted,
      &--delivered,
      &--permanent_failure,
      &--temporary_failure,
      &--opened,
      &--clicked,
      &--unsubscribed,
      &--complained,
      &--stored,
      &--rejected,
      &--unknown,
      &--processing {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        flex: 0 1;
        padding: 0.3em 0.6em;
        color: white;
        border-radius: 4px;
        text-align: center;
        white-space: nowrap;
      }

      &--unsubscribed,
      &--complained,
      &--rejected,
      &--unknown,
      &--permanent_failure,
      &--temporary_failure {
        background: $site-red-color;
      }

      &--opened,
      &--clicked {
        background: $default-green-color;
      }

      &--stored,
      &--accepted,
      &--delivered,
      &--processing {
        background: $default-gray-color;
      }

      &__icon {
        margin: 0 0.5em 0 0;
      }
    }

    &__status-data {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &__row {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__label {
        font-weight: bold;
        margin: 0 0.5em 0 0;
        min-width: 5.5em;
        max-width: 5.5em;
      }

      &__value {
        text-align: justify;
        overflow-wrap: anywhere;
      }
    }
  }

  &__indicator-container {
    &,
    &--spaced {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
      }
    }

    &--spaced {
      margin: 0.5em 0;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__action-button {
    font-family: 'Open Sans', sans-serif;
    background: $default-dark-blue-color;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    color: white;
    user-select: none;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;
    transition: $default-transition-duration;
    text-align: center;

    &:not(:first-child) {
      margin: 0 0 0 0.5em;
    }

    &:disabled {
      background: #ccc;
      color: #636363;
      cursor: default;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      &:not(:first-child) {
        margin: 0.4em 0 0;
      }
    }
  }

  &__actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0.5em;
  }
}
