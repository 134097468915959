@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-text-color: rgb(102, 102, 102);
$default-gray-color: #949494;

$service-button-text-color: #737373;
$service-button-background-color: #c5c5c5;
$service-button-border-color: #a0a0a0;
$service-button-disabled-background-color: #f1f1f1;
$service-button-disabled-border-color: #2c3e50;
$service-button-disabled-text-color: #2c3e50;

.service-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__days-title,
  &__hour-manager-title {
    text-transform: uppercase;
    font-size: 1.1em;
    color: $default-text-color;
    width: 100%;
    text-align: center;
    border: 0 solid $default-text-color;
    border-width: 0 0 1px;
    padding: 0 0 0.1em;
    margin: 0 0 0.4em;
  }

  &__days {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 1em 0 0;

    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: space-between;
      flex: 1;
    }

    &__input {
      display: none;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      padding: 0.8em 1.6em;
      border: 2px solid white;
      color: #333;
      user-select: none;
      font-size: 1em;
      outline: none;
      margin: 0 0 0.3em;
      white-space: nowrap;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.5em 1em;
      }

      &:last-of-type {
        margin: 0;
      }

      &[dayid="0"] {
        border-color: #e84c3d;

        input:checked + & {
          background: #e84c3d;
        }
      }

      &[dayid="1"] {
        border-color: #e77e22;

        input:checked + & {
          background: #e77e22;
        }
      }

      &[dayid="2"] {
        border-color: #f2c40f;

        input:checked + & {
          background: #f2c40f;
        }
      }

      &[dayid="3"] {
        border-color: #3ab54b;

        input:checked + & {
          background: #3ab54b;
        }
      }

      &[dayid="4"] {
        border-color: #00aff0;

        input:checked + & {
          background: #00aff0;
        }
      }

      &[dayid="5"] {
        border-color: #0054a5;

        input:checked + & {
          background: #0054a5;
        }
      }

      &[dayid="6"] {
        border-color: #652d90;

        input:checked + & {
          background: #652d90;
        }
      }

      input:checked + & {
        color: white;
        cursor: default;
      }
    }
  }

  &__hour-manager {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
  }

  &__hours {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -0.5em 0 0;
  }

  &__hour {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0 0.3em 0.6em;
      border-radius: 3px;
      text-align: center;
      white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      width: 5.5em;
      font-size: 0.95em;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.1em 0 0.1em 0.4em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__text {

    }

    &__select-time-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.2em 0 0.5em;
      font-size: 1.2em;
    }

    &--disabled &__select-time-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__service-selector-header {
    box-shadow: none;
    margin: 0 0 0.5em;
  }

  &__service-selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0.8em;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__service-button {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: $service-button-background-color;
    color: $service-button-text-color;
    border: 0 solid $service-button-border-color;
    border-width: 2px 0;

    &,
    &:disabled {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.6em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-weight: bold;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &:disabled {
      cursor: default;
      background: $service-button-disabled-background-color;
      color: $service-button-disabled-text-color;
      border: 0 solid $service-button-disabled-border-color;
      border-width: 2px 0;
    }
  }

  &__instructions {
    font-size: 0.92em;
    text-transform: uppercase;
    color: #333;
    font-weight: bold;
    margin: 0 0 1em;
    text-align: justify;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__website-color {
    &__color-input-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0.5em 0 1em;
    }

    &__color-preview {
      display: block;
      width: 3em;
      height: 3.6em;
      border-radius: 3px;
      margin: 0 0.8em 0 0;
      border: 1px solid #c3c8ce;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.8em;
      }
    }
  }
}
