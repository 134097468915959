$site-red-color: #e83f53;
$site-background-color: #364150;
$default-gray-color: #9e9fa1;

$default-green-color: #57904f;

$default-golden-color: #d6af36;
$default-silver-color: #a7a7ad;
$default-bronze-color: #a77044;

$default-alert-color: #f0ad4e;

$default-text-color: #333;

$burned-color: #af7272;
