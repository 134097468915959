@import '../../color_variables.scss';
@import '../../constants.scss';
@import '../../size_variables.scss';

$gray-color: #8e96a0;
$red-color: #e08283;
$blue-color: #659be0;

.operational-task-list {
  max-width: 70em;

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__date-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex: 0 1;
    padding: 0.3em 0.6em;
    color: white;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;
    background: $gray-color;
  }

  &__status-text {
    &--enabled,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--disabled {
      background: $site-red-color;
    }

    &--enabled {
      background: $default-green-color;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button,
    &__reset-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__refresh-button {
      background: #829e7b;
    }
    &__reset-button {
      background: #9e7b7b;
    }
  }

  &__task-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__info-icon {
      margin: 0 0 0 0.4em;
    }
  }

  &__commentary-popup {
    white-space: pre-wrap;
    font-size: 0.82em;
  }
}
