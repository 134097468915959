@import '../../color_variables.scss';
@import '../../constants.scss';
@import '../../size_variables.scss';

$default-dark-blue-color: #2c3e50;
$default-text-color: rgb(102, 102, 102);
$default-light-blue: #3598dc;
$default-green-color: #809e5f;
$default-gray-color: #949494;

$service-button-text-color: #737373;
$service-button-background-color: #c5c5c5;
$service-button-border-color: #a0a0a0;
$service-button-disabled-background-color: #f1f1f1;
$service-button-disabled-border-color: #2c3e50;
$service-button-disabled-text-color: #2c3e50;

.training-time {
  max-width: 70em;

  &__content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    padding: 0.4em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }
  }

  &__days-title,
  &__hour-manager-title {
    text-transform: uppercase;
    font-size: 1.1em;
    color: $default-text-color;
    width: 100%;
    text-align: center;
    border: 0 solid $default-text-color;
    border-width: 0 0 1px;
    padding: 0 0 0.1em;
    margin: 0 0 0.4em;
  }

  &__days {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 1em 0 0;

    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
    }

    &__input {
      display: none;
    }

    &__label {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      padding: 0.6em 1.4em;
      border: 2px solid white;
      color: #333;
      user-select: none;
      font-size: 1em;
      outline: none;
      margin: 0 0 0.3em;
      white-space: nowrap;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.5em 1em;
      }

      &:last-of-type {
        margin: 0;
      }

      &[dayid="0"] {
        border-color: #e84c3d;

        input:checked + & {
          background: #e84c3d;
        }
      }

      &[dayid="1"] {
        border-color: #e77e22;

        input:checked + & {
          background: #e77e22;
        }
      }

      &[dayid="2"] {
        border-color: #f2c40f;

        input:checked + & {
          background: #f2c40f;
        }
      }

      &[dayid="3"] {
        border-color: #3ab54b;

        input:checked + & {
          background: #3ab54b;
        }
      }

      &[dayid="4"] {
        border-color: #00aff0;

        input:checked + & {
          background: #00aff0;
        }
      }

      &[dayid="5"] {
        border-color: #0054a5;

        input:checked + & {
          background: #0054a5;
        }
      }

      &[dayid="6"] {
        border-color: #652d90;

        input:checked + & {
          background: #652d90;
        }
      }

      input:checked + & {
        color: white;
        cursor: default;
      }

      &__minor-text {
        font-size: 0.85em;
      }
    }
  }

  &__hour-manager {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
    min-width: 0;
  }

  &__hours {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -0.5em 0 0;
  }

  &__hour {
    background: #2c3e50;
    color: white;

    &,
    &--past,
    &--gray {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      // padding: 0.3em 0 0.3em 0.3em;
      border-radius: 3px;
      text-align: center;
      white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      width: 5.5em;
      font-size: 0.95em;
      min-width: 10em;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.1em 0 0.1em 0.1em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        flex: 1;
      }
    }

    &--past,
    &--gray {
      background: #dcdcdc;
      color: #636363;
    }

    &__main-content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__text-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.2em 0;

      &:last-child {
        margin: 0 0.5em 0 0;
      }
    }

    &__target-student-count {
      &,
      &--highlighted {
        font-size: 0.85em;
        line-height: 0.9em;
      }

      &--highlighted {
        color: $site-red-color;
        font-weight: bold;
      }
    }

    &__buttons-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      align-self: stretch;
      margin: 0 0 0 0.3em;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
      }
    }

    &__edit-button,
    &__remove-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: none;

      &:hover {
        background: #dcdcdc;
        color: #2c3e50
      }

      &:last-child {
        border-radius: 0 2px 2px 0;

        @media (max-width: $smaller-width-breakpoint) {
          border-radius: 0;
        }
      }
    }

    &--past &__edit-button,
    &--past &__remove-button {
      color: #636363;
    }

    &__select-time-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0.3em;
      font-size: 1.2em;

      @media (max-width: $smaller-width-breakpoint) {
        align-self: stretch;
        margin: 0.2em;
        min-width: 30px;
      }
    }

    &--past &__select-time-button,
    &--gray &__select-time-button {
      color: #636363;
      border-color: #636363;
    }

    &__coaches {
      display: inline-flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      background: white;
      margin: 0.2em;
      padding: 0.2em;
      border-radius: 3px;
    }

    &__coach-name {
      color: #2c3e50;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__add-hour-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 1em 0 0;

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__add-hour-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: $default-gray-color;
      cursor: default;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
  }

  &__save-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: $default-gray-color;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__data-saved-text {
    color: $default-gray-color;
    font-size: 1em;
    white-space: nowrap;
    margin: 0 0 0 1em;
  }

  &__links-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-end;
    margin: 0 0 0.5em;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__default-button {
    font-family: 'Open Sans', sans-serif;
    background: $default-dark-blue-color;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    color: white;
    user-select: none;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;

    &:not(:first-of-type) {
      margin: 0 0 0 0.5em;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      text-align: center;
    }
  }

  &__service-selector-header {
    box-shadow: none;
    margin: 0 0 0.5em;
  }

  &__service-selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0.8em;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__service-button {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: $service-button-background-color;
    color: $service-button-text-color;
    border: 0 solid $service-button-border-color;
    border-width: 2px 0;

    &,
    &:disabled {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.6em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-weight: bold;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &:disabled {
      cursor: default;
      background: $service-button-disabled-background-color;
      color: $service-button-disabled-text-color;
      border: 0 solid $service-button-disabled-border-color;
      border-width: 2px 0;
    }
  }

  &__class-duration-input,
  &__checkin-limit-input {
    align-self: flex-start;

    & .default-input__label {
      width: 12.5em;
    }

    & .default-input__input-wrapper {
      flex: 1;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__exception {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0.4em;
    }
  }

  &__exception &__add-hour-wrapper {
    margin: 0;
  }

  &__tab-option-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__info-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0.3em;
  }

  &__context-popup {
    white-space: pre-wrap;
  }

  &__default-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 1em 0 0;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
        color: #7b7b7b;

        -webkit-tap-highlight-color:  transparent;
      }

      &--disabled {
        cursor: default;
      }

      &__text-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;
        flex-shrink: 0;
      }

      &__text {
        font-weight: lighter;
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;

        @media (max-width: $small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }

      &--disabled &__visible-icon {
        display: none;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #7b7b7b;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #d9d9d9;
      border-top-width: 0;
    }
  }

  &__active-coaches {
    padding: 0 0 0 0.5em;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #364150;
      border-radius: 3px;
      color: white;
      font-size: 0.8em;
    }

    &--disabled {
      padding: 0.5em;
    }

    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: none;
      margin: 0;
      font-size: 1.1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0 0 0 0.4em;
      flex-shrink: 1;
      gap: 0.4em;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.3em 0 0 0;
      }
    }
  }

  &__coach-options {
    &__container {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0.6em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
      }
    }
  }

  &__coach-option {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0.6em 0.3em 0;
      border-radius: 3px;
      text-align: center;
      // white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      font-size: 0.8em;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__check-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.5em 0 0.2em;
      font-size: 1.2em;
    }

    &--disabled &__check-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__active-coach-option {
    padding: 0 0 0 0.5em;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #364150;
      border-radius: 3px;
      color: white;
      font-size: 0.8em;
    }

    &--disabled {
      padding: 0.5em;
    }

    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: none;
      margin: 0;
      font-size: 1.1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0 0 0 0.4em;
      flex-shrink: 1;
      gap: 0.4em;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.3em 0 0 0;
      }
    }
  }

  &__schedule-grid-entry {
    &--selected {
      background-color: lighten($site-red-color, 3) !important;

      & .schedule-grid__interval__text {
        color: white !important;
      }
    }

    &--gray {
      background-color: #8b8b8b !important;

      & .schedule-grid__interval__text {
        color: white !important;
      }
    }

    &__popup-content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &__text {
        font-weight: bold;
      }
    }
  }

  &__overview-table {
    &__section-title {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    &__cell--highlight {
      background: transparentize(#ea4d5f, 0.2);
      color: white;
    }

    &__total-row {
      background: transparentize($default-dark-blue-color, 0.6);
    }
  }
}
