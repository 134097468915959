@import '../../color_variables.scss';
@import '../../constants.scss';
@import '../../size_variables.scss';

$gray-color: #8e96a0;
$red-color: #e08283;
$blue-color: #659be0;

$action-button-green-color: #7fa965;
$action-button-red-color: #e08283;
$action-button-yellow-color: #bdb44e;
$action-button-light-gray-color: #ccc;
$action-button-gray-color: #717171;

.appointment-list {
  max-width: 70em;

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--today {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $red-color;
    }

    &--today {
      background: $blue-color;
    }
  }

  &__status-text {
    &,
    &--attended,
    &--missed {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    & {
      background: #d1d1d1;
      color: #767676;
    }

    &--missed {
      background: $site-red-color;
    }

    &--attended {
      background: $default-green-color;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__type-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex: 0 1;
    padding: 0.3em 0.6em;
    color: white;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;
  }

  &__time-text {
    font-weight: bold;
    margin: 0 0 0 0.5em;
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button,
    &__reset-button, {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__refresh-button {
      background: #829e7b;
    }
    &__reset-button {
      background: #9e7b7b;
    }
  }

  &__action-button {
    &,
    &--red,
    &--green,
    &--yellow {
      font-family: 'Open Sans', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      user-select: none;
      padding: 0.8em;
      font-size: 0.8em;
      min-width: 35px;
      min-height: 35px;
      color: white;
      transition: background $default-transition-duration;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      text-decoration: none;

      &:disabled {
        cursor: default;
        border: 1px solid darken($action-button-light-gray-color, 20);
        color: darken($action-button-light-gray-color, 20);
        background: lighten($action-button-light-gray-color, 7);
      }
    }

    & {
      background: $action-button-light-gray-color;
      color: $action-button-gray-color;

      &:hover:not(:disabled) {
        background: darken($action-button-light-gray-color, 15);
      }
    }
    &--green {
      background: $action-button-green-color;

      &:hover:not(:disabled) {
        background: darken($action-button-green-color, 15);
      }
    }
    &--red {
      background: $action-button-red-color;

      &:hover:not(:disabled) {
        background: darken($action-button-red-color, 15);
      }
    }
    &--yellow {
      background: $action-button-yellow-color;

      &:hover:not(:disabled) {
        background: darken($action-button-yellow-color, 15);
      }
    }
  }
}
