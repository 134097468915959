@import '../../../color_variables.scss';

$gray-color: #bac3d0;
$alert-color: #f0ad4e;

.financial-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__date-text {
    background: $gray-color;

    &,
    &--past,
    &--alert,
    &--completed {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--past {
      background: $site-red-color;
    }

    &--alert {
      background: $alert-color;
    }

    &--completed {
      background: $default-green-color;
    }
  }

  &__type-text {
    background: $default-green-color;
    
    &,
    &--expense {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--expense {
      background: $site-red-color;
    }
  }

  &__classification-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
