@import '../../../size_variables.scss';
@import '../../../color_variables.scss';
@import '../../../constants.scss';

$default-text-color: #333;
$date-text-color: #2c3e50;
$default-black-color: #3a3839;

.student-access-control {
  &__device-selector-header {
    box-shadow: none;
    margin: 0 0 0.5em;
  }

  &__device-selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0.8em;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__device-button {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: #c5c5c5;
    color: #737373;
    border: 0 solid #a0a0a0;
    border-width: 2px 0;

    &,
    &:disabled {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.6em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-weight: bold;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &:disabled {
      cursor: default;
      background: #f1f1f1;
      color: #2c3e50;
      border: 0 solid #2c3e50;
      border-width: 2px 0;
    }
  }

  &__alert-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    font-weight: bold;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
      padding: 0.5em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }

  &__action-buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    margin: 0 0 0.8em;
  }

  &__add-button,
  &__reconfig-button,
  &__remove-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    color: white;
    flex: 1;

    &:disabled {
      cursor: default;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }

    &:not(:last-child) {
      margin: 0 0.4em 0 0;
    }
  }

  &__add-button {
    background: $default-green-color;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: #949494;
    }
  }

  &__reconfig-button {
    background: #3598dc;

    &:hover:not(:disabled) {
      background: darken(#3598dc, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: #949494;
    }
  }

  &__remove-button {
    background: #e08283;

    &:hover:not(:disabled) {
      background: darken(#e08283, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: #949494;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__message {
      font-size: 0.9em;
      text-align: justify;
      margin: 0 0 0.65em;
      color: #777777;
      background: #e7e7e7;
      padding: 0.4em 0.8em;
      border: 1px solid #b7b7b7;
      border-radius: 3px;
      font-weight: bold;
    }
  }

  &__horizontal-rule {
    border: 0 solid #3a3839;
    border-width: 2px 0 0;

    &,
    &--light {
      width: 100%;
      margin: 0.5em 0;

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        border-width: 1px 0 0;
      }
    }

    &--light {
      border: 0 solid #eee;
      border-width: 1px 0 0;
    }
  }

  &__user-picture {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &__img {
      max-width: 100%;
    }
  }

  &__access-status-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.9em;
    }
  }

  &__access-status {
    &,
    &--red,
    &--green {
      border-radius: 3px;
      color: white;
      font-size: 1.1em;
      padding: 0.4em 0.6em;
      text-align: center;
      background: #8f8f8f;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &--red {
      background: $site-red-color;
    }

    &--green {
      background: $default-green-color;
    }

    &__toggle-button {
      margin: 0 0 0 0.4em !important;

      &__icon {
        margin: 0 0.4em 0 0;
      }
    }
  }

  &__status-buttons-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  &__popup-window-content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 0 1em;
  }

  &__input {
    width: 100%;
  }
}
