@import '../../../color_variables.scss';
@import '../../../size_variables.scss';


.customer-feedback-target-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
    white-space: pre-wrap;
  }
}
