@import '../../size_variables.scss';
@import '../../constants.scss';
@import '../../color_variables.scss';

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.personal-training-performance-report {
  &__wrapper {
    @extend %default-side-margin;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: stretch;
    max-width: 70em;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__graph {
    overflow: hidden;
    min-width: 500px;
  }
}
