@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;

.role-experience-level-data {
  max-width: 70em;

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__salary-map {
    margin: 0.6em 0 0;

    &__title {
      font-size: 0.9em;
      color: $default-text-color;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.81em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.765em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.72em;
      }
    }

    &__green-header,
    &__yellow-header,
    &__red-header,
    &__white-header {
      & .model-table__table__header-label {
        flex: 1;
        justify-content: center;
      }
    }

    &__green-header {
      background: #78bb67;
    }
    &__yellow-header {
      background: #edf570;
    }
    &__red-header {
      background: #ea6767;
    }

    &__green-cell,
    &__yellow-cell,
    &__red-cell,
    &__white-cell {
      padding: 0.2em;
    }

    &__green-cell .default-input__input-prefix,
    &__green-cell .default-input__input {
      border-color: #78bb67;
    }
    &__yellow-cell .default-input__input-prefix,
    &__yellow-cell .default-input__input {
      border-color: #edf570;
    }
    &__red-cell .default-input__input-prefix,
    &__red-cell .default-input__input {
      border-color: #ea6767;
    }

    &__green-cell .default-input__input-prefix {
      background: #78bb679c;
    }
    &__yellow-cell .default-input__input-prefix {
      background: #edf5709c;
    }
    &__red-cell .default-input__input-prefix {
      background: #ea67679c;
    }

    &__input-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }

    & .default-input__input {
      width: 6em;
    }

    & .model-table__table-wrapper {
      margin-top: 0.2em;
    }

    &__input {
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1em;
      }
    }
  }
}
