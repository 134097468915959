@import '../size_variables.scss';

.warning-message {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background: #fbe1e3;
  overflow: hidden;

  &__message {
    color: #e73d4a;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 15px;
    text-align: justify;

    @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.9em;
      padding: 6px 10px;
    }

    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.82em;
      padding: 5px 8px;
    }
  }

  &__control-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin: 0 15px 0 0;

    @media (max-width: $small-width-breakpoint) {
      margin: 0 10px 0 0;
    }

    @media (max-width: $tiny-width-breakpoint) {
      margin: 0 5px 0 0;
    }
  }

  &__close-button {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: none;
    color: #c9b4b6;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    min-width: 35px;
    min-height: 35px;
    font-size: 1em;

    &:hover {
      color: black;
    }
  }
}
