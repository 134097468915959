@import '../../size_variables.scss';
@import '../../color_variables.scss';

$default-light-blue: #3598dc;
$default-gray: #e1e5ec;
$default-dark-gray: #656565;
$sub-section-background: #f7f7f7;

$default-dark-blue-color: #364150;
$profile-border-color: #c3c8ce;

$scale-reference-border-color: #c2cad8;

$data-column-background: white; // #f7f7f7

$indicator-background-color: #f7f7f7;

$text-color: #333;

$data-color-d: #f37778;
$data-color-i: #fcd602;
$data-color-s: #50bc84;
$data-color-c: #35ade3;

$scale-reference-background-1: #a50026;
$scale-reference-background-2: #d73027;
$scale-reference-background-3: #f46d43;
$scale-reference-background-4: #fdae61;
$scale-reference-background-5: #fee08b;
$scale-reference-background-6: #d9ef8b;
$scale-reference-background-7: #a6d96a;
$scale-reference-background-8: #66bd63;
$scale-reference-background-9: #1a9850;
$scale-reference-background-10: #006837;


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@mixin background($color, $percentage) {
  background: linear-gradient(to left, darken($color, 12%), lighten($color, $percentage));
}


.physical-evaluation-data {
  max-width: 70em;

  &__subsection {
    margin-top: 1em;
  }

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button,
  &__cancel-button,
  &__sync-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__save-button {
    background: $default-light-blue;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }
  }

  &__cancel-button {
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }
  }

  &__sync-button {
    margin: 0;
    background: #bdbdbd;
    color: #6f6f6f;

    @media (max-width: $very-small-width-breakpoint) {
      align-self: stretch;
    }

    @media (max-width: $tiny-width-breakpoint) {
      flex: 1;
    }

    &:hover:not(:disabled) {
      background: darken(#bdbdbd, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__reference-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 0 0.5em;
  }

  &__scale-reference {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
  }

  &__scale-item,
  &__scale-item--1,
  &__scale-item--5,
  &__scale-item--7,
  &__scale-item--10 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.2em 0.6em;
    font-size: 0.95em;

    &:first-of-type {
      border-radius: 3px 0 0 3px;
    }

    &:last-of-type {
      border-radius: 0 3px 3px 0;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
      padding: 0.2em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
      padding: 0.2em 0;
    }

    @media (max-width: $tiny-width-breakpoint) {
      padding: 0.2em 1.3em;

      &:first-of-type {
        border-radius: 3px 0 0 0;
      }

      &:nth-child(2) {
        border-radius: 0 3px 0 0;
      }

      &:nth-child(3) {
        border-radius: 0 0 0 3px;
      }

      &:last-of-type {
        border-radius: 0 0 3px 0;
      }
    }

    &__label {
      font-weight: bold;
    }

    &__label,
    &__value {
      width: 6em;
      text-align: center;
    }
  }

  &__scale-item--1 {
    background: $scale-reference-background-1;
    color: white;
  }
  &__scale-item--5 {
    background: $scale-reference-background-5;
  }
  &__scale-item--7 {
    background: $scale-reference-background-7;
  }
  &__scale-item--10 {
    background: $scale-reference-background-10;
    color: white;
  }

  &__generic-note {
    margin: 1em 0 0.5em;
    padding: 0.4em;
    background: #e4e8ec;
    border: 1px solid #adb4bd;
    border-radius: 3px;

    &__text {
      font-size: 0.9em;
      text-align: justify;
      color: #65696f;
    }
  }

  &__action-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0.3em;

    @media (max-width: $tiny-width-breakpoint) {
      justify-content: stretch;
    }
  }

  &__loading-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    will-change: transform;
    animation: 1.5s infinite linear spin;
    font-size: 1.2em;
  }

  &__profile {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $profile-border-color;
      border-top-width: 0;
    }

    &__buttons-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 1em 0 0;
    }

    &__save-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      border-radius: 3px;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      background: $default-green-color;
      color: white;

      &:hover:not(:disabled) {
        background: darken($default-green-color, 15);
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }

      &__icon {
        margin: 0 0.5em 0 0;

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0;
        }
      }

      &:not(:last-of-type) {
        margin: 0 0.5em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 0.4em;
        }
      }
    }
  }

  &__profile {
    &__disc {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      padding: 0;
      margin: 0 0 0.7em;
      align-self: flex-start;
      background: $data-column-background;
      border-radius: 3px;
      color: $text-color;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        align-self: stretch;
      }

      &__label {
        font-size: 1.2em;
        margin: 0 0.4em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          font-weight: bold;
          flex: 1;
        }
      }

      &__text {
        &--d,
        &--i,
        &--s,
        &--c {
          font-size: 1.1em;
          font-weight: bold;
          color: $text-color;
          padding: 0.4em;
          border-radius: 3px;

          @media (max-width: $very-small-width-breakpoint) {
            padding: 0.4em 0.9em;
          }

          & + & {
            margin: 0 0 0 0.2em;
          }
        }

        &--d {
          @include background($data-color-d, 12%);
        }

        &--i {
          @include background($data-color-i, 12%);
        }

        &--s {
          @include background($data-color-s, 12%);
        }

        &--c {
          @include background($data-color-c, 12%);
        }
      }
    }
  }

  &__question {
    &__label {
      font-weight: bold;
    }
  }

  &__indicators-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0.8em 0 0;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0.8em 0;
    }
  }

  &__indicators-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      order: 2;
    }
  }

  &__indicator {
    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0;
      border-radius: 3px;
      color: $default-text-color;
      background: $indicator-background-color;
      margin: 0 0.5em 0.5em 0;
      border: 1px solid $site-background-color;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
        justify-content: space-between;
        margin: 0;
        font-size: 0.9em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &--disabled {
      border: 1px solid #ababab;
    }

    &--clickable {
      cursor: pointer;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      color: #505050;
      margin: 0 0.2em 0 0;
      padding: 0.3em;
      // max-width: 9em;
      text-transform: uppercase;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
        max-width: none;
      }
    }

    &__value {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 0.6em;
      background: $site-background-color;
      color: white;
      white-space: nowrap;

      @media (max-width: $smaller-width-breakpoint) {
        width: 8em;
      }

      &__number {
        font-size: 1.3em;
      }

      &__unit {
        font-size: 0.8em;
      }
    }

    &--disabled > &__value {
      background: #ababab;
      color: #565656;
    }

    & + & {
      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__input-label {
    &--red,
    &--yellow,
    &--green {
      font-weight: bold;
      border-radius: 3px;
      padding: 0.1em 0.3em;
    }

    &--red {
      background: #f37778;
    }
    &--yellow {
      background: #fcd602;
    }
    &--green {
      background: #50bc84;
    }
  }
}
