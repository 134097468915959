@import '../../color_variables.scss';
@import '../../size_variables.scss';

$default-dark-blue-color: #2c3e50;

.work-schedule-list {
  max-width: 75em;

  &__list-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 0 1.2em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__user-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__info-icon {
      margin: 0 0 0 0.4em;
    }
  }

  &__entry-info {
    white-space: pre-wrap;
    font-size: 0.82em;
  }

  &__schedule-grid-entry {
    &--selected {
      background-color: lighten($site-red-color, 3) !important;

      & .schedule-grid__interval__text {
        color: white !important;
      }
    }

    &--gray {
      background-color: #8b8b8b !important;

      & .schedule-grid__interval__text {
        color: white !important;
      }
    }

    &__popup-content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &__text {
        font-weight: bold;
      }
    }
  }

  &__overview-table {
    &__section-title {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    &__cell--highlight {
      background: transparentize(#ea4d5f, 0.2);
      color: white;
    }

    &__total-row {
      background: transparentize($default-dark-blue-color, 0.6);
    }
  }
}
