$default-transition-duration: 0.4s;

%default-scroll {
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2c3e50;
    border-radius: 0.4em;
  }
}
