@import '../../../size_variables.scss';
@import '../../../color_variables.scss';

$default-text-color: #333;
$alert-color: #f0ad4e;
$date-text-color: #2c3e50;
$default-black-color: #3a3839;

.support-ticket-list {
  &__status-text {
    &--red,
    &--gray,
    &--yellow {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      color: white;
    }

    &--red {
      background: $site-red-color;
    }
    &--gray {
      background: $default-gray-color;
    }
    &--yellow {
      background: $alert-color;
    }
    &--green {
      background: $default-green-color;
    }
  }

  &__action-icon {
    margin: 0 0.4em 0 0;
  }

  &__subject {
    &,
    &--red,
    &--green {
      min-width: 10em;
    }

    &--red {
      background: #f37778;
    }
    &--green {
      background: #50bc84;
    }
    &--gray {
      background: #adadad;
    }
    &--purple {
      background: #d786f9;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 !important;
    width: 100%;
    height: 90%;
    margin: 0 0.5em;
    align-self: center;

    @media (min-width: 1500px) {
      max-width: 90em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      align-self: stretch;

      &__close-button {
        border-radius: 0;
        border-top-right-radius: 7px;
        font-size: 2em;
        padding: 0.2em 0.5em;
        max-width: 2em;
        box-shadow: none;

        @media (max-width: $medium-less-width-breakpoint) {
          max-width: 1.6em;
        }
        @media (max-width: $small-width-breakpoint) {
          border-top-right-radius: 3px;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.8em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.7em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.5em;
        }
      }

      &__title {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        // font-family: 'Black Ops One', cursive;
        font-family: 'Black Ops One', fantasy;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: center;
        flex: 1;
        padding: 0.3em 0.3em 0.2em;
        min-width: 0;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }
        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.9em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.75em;
          flex-flow: column wrap;
          align-items: center;
          justify-content: center;
        }
        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.6em;
        }
        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.45em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.25em;
        }


        &--label {
          color: #707070;
          margin: 0 0.3em 0 0;
          font-size: 0.8em;

          @media (max-width: $small-width-breakpoint) {
            font-size: 0.65em;
            margin: 0;
          }
        }
      }
    }

    &__content-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        background: #ede4e4;
        border-radius: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #dbbbbb;
        border-radius: 0;
      }
    }

    &__loading-icon {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../../../images/preloader.gif), white;
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
    }
  }

  &__messages-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    background: #ede4e4;
    padding: 2.5em 0 1em;
  }

  &__message-wrapper {
    &,
    &--left,
    &--right {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0 3em;
      width: 100%;

      &:not(:first-child) {
        margin: 2em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          margin: 1.75em 0 0;
        }
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 0 1.5em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        padding: 0 0.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        padding: 0 0.6em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.4em;
      }
    }

    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }

  &__message {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 65%;

    @media (max-width: $small-width-breakpoint) {
      max-width: 80%;
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-width: 85%;
    }
    @media (max-width: $very-small-width-breakpoint) {
      max-width: 87%;
    }
    @media (max-width: $tiny-width-breakpoint) {
      max-width: 90%;
    }

    &__arrow {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    &__balloon {
      position: relative;
      background: white;
      box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
      padding: 0.4em;
      border-radius: 4px;
    }

    &__sent-text {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      top: 0;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7em;
      color: #a98282;
      transform: translateY(-100%);
      min-width: 8em;
      width: max-content;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.65em;
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: justify;
      white-space: pre-wrap;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.94em;
      }
    }
  }

  &__message-wrapper--left &__message__arrow {
    justify-content: flex-end;
    order: -1;
  }
  &__message-wrapper--right &__message__arrow {
    justify-content: flex-start;
    order: 2;
  }

  &__message-wrapper--left &__message__balloon {
    border-top-left-radius: 0;
    padding-left: 0.5em;
  }
  &__message-wrapper--right &__message__balloon {
    background: #f7bfaa;
    border-top-right-radius: 0;
    padding-right: 0.5em;
  }

  &__message-wrapper--left &__message__sent-text {
    text-align: left;
    left: 0.5em;
  }
  &__message-wrapper--right &__message__sent-text {
    text-align: right;
    right: 0.5em;
  }

  &__send-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    background: #e9edef;
    padding: 0.8em 1.2em;
    border-radius: 0 0 7px 7px;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 0 0 3px 3px;
    }
    @media (max-width: $smaller-width-breakpoint) {
      padding: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 0.6em 0.4em;
    }
    // @media (max-width: $very-small-width-breakpoint) {
    //   flex-flow: column nowrap;
    //   align-items: stretch;
    //   justify-content: center;
    // }

    &__input {
      flex: 1;

      & textarea {
        font-size: 1em;
        border: none;
        border-radius: 9px;
        padding: 0.6em 0.8em;
        color: $default-black-color;

        @media (max-width: $smaller-width-breakpoint) {
          border-radius: 5px;
        }
        @media (max-width: $very-small-width-breakpoint) {
          padding: 0.5em 0.6em;
          border-radius: 4px;
        }
        @media (max-width: $tiny-width-breakpoint) {
          padding: 0.5em 0.4em;
        }
      }
    }

    &__controls {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.6em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.5em;
      }
    }

    &__button {
      font-family: 'Iceland', cursive;
      width: 8em;

      @media (max-width: $medium-less-width-breakpoint) {
        width: 7em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        width: 6.5em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        width: 6.3em;
      }

      &,
      &--send {
        border-radius: 3px;
        font-size: 2em;
        padding: 0.2em 0.5em;
        box-shadow: none;

        &:first-child {
          margin: 0 0 0 6px;

          @media (max-width: $tiny-width-breakpoint) {
            margin: 0 0 0 4px;
          }
        }
      }

      &--send {
        width: 4.5em;
      }
    }
  }
}
