@import '../../../color_variables.scss';
@import '../../../size_variables.scss';
@import '../../../constants.scss';

$service-button-text-color: #737373;
$service-button-background-color: #c5c5c5;
$service-button-border-color: #a0a0a0;
$service-button-disabled-background-color: #f1f1f1;
$service-button-disabled-border-color: #2c3e50;
$service-button-disabled-text-color: #2c3e50;

$today-color: #2c3e50;

$alert-color: #f0ad4e;
$loading-color: #5f5f5f;
$light-text-color: #777;
$warning-color: #ea5460;
$default-black-color: #3a3839;


.appointment-schedule-list {
  max-width: 100em;

  &__list-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 0 1.2em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__selector {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0.2em 0.8em;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: transparent;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }

    &__header {
      box-shadow: none;
      margin: 0 0 0.5em;
    }

    &__button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      background: $service-button-background-color;
      color: $service-button-text-color;
      border: 0 solid $service-button-border-color;
      border-width: 2px 0;

      &,
      &:disabled {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        outline: none;
        font-weight: normal;
        user-select: none;
        padding: 0.6em 0.7em;
        transition: $default-transition-duration;
        font-size: 0.9em;
        white-space: nowrap;
        text-align: center;
        margin: 0;
        font-weight: bold;

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.8em;
        }

        &:not(:last-of-type) {
          margin: 0 0.6em 0 0;
        }
      }

      &:disabled {
        cursor: default;
        background: $service-button-disabled-background-color;
        color: $service-button-disabled-text-color;
        border: 0 solid $service-button-disabled-border-color;
        border-width: 2px 0;
      }
    }
  }

  &__alert-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
      padding: 0.5em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }

  &__calendar {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    &__date-controls {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-around;

      @media (max-width: $smaller-width-breakpoint) {
        justify-content: space-between;
      }

      &__button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;
        background: #e7e7e7;
        color: #727272;
        width: 10em;

        @media (max-width: $small-width-breakpoint) {
          width: 5em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          width: 4em;
        }

        &:hover:not(:disabled) {
          background: darken(#e7e7e7, 15);
        }

        &:disabled {
          background: #dcdcdc;
          color: #949494;
          cursor: default;
        }
      }

      &__header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin: 0 0.5em;
        font-size: 1.1em;
        color: $default-text-color;
        text-align: center;
      }
    }

    &__hr {
      display: block;
      height: 2px;
      margin: 1em 0 1em;
      align-self: stretch;
      background: #c9c9c9;
    }

    &__main-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      position: relative;
      padding: 2.5em 0 0;
      // margin: 1.5em 0 0;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__day-entries-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
    }

    &__hour-division {
      border: 0 solid #c7c7c7;
      border-top-width: 1px;
      margin: 0 0 0 2.3em;

      &__value {
        position: relative;
        left: -3.1em;
        top: -0.8em;
        color: #979797;
        font-size: 0.7em;
        font-weight: bold;
      }

      &__wrapper {
        position: absolute;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        bottom: 0;
        left: 0;
      }
    }

    &__hour-division-spacer {
      display: block;
      width: 3em;
      min-width: 3em;
    }

    &__day {
      &,
      &--today {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        border: 1px solid transparent;
        border-left-color: gray;
      }

      &__header {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -1.3em;

        &__text {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          width: 3.2em;
          min-height: 3.2em;
          height: 3.2em;
          border-radius: 50%;
        }

        &__day-name {
          text-transform: uppercase;
          font-weight: bold;
          color: #979797;
          font-size: 0.7em;
        }

        &__day-number {
          color: #838383;
          font-weight: bold;
          font-size: 1.1em;
        }
      }

      &--today &__header__text {
        background: $today-color;
      }
      &--today &__header__day-name,
      &--today &__header__day-number {
        color: white;
      }

      &__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        min-width: 5em;
      }

      &--today &__content {
        background: transparentize($today-color, 0.85);
      }

      &__schedule {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0.8em 0 0;
        z-index: 0;
        border-radius: 4px;
        box-shadow: 1px 1px 7px #000000b8;
        padding: 0 0.2em;

        &__time-slot-container {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          width: 10em;

          @media (max-width: $small-width-breakpoint) {
            width: 11em;
          }
        }

        &__time-slot {
          &,
          &--available {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: stretch;
            padding: 0.2em 0 0;
            flex-shrink: 0;
            overflow: hidden;

            &:first-child {
              padding-top: 0.4em;
            }
            &:last-child {
              padding-bottom: 0.2em;
            }
          }

          &__wrapper {
            position: relative;
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            border: 1px solid white;
          }

          &__label {
            position: absolute;
            margin: 0;
            top: 0;
            left: 0.2em;
            font-weight: bold;
            color: #4e4e4e;
            font-size: 0.7em;
          }

          &__appointment {
            background: white;

            &,
            &--attended {
              display: flex;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-end;
              height: 100%;
              width: 100%;
              padding: 1em 0 0 0.1em;
            }

            &--attended {
              background: #cbcbcb;
            }

            &__name,
            &__email {
              color: $default-text-color;
            }

            &__name {
              font-size: 0.7em;
              font-weight: bold;
            }

            &__email {
              font-size: 0.6em;

              @media (max-width: $small-width-breakpoint) {
                font-size: 0.7em;
              }
            }

            &__cancel-button {
              position: absolute;
              margin: 0;
              top: 0;
              right: 0;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: center;
              font-family: 'Open Sans', sans-serif;
              outline: none;
              cursor: pointer;
              -webkit-tap-highlight-color:  transparent;
              border: 1px solid $site-red-color;
              border-radius: 5px;
              user-select: none;
              font-size: 1em;
              text-decoration: none;
              min-width: 25px;
              min-height: 25px;
              color: $site-red-color;
              background: white;

              &:hover:not(:disabled) {
                color: white;
                background: $site-red-color;
              }
            }
          }

          &__add-button {
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            font-family: 'Open Sans', sans-serif;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color:  transparent;
            border: none;
            user-select: none;
            font-size: 0.9em;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            min-width: 35px;
            min-height: 35px;
            // padding: 0.6em 0.8em;
            // background: linear-gradient(50deg, #858585, #f9f9f9, #858585);
            background: #ffffff87;
            color: #727272;
            width: 100%;

            &:hover:not(:disabled) {
              background: #ffffffd9;
            }

            &:disabled {
              background: #dcdcdc;
              color: #949494;
              cursor: default;
            }
          }
        }

        &__action-container {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          margin: 0.4em 0.2em 0.2em 0;
        }

        &__action-button {
          &,
          &--blue,
          &--red {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-family: 'Open Sans', sans-serif;
            // padding: 0.6em 0.8em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color:  transparent;
            border: 1px solid white;
            border-radius: 5px;
            user-select: none;
            font-size: 0.95em;
            text-decoration: none;
            min-width: 32px;
            min-height: 32px;
            color: #727272;
            background: #e7e7e7;

            &:hover:not(:disabled) {
              background: darken(#e7e7e7, 15);
            }

            &:disabled {
              background: #dcdcdc;
              color: #949494;
              cursor: default;
            }

            &:not(:last-child) {
              margin: 0 0 0.5em;
            }
          }

          &--blue {
            background: #3598dc;
            color: white;

            &:hover:not(:disabled) {
              background: darken(#3598dc, 15);
            }
          }
          &--red {
            background: #e08283;
            color: white;

            &:hover:not(:disabled) {
              background: darken(#e08283, 15);
            }
          }

          &__icon {
            pointer-events: none;
          }
        }

        &__info-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
        }

        &__info {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;

          &__title {
            font-size: 1em;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 0 0.2em;
          }

          &__entry {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            &__label {
              font-weight: bold;
              width: 6.5em;
            }
          }
        }
      }
    }

    &__alert-text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1em;
      text-align: center;
      background: #f5f5f5;
      color: $site-red-color;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0.5em 0;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        text-align: justify;
        font-size: 0.85em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.7em;
        padding: 0.5em;
      }

      &__text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        flex: 1;
      }

      &__icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        background: $site-red-color;
        color: white;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        margin: 0 0.5em 0 0;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__activate-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    background: $default-green-color;
    color: white;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__send-emails-window {
    border-radius: 0;
    box-shadow: none;
    padding: 1em 2em 1.2em;
    margin: 4em 0.4em 0.4em;

    @media (max-width: $smaller-width-breakpoint) {
      padding: 0.8em 0.5em;
    }

    &__header {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      padding:  0 0.4em 0.4em;

      @media (max-height: $tiny-height-breakpoint) {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }

      &__title--pulsing,
      &__title {
        margin: 0.5em 0 0;
        color: $default-text-color;
        text-align: center;
        font-weight: lighter;

        @media (max-height: $tiny-height-breakpoint) {
          margin: 0 0 0 0.5em;
        }
      }

      &__title--pulsing {
        animation: PULSE_ANIMATION 1s ease-in-out infinite alternate;
      }
    }

    &__alert-icon {
      font-size: 5em;
      color: $alert-color;

      @media (max-width: $small-width-breakpoint) {
        font-size: 4em;
      }

      @media (max-height: $tiny-height-breakpoint) {
        font-size: 3em;
      }
    }

    &__loading-icon {
      font-size: 3.5em;
      color: $loading-color;
      animation: SPIN_ANIMATION 3s linear infinite;

      @media (max-width: $small-width-breakpoint) {
        font-size: 3em;
      }
    }

    &__description {
      color: $light-text-color;
      text-align: center;
      font-weight: lighter;

      @media (max-width: $medium-less-width-breakpoint) {
        text-align: justify;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      margin: 1.5em 0 0;
    }

    &__cancel-button,
    &__confirm-button {
      font-family: 'Open Sans', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      user-select: none;
      padding: 0.6em 1em;
      font-size: 0.9em;
      min-width: 35px;
      min-height: 35px;
      color: white;
      transition: background $default-transition-duration;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      text-decoration: none;
    }

    &__cancel-button {
      border: 1px solid #ccc;
      color: $default-text-color;
      background: none;

      &:hover {
        background: #e6e6e6;
      }
    }

    &__confirm-button {
      border: 1px solid $warning-color;
      color: white;
      background: $warning-color;
      margin: 0 0 0 0.6em;

      &:hover {
        background: darken($warning-color, 15);
      }
    }

    &__horizontal-rule {
      width: 100%;
      border: 0 solid #eee;
      border-width: 1px 0 0;
      margin: 0.6em 0;
    }

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 50vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 45vh;
      }

      @media (max-height: $medium-height-breakpoint) {
        max-height: 35vh;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        max-height: 29vh;
      }

      &::-webkit-scrollbar {
        width: 1.8em;
        height: 1.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__service-plan-filter {
      &__instructions {
        font-size: 0.92em;
        text-transform: uppercase;
        color: #333;
        font-weight: bold;
        margin: 0 0 1em;
        text-align: justify;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.9em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.85em;
        }
      }

      &__options-container {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0.6em 0 0;

        @media (max-width: $smaller-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: stretch;
        }
      }

      &__option {
        background: #2c3e50;
        color: white;

        &,
        &--disabled {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0.3em 0.6em 0.3em 0;
          border-radius: 3px;
          text-align: center;
          // white-space: nowrap;
          margin: 0 0.5em 0.4em 0;
          font-size: 0.95em;

          @media (max-width: $smaller-width-breakpoint) {
            flex: 1;
          }
        }

        &--disabled {
          background: #dcdcdc;
          color: $default-gray-color;
        }

        &__text {

        }

        &__check-button {
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          outline: none;
          background: none;
          min-width: 35px;
          min-height: 30px;
          color: white;
          border: 1px solid white;
          border-radius: 3px;
          margin: 0 0.5em 0 0.2em;
          font-size: 1.2em;
        }

        &--disabled &__check-button {
          color: $default-gray-color;
          border-color: $default-gray-color;
          color: $default-gray-color;
        }
      }
    }
  }
}
