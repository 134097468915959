@import '../../size_variables.scss';
@import '../../color_variables.scss';
@import '../../constants.scss';

$text-color: #333;
$activate-period-border-color: #c3c8ce;

$default-red-color: #e08283;
$default-dark-blue-color: #364150;
$indicator-background-color: #f7f7f7;

$profile-border-color: #c3c8ce;

$data-column-background: white; // #f7f7f7

$data-color-d: #f37778;
$data-color-i: #fcd602;
$data-color-s: #50bc84;
$data-color-c: #35ade3;

@mixin background($color, $percentage) {
  background: linear-gradient(to left, darken($color, 12%), lighten($color, $percentage));
}

.personal-training {
  &__name-cell {
    &--d {
      background: $data-color-d;
    }
    &--i {
      background: $data-color-i;
    }
    &--s {
      background: $data-color-s;
    }
    &--c {
      background: $data-color-c;
    }
  }

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__capacity-text {
    color: white;

    &,
    &--gray {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      background: black;
    }

    &--gray {
      color: #929292;
    }
  }

  &__activate-period {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $activate-period-border-color;
      border-top-width: 0;
    }

    & .model-table__table__row-cell,
    & .model-table__table__row-cell--highlighted {
      font-size: 0.8em;
    }
  }

  &__note-to-students {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      margin: 1em 0 0;
      background: #a2a2a2;
      border: 1px solid #a2a2a2;
      color: #5d5d5d;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #5d5d5d;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.4em;
      border: 1px solid #a2a2a2;
      border-top-width: 0;
      background: white;
    }
  }

  &__activate-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    background: $default-green-color;
    color: white;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0;
      }
    }
  }

  &__active-period-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
    border: 1px solid $activate-period-border-color;
    border-radius: 3px;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 0;
    }
  }

  &__active-period {
    &__header-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__header {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 0 solid $activate-period-border-color;
      border-width: 0 0 1px 0;
      padding: 0.4em 0.8em;
      background: #eaeaea;

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__controls-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $very-small-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
        }
      }

      &__input-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0.5em 0 0 0.8em;

        @media (max-width: $tiny-width-breakpoint) {
          margin: 0.5em 0 0 0;
        }
      }

      &__input-container &__end-date-input {
        margin: 0.4em 0 0;
      }

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.3em;
        color: $default-dark-blue-color;
        font-weight: bold;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.25em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.15em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.05em;
        }

        &__link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          user-select: none;
          min-width: 35px;
          min-height: 35px;
          transition: $default-transition-duration;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          text-decoration: none;
          color: $default-dark-blue-color;
          font-size: 0.8em;
          border: 1px solid $default-dark-blue-color;
          border-radius: 3px;
          background: transparent;
          margin: 0 0 0 0.4em;

          &:hover {
            color: white;
            background: $default-dark-blue-color;
          }
        }
      }

      &__deactivate-button,
      &__save-button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        border-radius: 3px;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #dcdcdc;
          color: #949494;
          cursor: default;
        }

        &__icon {
          margin: 0 0.5em 0 0;

          @media (max-width: $smaller-width-breakpoint) {
            margin: 0;
          }
        }

        &:not(:last-of-type) {
          margin: 0 0.5em 0 0;

          @media (max-width: $very-small-width-breakpoint) {
            margin: 0 0 0.4em;
          }
        }
      }

      &__save-button {
        background: $default-green-color;
        color: white;

        &:hover:not(:disabled) {
          background: darken($default-green-color, 15);
        }
      }

      &__deactivate-button {
        background: $default-red-color;
        color: white;

        &:hover:not(:disabled) {
          background: darken($default-red-color, 15);
        }
      }

      &__schedule-configuration {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        &__actions {
          align-self: stretch;
          display: flex;
          flex-flow: row wrap;
          align-items: stretch;
          justify-content: flex-end;
        }
      }

      &__note {
        text-align: justify;
        white-space: pre-wrap;
        color: $text-color;
        padding: 0.2em 0 0.4em;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.6em;
          height: 0.6em;

          @media (max-width: $medium-less-width-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #5d5d5d;
          border-radius: 0.4em;
        }
      }

      &__initial-date-input label,
      &__end-date-input label {
        min-width: 7.4em;
      }
    }

    &__trainings-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      list-style: none;
      padding: 0.5em 0 0;
    }

    &__ruler {
      display: block;
      width: 100%;
      height: 1px;
      background: $activate-period-border-color;
      align-self: center;
      margin: 0.5em 0;

      // &:last-of-type {
      //   display: none;
      // }
    }

    &__training {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.2em 0.6em 0.5em;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }

      &__text-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0.5em 0 0;
      }

      &__name {
        font-size: 1.2em;
        color: $default-dark-blue-color;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.1em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.95em;
        }
      }

      &__note {
        font-size: 0.85em;
        color: $site-red-color;
        text-align: justify;
        white-space: pre-wrap;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.8em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.7em;
        }
      }

      &__start-button-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 0.5em 0 0.6em;
      }

      &__start-button {
        font-family: 'Open Sans', sans-serif;
        padding: 0.6em 0.8em;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        background: #929292;
        color: white;
        border-radius: 3px;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;

        &:disabled {
          background: #dcdcdc;
          color: #949494;
          cursor: default;
        }

        &:hover:not(:disabled) {
          background: darken(#929292, 15);
        }

        &__icon {
          margin: 0 0.5em 0 0;

          @media (max-width: $smaller-width-breakpoint) {
            margin: 0;
          }
        }
      }
    }

    &__details {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.4em 0.6em;

      &__indicators-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $smaller-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
        }
      }

      &__indicators-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $smaller-width-breakpoint) {
          flex-flow: column nowrap;
          order: 2;
        }
      }

      &__progress-indicator {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        width: 8.5em;
        height: 8.5em;
        background: center / contain no-repeat url(../../images/progress_background.svg);
        flex-shrink: 0;

        @media (max-width: $medium-width-breakpoint) {
          width: 8em;
          height: 8em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          width: 7em;
          height: 7em;
        }

        @media (max-width: $smaller-width-breakpoint) {
          order: 1;
          align-self: center;
          margin: 0 0 0.5em;
        }

        &__text {
          font-family: 'Orbitron', sans-serif;
          font-weight: bold;
          font-size: 1.5em;
          color: $default-text-color;

          @media (max-width: $medium-width-breakpoint) {
            font-size: 1.3em;
          }

          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 1.1em;
          }
        }
      }

      &__indicator {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.2em 0.6em;
        border-radius: 3px;
        color: $default-text-color;
        background: $indicator-background-color;
        margin: 0 0.5em 0 0;

        @media (max-width: $smaller-width-breakpoint) {
          flex: 1;
          justify-content: space-between;
          margin: 0;
        }

        &__label {
          font-size: 1.2em;
          margin: 0 0.5em 0 0;
        }

        &__value {
          padding: 0.4em 0.6em;
          border-radius: 3px;
          background: $site-background-color;
          color: white;
          white-space: nowrap;
        }

        & + & {
          @media (max-width: $smaller-width-breakpoint) {
            margin: 0.5em 0 0;
          }
        }
      }

      &__graph {
        margin: 1em 0 0;
      }
    }
  }

  &__alert-text-container {
    background: #f5f5f5;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  &__alert-text {
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5em 0;
    margin: 0 0 0.5em;

    &__icon {
      margin: 0 0.4em 0 0;
    }

    &__button {
      align-self: stretch;
      background: $site-red-color;
      margin: 0 0.5em 0.5em !important;
      border-radius: 3px;
    }
  }

  &__alert-text-container > &__alert-text {
    margin: 0;
  }

  &__actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  &__list-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 0 1.2em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__filters {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 0 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
    margin: 0 0 0.8em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.4em;
    }

    &__header {
      background: #d4d4d4;
      margin: 0 0 0.5em;
      padding: 0.2em 0 0.2em 0.5em;

      &__text {
        font-weight: bold;
        text-transform: uppercase;
        color: #7b7b7b;
        font-size: 0.8em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 0.5em 0;
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }

  &__profile,
  &__physicalActivity,
  &__coach-responsible {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid $profile-border-color;
      border-top-width: 0;
    }

    &__buttons-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 1em 0 0;
    }

    &__save-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      border-radius: 3px;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      background: $default-green-color;
      color: white;

      &:hover:not(:disabled) {
        background: darken($default-green-color, 15);
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }

      &__icon {
        margin: 0 0.5em 0 0;

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0;
        }
      }

      &:not(:last-of-type) {
        margin: 0 0.5em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 0.4em;
        }
      }
    }

    &__disc {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      padding: 0;
      margin: 0 0 0.7em;
      align-self: flex-start;
      background: $data-column-background;
      border-radius: 3px;
      color: $text-color;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        align-self: stretch;
      }

      &__label {
        font-size: 1.2em;
        margin: 0 0.4em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          font-weight: bold;
          flex: 1;
        }
      }

      &__text {
        &--d,
        &--i,
        &--s,
        &--c {
          font-size: 1.1em;
          font-weight: bold;
          color: $text-color;
          padding: 0.4em;
          border-radius: 3px;

          @media (max-width: $very-small-width-breakpoint) {
            padding: 0.4em 0.9em;
          }

          & + & {
            margin: 0 0 0 0.2em;
          }
        }

        &--d {
          @include background($data-color-d, 12%);
        }

        &--i {
          @include background($data-color-i, 12%);
        }

        &--s {
          @include background($data-color-s, 12%);
        }

        &--c {
          @include background($data-color-c, 12%);
        }
      }
    }
  }

  &__question {
    &__label {
      font-weight: bold;
    }
  }

  &__tracking-status-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.9em;
    }
  }

  &__tracking-status {
    &,
    &--red,
    &--green {
      border-radius: 3px;
      color: white;
      font-size: 1.1em;
      padding: 0.4em;
      text-align: center;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &--red {
      background: $site-red-color;
    }

    &--green {
      background: $default-green-color;
    }

    &__toggle-button {
      margin: 0 0 0 0.4em !important;
    }
  }

  &__indicators-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 0 0.8em;
    }
  }

  &__indicators-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      order: 2;
    }
  }

  &__indicator {
    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0;
      border-radius: 3px;
      color: $default-text-color;
      background: $indicator-background-color;
      margin: 0 0.5em 0.5em 0;
      border: 1px solid $site-background-color;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
        justify-content: space-between;
        margin: 0;
        font-size: 0.9em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &--disabled {
      border: 1px solid #ababab;
    }

    &--clickable {
      cursor: pointer;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      color: #505050;
      margin: 0 0.2em 0 0;
      padding: 0.3em;
      // max-width: 9em;
      text-transform: uppercase;

      @media (max-width: $smaller-width-breakpoint) {
        flex: 1;
        max-width: none;
      }
    }

    &__value {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 0.6em;
      background: $site-background-color;
      color: white;
      white-space: nowrap;

      @media (max-width: $smaller-width-breakpoint) {
        width: 8em;
      }

      &__number {
        font-size: 1.3em;
      }

      &__unit {
        font-size: 0.8em;
      }
    }

    &--disabled > &__value {
      background: #ababab;
      color: #565656;
    }

    & + & {
      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__input-label {
    &--red,
    &--yellow,
    &--green {
      font-weight: bold;
      border-radius: 3px;
      padding: 0.1em 0.3em;
    }

    &--red {
      background: #f37778;
    }
    &--yellow {
      background: #fcd602;
    }
    &--green {
      background: #50bc84;
    }
  }

  &__schedule-configuration {
    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      overflow-y: hidden;
      padding: 0;
      max-width: 100%;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 0.5em;
    border-radius: 0;
    white-space: nowrap;
    margin: 0;
    font-family: 'Montserrat', sans-serif;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 0 1.5em;

      &__weekday {
        &,
        &--highlighted {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          background: #2c3e50;
          border: 0 solid white;
          border-left-width: 1px;
          padding: 0.1em 0.3em;
          width: 7em;

          &:first-child {
            border-radius: 3px 0 0 0;
            border-left-width: 0;
          }

          &:last-child {
            border-radius: 0 3px 0 0;
          }
        }

        &--highlighted {
          background: $site-red-color;
        }

        &__text {
          font-size: 0.94em;
          text-transform: uppercase;
          font-weight: bold;
          color: white;
        }
      }
    }

    &__week-list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__week {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;

      &__header {
        &,
        &--highlighted {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          background: #2c3e50;
          border: 0 solid white;
          border-top-width: 1px;
          font-size: 1em;
          padding: 0.4em 0;
          width: 1.5em;
        }

        &--highlighted {
          background: $site-red-color;
        }

        &__text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-size: 0.85em;
          text-transform: uppercase;
          font-weight: bold;
          color: white;
          writing-mode: vertical-lr;
          text-orientation: sideways;
        }

        &__action-button {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-family: 'Open Sans', sans-serif;
          outline: none;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          border: 1px solid white;
          border-radius: 3px;
          user-select: none;
          font-size: 0.9em;
          text-decoration: none;
          min-height: 32px;
          background: #e08283;
          color: white;
          pointer-events: auto;
          margin: 0.4em 0 0;
          width: 90%;

          &:hover:not(:disabled) {
            background: darken(#e7e7e7, 15);
          }

          &:disabled {
            background: #dcdcdc;
            color: #949494;
            cursor: default;
          }

          &:hover:not(:disabled) {
            background: darken(#e08283, 15);
          }
        }
      }

      &__content {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1;
        background: #cdcdcd;
        border: 0 solid $default-gray-color;
        border-top-width: 1px;
        border-radius: 0;
        overflow: hidden;
      }

      &:first-child &__content {
        border-top-width: 0;
      }

      &:last-child &__content {
        border-radius: 0 0 3px 0;
        border-bottom-width: 1px;
      }

      &__weekday {
        &__wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: stretch;
          width: 100%;
          padding: 0.2em;
          position: relative;
          pointer-events: none;
        }

        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: stretch;
        width: 7em;
        border: 0 solid $default-gray-color;
        border-left-width: 1px;
        position: relative;

        &:first-child {
          border-left-width: 0;
        }
        &:last-child {
          border-right-width: 1px;
        }

        &__text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          flex: 1;
          text-align: center;
          color: $default-text-color;
          font-weight: bold;
          width: 100%;
          font-size: 0.85em;
          white-space: pre-wrap;
          user-select: text;
          cursor: text;
        }

        &__controls {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          margin: 0 0 0.4em;
        }

        &__action-button {
          &,
          &--blue,
          &--red {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-family: 'Open Sans', sans-serif;
            // padding: 0.6em 0.8em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color:  transparent;
            border: 1px solid white;
            border-radius: 5px;
            user-select: none;
            font-size: 0.95em;
            text-decoration: none;
            min-width: 32px;
            min-height: 32px;
            color: #727272;
            background: #e7e7e7;
            pointer-events: auto;

            &:hover:not(:disabled) {
              background: darken(#e7e7e7, 15);
            }

            &:disabled {
              background: #dcdcdc;
              color: #949494;
              cursor: default;
            }

            &:not(:last-child) {
              margin: 0 0.4em 0 0;
            }
          }

          &--blue {
            background: #3598dc;
            color: white;

            &:hover:not(:disabled) {
              background: darken(#3598dc, 15);
            }
          }
          &--red {
            background: #e08283;
            color: white;

            &:hover:not(:disabled) {
              background: darken(#e08283, 15);
            }
          }

          &__icon {
            pointer-events: none;
          }
        }

        &__grab-button {
          position: absolute;
          left: 0;
          top: 0;
          height: 30%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          outline: none;
          cursor: grab;
          -webkit-tap-highlight-color:  transparent;
          border: none;
          border-radius: 0 0 3px 0;
          user-select: none;
          font-size: 0.75em;
          text-decoration: none;
          color: #747474;
          background: #e7e7e7ad;
          width: 1.8em;
          pointer-events: auto;
        }
      }

      &:first-child &__header,
      &:first-child &__header--highlighted {
        border-radius: 3px 0 0 0;
        border-top-width: 0;
      }

      &:last-child  &__header,
      &:last-child  &__header--highlighted {
        border-radius: 0 0 0 3px;
      }
    }

    &__add-weekday-button,
    &__add-week-button {
      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: stretch;
        width: 100%;
      }

      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: 1px solid #959595;
      border-radius: 3px;
      user-select: none;
      font-size: 0.8em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      margin: 0;
      background: #ffffff8f;
      text-transform: uppercase;
      font-weight: bold;
      color: #727272;
      width: 100%;
      margin: 0.5em;
      white-space: normal;
      // overflow-wrap: break-word;

      &:hover:not(:disabled) {
        background: $default-green-color;
        color: white;
        border-color: white;
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__add-week-button__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__dropping-effect {
    &::after {
      content: "+";
      font-weight: bold;
      color: #6e6e6e;
      font-size: 2.5em;
      position: absolute;
      top: 3%;
      left: 3%;
      width: 90%;
      height: 90%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #ffffffbd;
      border: 2px dashed #6e6e6e;
      border-radius: 5px;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__color-input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0.5em 0 1em;
  }

  &__color-preview {
    display: block;
    width: 3em;
    height: 3.6em;
    border-radius: 3px;
    margin: 0 0.8em 0 0;
    border: 1px solid #c3c8ce;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.8em;
    }
  }

  &__training-perdiod-row {
    background: #7533333d;
  }
}
