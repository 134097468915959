@import '../color_variables.scss';
@import '../size_variables.scss';
@import '../constants.scss';

$header-brackground-color: #2b3643;
$default-light-blue-color: #c6cfda;
$default-blue-color: #708096;

$counter-blue-color: #36c6d3;
$counter-red-color: #d33636;

$mobile-width-breakpoint: 970px;

%default-side-padding {
  padding-left: 1.2em;
  padding-right: 1.2em;

  @media (max-width: $small-width-breakpoint) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  @media (max-width: $very-small-width-breakpoint) {
    padding-left: 0em;
    padding-right: 0em;
  }
}

%default-counter-style {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.7em;
  font-weight: lighter;
  width: 1.55em;
  height: 1.55em;
  border-radius: 50%;
}

.admin-frame {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100vh;

  &__header {
    @extend %default-side-padding;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $header-brackground-color;
    position: sticky;
    top: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 0.9em;
    z-index: 5;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding-top: 0em;
      padding-bottom: 0em;
      padding-left: 1em;
    }

    &__content-wrapper,
    &__controls-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      & > *:not(:first-child) {
        margin: 0 0 0 14px;
      }
    }

    @media (max-width: $very-small-width-breakpoint) {
      &__content-wrapper {
        justify-content: center;

        & > *:not(:first-child) {
          margin: 0;
        }
      }

      &__controls-wrapper {
        min-width: 15em;
        justify-content: space-between;
      }
    }

    &__logo-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      cursor: default;

      @media (max-width: $very-small-width-breakpoint) {
        justify-content: center;
        width: 12em;
        border: 0 solid $default-light-blue-color;
        border-width: 0 0 1px;
        padding: 0.4em 0;
      }
    }

    &__logo {
      max-width: 35px;
    }

    &__logo-text {
      font-family: 'Russo One', sans-serif;
      font-size: 1em;
      margin: 0 0 0 0.2em;
      color: white;

      @media (max-width: $very-small-width-breakpoint) {
        text-align: center;
        margin: 0 0.2em;
      }
    }

    &__menu-button,
    &__logout-button,
    &__counter-button {
      background: none;
      color: $default-light-blue-color;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      padding: 0.8em;
      min-width: 30px;
      min-height: 30px;
      font-size: 1.1em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      &__menu-button {
        position: absolute;
        left: 0.8em;
        top: 1.8em;
      }
    }

    &__counter-button {
      position: relative;
      font-size: 1em;

      &__counter {
        background: $counter-blue-color;

        &,
        &--red {
          @extend %default-counter-style;
          position: absolute;
          top: 0.3em;
          left: -0.4em;
        }

        &--red {
          background: $counter-red-color;
        }
      }
    }

    &__menu-icon {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &::before,
      &::after {
        content: '';
        width: 1.2em;
        box-sizing: content-box;
      }

      &::before {
        border: 0 solid $default-light-blue-color;
        border-width: 1px 0;
        margin: 0 0 0.4em;
        height: 0.4em;
      }

      &::after {
        background: $default-light-blue-color;
        height: 1px;
      }
    }

    &__identifiers {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
    }

    &__user-name {
      color: $default-light-blue-color;
      text-align: center;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &__unit-name {
      color: $default-light-blue-color;
      border: 0 solid $default-light-blue-color;
      border-top-width: 1px;
      padding: 0;
      font-size: 0.75em;
      text-align: center;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.65em;
      }
    }
  }

  &__content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    position: relative;
  }

  &__menu,
  &__menu--hidden {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $site-background-color;
    padding: 1.1em 0 0;
    transition: $default-transition-duration;
    z-index: 4;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $mobile-width-breakpoint) {
      display: block;
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   width: 0.5em;
      //   height: 100%;
      //   background: linear-gradient(to right, #5f5f5f, rgba(0, 0, 0, 0) 100%);
      //   z-index: 1;
      //   top: 0;
      //   left: 100%;
      //   transition: $default-transition-duration;
      // }
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      list-style: none;
      transition: $default-transition-duration;
    }

    &__counter-icon {
      background: $counter-blue-color;

      &,
      &--red {
        @extend %default-counter-style;
      }

      &--red {
        background: $counter-red-color;
      }
    }
  }

  &__menu--hidden {
    @media (max-width: $mobile-width-breakpoint) {
      transform: translateX(-115%);
    }
  }

  &__menu--hidden:not(:hover) {
    .menu-items {
      &__default-item,
      &__accordion-item,
      &__sub-item,
      &__organizer-item,
      &__expandable-organizer-item {
        &__wrapper {
          justify-content: flex-start;
        }

        &__text-wrapper {
          margin: 0;
        }

        &__text {
          width: 0;
        }

        &__icon {
          margin: 0;
        }
      }

      &__accordion-item {
        &__text-wrapper {
          margin: 0;
        }

        &__status-icon {
          width: 0;
        }

        &__sub-menu {
          padding: 0;
          opacity: 0;
        }
      }

      &__sub-item {
        &__link {
          padding: 0;
          height: 0;
        }
      }
    }
  }

  &__main {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    min-width: 0;
    overflow: auto;
    overflow-x: hidden;
  }

  &__content-blocker {
    display: none;
    transition: $default-transition-duration;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(212, 212, 212, 0.6);
    z-index: 3;
    left: -101%;

    @media (max-width: $mobile-width-breakpoint) {
      display: block;
    }
  }

  &__menu + &__content-blocker {
    @media (max-width: $mobile-width-breakpoint) {
      left: 0;
    }
  }

  &__footer {
    @extend %default-side-padding;
    background: $site-background-color;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    z-index: 5;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    &__text {
      color: $default-light-blue-color;
      font-size: 0.75em;

      @media (max-width: $very-small-width-breakpoint) {
        text-align: center;
      }
    }
  }
}
