@import '../../size_variables.scss';

$default-gray: #e1e5ec;

.physical-evaluation-photo-edit {
  max-width: 80em;

  &__subsection {
    margin-top: 1em;
  }

  &__comparison-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__photo {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;

    &:not(:last-child) {
      margin: 0 0.4em 0 0;

      & .image-picker__image-container,
      & .image-picker__image-container--no-border {
        border-bottom-width: 1px;
        border-radius: 4px;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 0.8em;
      color: gray;
      font-weight: bold;
      text-align: center;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.7em;
      }
    }
  }

  &__image-picker {
    flex: 1;
    justify-content: stretch;
    
    & .image-picker__image-container,
    & .image-picker__image-container--no-border {
      width: 100%;
      height: auto;
      min-height: 10em;
      flex: 1;
    }
  }

  &__message-window {
    z-index: 30;
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__cancel-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    margin: 0 0 0 0.5em;
    background: $default-gray;
    color: rgb(102, 102, 102);

    &:hover:not(:disabled) {
      background: darken($default-gray, 15);
    }

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }
}
