@import '../../../size_variables.scss';
@import '../../../constants.scss';
@import '../../../color_variables.scss';

$alert-list-background: #f7f7f7;

$indicator-background-color: #f7f7f7;

$default-header-border-color: #c3c8ce;
$default-border-color: #e7ecf1;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.exit-survey-report {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 70em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__indicator-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__period-control {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &__title {
      color: $default-text-color;
      font-size: 1.3em;
      background: $alert-list-background;
      border: 1px solid $default-border-color;
      text-align: center;
      font-weight: lighter;
      padding: 0.2em 0;
    }

    &__inputs {
      padding: 0.5em;
      border: 1px solid $default-border-color;
      border-top-width: 0;
    }
  }

  &__inputs-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.5em;
    border: 1px solid #d4d4d4;
    border-top-width: 0;
  }

  &__inputs-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
  }

  &__refresh-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    border-radius: 3px;
    margin: 0 0 0 0.5em;
    background: #829e7b;
    color: white;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }
}
