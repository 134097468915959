@import '../../color_variables.scss';
@import '../../size_variables.scss';

.service-group-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
    white-space: pre-wrap;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__list-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 0 1.2em;

    @media (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0 0 0.6em;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__is-active-text {
    &__ativo,
    &__inativo {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &__ativo {
      background: $default-green-color;
      color: white;
    }

    &__inativo {
      background: $site-red-color;
      color: white;
    }
  }

  & .model-table__table__row-cell,
  & .model-table__table__row-cell--highlighted {
    font-size: 0.8em;
  }
}
