@import '../../size_variables.scss';

.student-manage {
  &__title-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 51.6em;

    @media (max-width: $tiny-width-breakpoint) {
      flex-flow: column nowrap;
    }
  }

  &__image-picker {
    @media (max-width: $tiny-width-breakpoint) {
      align-self: center;
    }
  }

  &__title {
    &__text {
      font-size: 1em;
      font-weight: lighter;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__icon {
      font-size: 1.2em;
      margin: 0 0.2em 0 0;
    }
  }

  &__title-student-name {
    font-weight: bold;
  }

  &__content-container {
    max-width: 80em;
  }
}
