@import '../../size_variables.scss';
@import '../../constants.scss';

$background-color: #f1f1f1;
$shadow-color: #a0a0a0;
$border-color: #e0e0e0;
$link-text-color: #737373;
$link-background-color: #c5c5c5;
$link-border-color: #a0a0a0;
$selected-link-text-color: #2c3e50;
$selected-link-background-color: none;
$selected-link-border-color: #2c3e50;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

%default-side-padding {
  padding-left: 0.9em;
  padding-right: 0.9em;

  @media (max-width: $small-width-breakpoint) {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
}

.horizontal-menu {
  @extend %default-side-margin;
  max-width: 70em;
  align-self: stretch;

  &__links-container {
    @extend %default-side-padding;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    border: 1px solid $border-color;
    border-bottom-width: 0;
    box-shadow: inset 0px -3px 0px 0px $shadow-color;
    background: $background-color;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      background: $background-color;
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__link-wrapper {
    &:not(:last-of-type) {
      margin: 0 0.4em 0 0;
    }
  }

  &__link {
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    background: $link-background-color;
    color: $link-text-color;
    border: 0 solid $link-border-color;
    border-width: 2px 0;

    &,
    &--selected {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      font-weight: normal;
      user-select: none;
      padding: 0.4em 0.7em;
      transition: $default-transition-duration;
      font-size: 0.9em;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
      }

      &__text {
        white-space: nowrap;
        margin: 0.2em 0 0;
        font-weight: bold;
      }
    }

    &--selected {
      cursor: default;
      background: $selected-link-background-color;
      color: $selected-link-text-color;
      border: 0 solid $selected-link-border-color;
      border-width: 2px 0;
    }
  }
}
