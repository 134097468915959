@import '../../size_variables.scss';
@import '../../constants.scss';
@import '../../color_variables.scss';

$default-dark-blue-color: #364150;
$default-light-gray: #e0e0e0;

$link-dark-gray-color: #656565;
$link-light-gray: #dedede;
$link-background-color: #3598dc;

$alert-list-background: #f7f7f7;

$default-header-border-color: #c3c8ce;
$default-border-color: #e7ecf1;
$default-text-color: #333;

$indicator-background-color: #f7f7f7;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

.sales-report {
  &__wrapper {
    @extend %default-side-margin;
    align-self: stretch;
    max-width: 70em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__period-control {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &__title {
      color: $default-text-color;
      font-size: 1.3em;
      background: $alert-list-background;
      border: 1px solid $default-border-color;
      text-align: center;
      font-weight: lighter;
      padding: 0.2em 0;
    }

    &__inputs-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.5em;
      border: 1px solid $default-border-color;
      border-top-width: 0;
    }

    &__refresh-button {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }
  }

  &__per-classification-container {
    align-self: stretch;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__per-classification {
    &,
    &--spaced {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;

      @media (max-width: $small-width-breakpoint) {
        flex: 0;
        margin: 1em 0 0;
      }
    }

    &--spaced {
      margin: 1em 0 0;
    }

    &__title {
      text-align: center;
      align-self: stretch;
      font-size: 1.2em;
      color: $default-text-color;
      background: $alert-list-background;
      border: 1px solid $default-border-color;
      font-weight: lighter;
      padding: 0.2em 0;
    }

    &__graph {
      align-self: stretch;
      border: 1px solid $default-border-color;
      padding: 0 1em 0.5em;
    }

    @media (min-width: $small-width-breakpoint) {
      & + & &__graph,
      &--spaced + & &__graph,
      & + &--spaced &__graph,
      &--spaced + &--spaced &__graph,
      & + & &__title,
      &--spaced + & &__title,
      & + &--spaced &__title,
      &--spaced + &--spaced &__title {
        border-left-width: 0;
      }
    }
  }

  &__indicator-container {
    &,
    &--spaced {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
      }
    }

    &--spaced {
      margin: 0.5em 0;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-text-color;
    background: $indicator-background-color;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $site-background-color;
      color: white;
      text-align: center;
    }

    & + & {
      margin: 0 0 0 0.5em;

      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__report-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 1em;

    &:not(:first-of-type) {
      margin: 0.5em 0;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.4em 0.8em;
      background: $default-dark-blue-color;
      border: 1px solid $default-dark-blue-color;
      color: white;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #c3c8ce;
      border-top-width: 0;
    }

    &__wrapper {
      &,
      &--stretched {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: auto;
        padding: 0.4em;
        border: 1px solid $default-dark-blue-color;
        border-top-width: 0;
        border-radius:  0 0 3px 3px;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #2c3e50;
          border-radius: 0.4em;
        }
      }

      &--stretched {
        align-items: stretch;
      }
    }
  }

  &__graph {
    align-self: stretch;
  }

  &__description-cell {
    white-space: pre-wrap;
  }

  &__controls-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  &__control-button {
    &,
    &--active,
    &--inactive {
      font-family: 'Open Sans', sans-serif;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      margin: 0 0 0 0.5em;
      background: #829e7b;
      color: white;

      &:disabled {
        cursor: default;
      }
    }

    &--inactive {
      background: #dcdcdc;
      color: #949494;
    }

    &--active {
      background: #829e7b;
      color: white;
    }
  }

  &__control-label {
    margin: 0 0.5em 0 0;
  }

  &__button-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    & button {
      margin: 0;
      border-radius: 0;

      &:first-of-type {
        border-radius: 3px 0 0 3px;
      }

      &:last-of-type {
        border-radius: 0 3px 3px 0;
      }
    }
  }

  &__filter-toggle {
    margin: 0 0 1em;
  }
}
