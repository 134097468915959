@import '../../color_variables.scss';

.exercise-activity-list {
  max-width: 70em;

  &__description-cell {
    text-align: justify;
  }

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__status-cell {
    width: 7em;
  }

  &__status-text {
    &--ativo,
    &--inativo {
      flex: 0 1;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--ativo {
      background: $default-green-color;
      color: white;
    }

    &--inativo {
      background: $site-red-color;
      color: white;
    }
  }
}
