@import '../../color_variables.scss';
@import '../../constants.scss';
@import '../../size_variables.scss';

$gray-color: #8e96a0;
$red-color: #e08283;
$blue-color: #659be0;

.hr-device-list {
  max-width: 70em;

  &__cell-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &--centered {
      justify-content: center;
    }
  }

  &__date-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex: 0 1;
    padding: 0.3em 0.6em;
    color: white;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;
    background: $gray-color;
  }

  &__status-text {
    &--enabled,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 1;
      padding: 0.3em 0.6em;
      color: white;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
    }

    &--disabled {
      background: $site-red-color;
    }

    &--enabled {
      background: $default-green-color;
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }
}
